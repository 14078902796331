import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  rowData: any;
  tenant: any;
  imageList: any;
  imageObject1: any = [];
  imageObject: Array<object> = [];
  //googleMapLink: any;
  // mgObj = {};
  public id: any;

  constructor(private router: Router, private authService: AuthService, private aRoute: ActivatedRoute) { }

  productDeatils(id): void {
    console.log("Tenant Id: ", id);

    this.router.navigate(['/products-deatils', id]);
    console.log("I am here");
  }

  ngOnInit() {
    this.id = this.aRoute.snapshot.paramMap.get('id');
    this.getAmenitiesByTenant(this.id);
    this.getImagesForTenant(this.id);
    this.getTenantById(this.id);
  }

  getTenantById(id) {
    this.authService.getSauByTenantId(id).subscribe(
      (response) => {
        console.log("Tenant: ", response);

        this.tenant = response;
        //this.googleMapLink = this.tenant.googleMapsLink;
        console.log("Map ", this.tenant.googleMapsLink);

      },
      (error) => {

      }
    );
  }

  getAmenitiesByTenant(id) {
    this.authService.getAllAmenitiesSauByTenantId(id).subscribe(
      (response) => {
        console.log("Amenities: ", response);

        this.rowData = response;
      },
      (error) => {

      }
    );
  }
  cancelSlots(): void {
      this.router.navigate(['/businesses']);
  }

  getImagesForTenant(id) {
    this.authService.getAllProductsImagesSauByTenantId(id).subscribe(
      (imageList) => {
        this.imageList = imageList;
        console.log(this.imageList);
        for(var i=0; this.imageList && this.imageList.images.length > i; i++){
          let imgObj = {
            image: this.imageList.images[i],
            thumbImage: this.imageList.images[i],
          }
          console.log("i: ", i);
          this.imageObject1.push(imgObj);
        }

        this.imageObject = this.imageObject1;
        console.log("imageObj:", this.imageObject);

      },
      (error) => {

      }
    );
  }
  getAllPhoneNumbers(tenant: any): string {
      let phoneNumbers : string = '';

      if (tenant && tenant.contactPhoneNumber1) {
        phoneNumbers = tenant.contactPhoneNumber1 }
      if (tenant && tenant.contactPhoneNumber2) {
              phoneNumbers += " / " + tenant.contactPhoneNumber2 }
      /* if (tenant.contactPhoneNumber3) {
                    phoneNumbers += " / " + tenant.contactPhoneNumber3 }
  */

      return phoneNumbers;
  }

}
