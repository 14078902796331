<div style="background-color:#fff;">
  <div class="container" > <!--style="height:100vh;"-->
    <div class="row">
      <!-- TODO height 287px when new content is added before footer and after this division -->
      <div class="col-md-6" style="height: 287px;">
        <h1 class="boldStatements">Online Booking Platform for your <span style = "color: #EC8B5D;">Venue and Services </span></h1>
        <p class="description">One stop solution to manage your Bookings</p>
        <!--<button class="btn btn-primary createNewButtonStyle" type="button" (click)="onClickCreateNow()" style = "width: 250px">Create a Free Account</button>-->
      </div>
      <div class="col-md-6 hidden-mobile">
        <div class="row">
          <div class="col">
            <img src="../../assets/img/1.png" style="height: 164px;width: 185px;background-repeat: no-repeat;margin-top: 93px;margin-bottom: 30px;" />
          </div>
          <div class="col">
            <img src="../../assets/img/2.png" style="margin-top: 30px;"/>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary createNewButtonStyle" type="button" (click)="onClickCreateNow()" style = "width: 250px">Create a Free Account</button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3 class = "descriptionHeader" style="text-align:center;margin-top: 100px;">Business Types</h3>
      </div>
    </div>
    <div class="row" style = "margin-top: 40px;">
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/badminton.svg" style="height: 70px;width: 70px;  margin-left: 30px;"/>
        <p style="text-align:center;margin-top: 15px; font-weight:bold;">Sports</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/clinic.svg" style="height: 70px;width: 70px; margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Hospitals & Clinics</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/hairdresser.svg" style="height: 70px;width: 70px; margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Salon</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/workspace.svg" style="height: 70px;width: 70px; ;margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Workspaces</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/meeting.svg" style="height: 70px;width: 70px; margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Meeting Rooms</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/trainer-male.svg" style="height: 70px;width: 70px; margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Trainers</p>
      </div>
      <div class="col" style="max-width: 160px;">
        <img src="../../assets/img/wedding-arch.svg" style="height: 70px;width: 70px; margin-left: 30px;"/>
        <p style="text-align:center; margin-top: 15px; font-weight:bold;">Banquet Halls</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3 class = "descriptionHeader" style="text-align:center;margin-top: 100px;">Business Admin Features</h3>
      </div>
    </div>
    <div class="row">
      <div class="col businessFeatures">
        <div class="row">
          <img src="../../assets/img/setting.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
          <h5 style="font-weight:bold;margin-left: 5px;margin-top: 50px;">Configurable Services</h5>

        </div>
        <ul style="list-style: none; padding-top:5px;">
          <li style="padding-top:3px;font-weight:bold;">Single or Multi-Session </li>
          <li style="padding-top:3px;font-weight:bold;">Configurable Duration</li>
          <li style="padding-top:3px;font-weight:bold;">Recurrence</li>
          <li style="padding-top:3px;font-weight:bold;">Configure price for each slot/session</li>
          <li style="padding-top:3px;font-weight:bold;">Define the number of clients each service can accept at once</li>
          <li style="padding-top:3px;font-weight:bold;">Associate a service to one or multiple Venues</li>
        </ul>
      </div>
      <div class="col businessFeatures">
        <div class="row">
          <img src="../../assets/img/email-notification.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
          <h5 style="font-weight:bold;margin-left: 5px;margin-top: 50px;">Notifications</h5>

        </div>
        <ul style="list-style: none; padding-top:5px;">
          <li style="padding-top:5px; font-weight:bold;">Clients and Admin receives email on every booking</li>
        </ul>
      </div>
      <div class="col businessFeatures">
        <div class="row">
          <img src="../../assets/img/report.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
          <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Reports</h5>

        </div>
        <ul style="list-style: none; padding-top:5px;">
          <li style="padding-top:5px; font-weight:bold;">Custom report of the bookings for a period</li>
        </ul>
      </div>
      <div class="col businessFeatures">
        <div class="row" >
          <img src="../../assets/img/secure-payment.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
          <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Payments</h5>
        </div>
        <ul style="list-style: none; padding-top:5px;">
          <li style="padding-top:5px; font-weight:bold;">Accept Online payments via UPI, Net banking or Credit/Debit Cards in India</li>
        </ul>
      </div>
      <div class="col businessFeatures" style="margin-bottom:60px;">
        <div class="row">
          <img src="../../assets/img/policy-rules.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
          <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Policy Rules</h5>
        </div>
        <ul style="list-style: none; padding-top:5px;">
          <li style="padding-top:5px; font-weight:bold;">Define your Policy and the Rules for the clients</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="main-container"  *ngIf="!isUserDefined()">
  <app-footer></app-footer>
</div>
  <div class="container">
    <div class="row">
      <!-- background-color: white; -->
      <div class="col-md-11" style="margin-top: 20px; margin-bottom: 20px;">
        <!-- <app-sign-up-success></app-sign-up-success> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>




<!--<div class="main-container" style="margin-bottom:40px;"> &lt;!&ndash;*ngIf="!isUserDefined()"&ndash;&gt;
  <div class="container">
    <form class="search-form" style="height: 50px;">
      <div class="input-group" style="height: 50px;">
        <div class="input-group-prepend">
              <span class="input-group-text" style="background-color: rgb(255,255,255);">
                <i class="material-icons">search</i>
              </span>
        </div>
        <input type="text" class="form-control"
               placeholder="Search here for Sports Facilities, Medical Shops and many more." style="height: 50px;" />
        <div class="input-group-append"></div>
      </div>
    </form>
  </div>
  <div class="content-wrapper">
    <div class="container" > &lt;!&ndash;style="height:100vh;"&ndash;&gt;
      <div class="row">
        &lt;!&ndash; TODO height 287px when new content is added bofere footer and after this division &ndash;&gt;
        <div class="col-md-6" style="height: 287px;">
          <h1 style="width: 398px;height: 63px;margin-left: 25px;margin-top: 30px;color: #14274e; font-weight: bold; " class = "bigStrings">Create Free Listing</h1>
          <p style="width: 300px;height: 60px;margin-top: 8px;margin-left: 25px;font-size: 20px;color: #14274e;">Online booking &amp; scheduling for your venue</p>
          <button class="btn btn-primary" type="button" (click)="onClickCreateNow()" style="background-color: rgb(255,255,255);color: #14274E;width: 200px;height: 50px;margin-top: 25px;margin-left: 25px;border: 2px solid #14274E;border-radius: 5px;font-size: 20px; ">Create New</button>
        </div>
        <div class="col-md-6 hidden-mobile">
          <div class="row">
            <div class="col">
              <img src="../../assets/img/1.png" style="height: 164px;width: 185px;background-repeat: no-repeat;margin-top: 93px;margin-bottom: 30px;" />
            </div>
            <div class="col">
              <img src="../../assets/img/2.png" style="margin-top: 30px;"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/badminton.svg" style="height: 70px;width: 70px; margin-top: 93px; margin-left: 30px;"/>
          <p style="text-align:center;margin-top: 15px; font-weight:bold;">Sports</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/clinic.svg" style="height: 70px;width: 70px; margin-top: 93px; margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Hospitals & Clinics</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/hairdresser.svg" style="height: 70px;width: 70px; margin-top: 93px;margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Salon</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/workspace.svg" style="height: 70px;width: 70px; margin-top: 93px;margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Workspaces</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/meeting.svg" style="height: 70px;width: 70px; margin-top: 93px;margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Meeting Rooms</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/trainer-male.svg" style="height: 70px;width: 70px; margin-top: 93px;margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Trainers</p>
        </div>
        <div class="col" style="max-width: 160px;">
          <img src="../../assets/img/wedding-arch.svg" style="height: 70px;width: 70px; margin-top: 93px;margin-left: 30px;"/>
          <p style="text-align:center; margin-top: 15px; font-weight:bold;">Banquet Halls</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3 style="text-align:center;margin-top: 100px; font-weight:bold; color: #14274e;">Business Admin Features</h3>
        </div>
      </div>
      <div class="row">
        <div class="col" style="margin-left: 50px;margin-right: 50px;min-width: 300px;">
          <div class="row">
            <img src="../../assets/img/setting.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
            <h5 style="font-weight:bold;margin-left: 5px;margin-top: 50px;">Configurable Services</h5>

          </div>
          <ul style="list-style: none; padding-top:5px;">
            <li style="padding-top:3px;font-weight:bold;">Single or Multi-Session </li>
            <li style="padding-top:3px;font-weight:bold;">Configurable Duration</li>
            <li style="padding-top:3px;font-weight:bold;">Recurrence</li>
            <li style="padding-top:3px;font-weight:bold;">Configure price for each slot/session</li>
            <li style="padding-top:3px;font-weight:bold;">Define the number of clients each service can accept at once</li>
            <li style="padding-top:3px;font-weight:bold;">Associate a service to one or multiple Venues</li>
          </ul>
        </div>
        <div class="col" style="margin-left: 50px;margin-right: 50px;min-width: 300px;">
          <div class="row">
            <img src="../../assets/img/email-notification.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
            <h5 style="font-weight:bold;margin-left: 5px;margin-top: 50px;">Notifications</h5>

          </div>
          <ul style="list-style: none; padding-top:5px;">
            <li style="padding-top:5px; font-weight:bold;">Clients and Admin receives email on every booking</li>
          </ul>
        </div>
        <div class="col" style="margin-left: 50px;margin-right: 50px;min-width: 300px;">
          <div class="row">
            <img src="../../assets/img/report.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
            <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Reports</h5>

          </div>
          <ul style="list-style: none; padding-top:5px;">
            <li style="padding-top:5px; font-weight:bold;">Custom report of the bookings for a period</li>
          </ul>
        </div>
        <div class="col"style="margin-left: 50px;margin-right: 50px;min-width: 300px;">
          <div class="row" >
            <img src="../../assets/img/secure-payment.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
            <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Payments</h5>
          </div>
          <ul style="list-style: none; padding-top:5px;">
            <li style="padding-top:5px; font-weight:bold;">Accept Online payments via UPI, Net banking or Credit/Debit Cards in India</li>
          </ul>
        </div>
        <div class="col" style="margin-left: 50px;margin-right: 50px;min-width: 300px; ">
          <div class="row">
            <img src="../../assets/img/policy-rules.svg" style="height: 40px;width: 40px; margin-top: 40px;margin-left: 10px;"/>
            <h5 style="font-weight:bold;margin-left: 5px;margin-top: 40px;">Policy Rules</h5>
          </div>
          <ul style="list-style: none; padding-top:5px; ">
            <li style="padding-top:5px; font-weight:bold;">Define your Policy and the Rules for the clients</li>
          </ul>
        </div>

    </div>
  </div>
</div>
</div>-->
