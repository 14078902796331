import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  showFacilityFeatures = true;
  user;
  removable = true;
  amenities = [];
  holidays = [];
  rules = [];
  showAddNewAmenitiesButton = true;
  showAddNewHolidaysButton = true;
  showAddNewRulesButton = true;
  amenityFormGroup: FormGroup;
  holidayFormGroup: FormGroup;
  ruleFormGroup: FormGroup;
  googleMapsLinkFormGroup: FormGroup;
  tenantDetails;
  tenantImagesResponse;
  noOfImages;
  images = [];
  previewImages = [];
  defaultImageIndex;
  defaultImageIndexBeforeUpdate;
  facilityTypes;
  cities = ["Pune"];
  facilityDetailsFormGroup: FormGroup;

  radioButtonColor: ThemePalette = 'primary';

  defaultImageInTenantDetailsHeader = "../../assets/img/2.png";

  // date = new FormControl(new Date());
  monthNames = ["","Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  constructor(private authService: AuthService, private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));

    console.log("CP User", this.user );

    this.amenityFormGroup = new FormGroup({
      amenityNameFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.holidayFormGroup = new FormGroup({
      holidayFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.ruleFormGroup = new FormGroup({
      ruleFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.googleMapsLinkFormGroup = new FormGroup({
      googleMapsLinkFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.facilityDetailsFormGroup = new FormGroup({
      facilityNameFormControl: new FormControl('', [
        Validators.required
      ]),
      facilityTypeFormControl: new FormControl('', [
        Validators.required
      ]),
      facilityDescriptionFormControl: new FormControl('', [
        Validators.required
      ]),
      addressLine1FormControl: new FormControl('', [
        Validators.required
      ]),
      addressLine2FormControl: new FormControl('', [
        Validators.required
      ]),
      pincodeFormControl: new FormControl('', [
        Validators.required
      ]),
      cityFormControl: new FormControl('', [
        Validators.required
      ]),
      phoneNumber1FormControl: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]),
      phoneNumber2FormControl: new FormControl('', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]),
      phoneNumber3FormControl: new FormControl('', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), 
        Validators.maxLength(10)
      ]),
    });
    console.log("Role Id CP ", this.user.roleId);
    if(this.user.roleId == 1)
    {
        console.log("Tenant Id ", AppConstants.SESSION_STORAGE_TENANTID);
        this.user.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
        console.log("Tenant Id ", this.user.tenantId);
        console.log("user", this.user);
    }
    this.authService.getTenantDetailsBYTenantID(this.user.tenantId).subscribe(
      (response: any) => {
        this.tenantDetails = response;
        this.googleMapsLinkFormGroup.patchValue({
          googleMapsLinkFormControl: response.googleMapsLink
        });
        console.log(this.tenantDetails);
      }
    );

    this.authService.getTenantHolidaysByTenantId(this.user.tenantId).subscribe(
      (response : any[]) => {
        if(response.length > 0) {
          response.forEach(holiday => {
            const date = holiday.date.split("-");
            const day = date[2];
            const month = this.monthNames[+date[1]];
            const displayDate = day  + " " + month;
            this.holidays.push({holidayId: holiday.id, displayDate: displayDate, holidayDate: holiday.date});
          });
        }
      }
    );

    this.authService.getTenantAmenitiesByTenantId(this.user.tenantId).subscribe(
      (response : any[]) => {
        if(response.length > 0) {
          response.forEach(amenity => this.amenities.push({amenityId: amenity.id, amenityName: amenity.amenityName}));
        }
      }
    );

    this.authService.getTenantRulesByTenantId(this.user.tenantId).subscribe(
      (response: any[]) => {
        if(response.length > 0) {
          response.forEach(rule => this.rules.push(rule));
        }
      }
    );

    this.authService.getFacilityDetails().subscribe(
      response => {
        this.facilityTypes = response;
      }
    );

    this.getTenantImagesByTenantId(this.user.tenantId);
  }

  get facilityDetailsFormControls() {
    return this.facilityDetailsFormGroup.controls;
  }

  getTenantImagesByTenantId(tenantId) {
    this.authService.getTenantImagesByTenantId(tenantId).subscribe(
      (response: any) => {
        console.log(response);
        if(response.status == 200) {
          this.tenantImagesResponse = response.body;
          this.noOfImages = response.body.images.length;
          this.defaultImageIndex = this.tenantImagesResponse.defaultImageIndex;
          for(let image of this.tenantImagesResponse.images) {
            this.images.push(image);
          }
        }
      },
      (error) => {

      }
    );
  }

  onClickEditIcon() {
    this.showFacilityFeatures=false;
  }

  onCancelClick() {
    this.showFacilityFeatures=true;
  }

  clickedAddNewAmenitiesButton() {
    this.showAddNewAmenitiesButton = false;
  }

  clickedAddNewHolidaysButton() {
    this.showAddNewHolidaysButton = false;
  }

  clickedAddNewRulesButton() {
    this.showAddNewRulesButton = false;
  }

  createNewAmenity() {
    this.authService.createNewAmenity(this.user.tenantId, this.amenityFormGroup.get('amenityNameFormControl').value).subscribe(
      (response: any) => {
        console.log(response);
        this.showAddNewAmenitiesButton = true;
        this.amenities.push({amenityId: response.id, amenityName: response.amenityName});
        this.amenityFormGroup.reset();
      }
    );
  }

  createNewHoliday() {
    const date = new Date(this.holidayFormGroup.get('holidayFormControl').value);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    // const month = date.getMonth();
    // const day = date.getDate();
    // const year = date.getFullYear();
    // const holidayDate = year + "-" + month + "-" + day;
    this.authService.createNewHoliday(this.user.tenantId, formattedDate).subscribe(
      (response: any) => {
        console.log(response);
        this.showAddNewHolidaysButton = true;
        const date = response.date.split("-");
        const day = date[2];
        const month = this.monthNames[+date[1]];
        const displayDate = day  + " " + month;
        this.holidays.push({holidayId: response.id, displayDate: displayDate, holidayDate: response.date});
        this.holidayFormGroup.reset();
      },
      (error: HttpErrorResponse) => {
        if(error.error.errors[0].code == 2205) {
          alert(error.error.errors[0].message);
        } else if(error.error.errors[0].code == 2206) {
          alert(error.error.errors[0].message);
        }
      }
    );
  }

  createNewRule(ruleIndex) {
    console.log(ruleIndex);
    this.authService.createNewRule(this.user.tenantId, "", Number(ruleIndex), this.ruleFormGroup.get('ruleFormControl').value).subscribe(
      (response: any) => {
        console.log(response);
        this.showAddNewRulesButton = true;
        this.rules.push(response);
        this.ruleFormGroup.reset();
      }
    );
  }

  updateGoogleMapsLink() {
    const googleMapsLink = this.googleMapsLinkFormGroup.get('googleMapsLinkFormControl').value;
    console.log(googleMapsLink);
    this.authService.updateGoogleMapsLinkByTenantId(this.user.tenantId, googleMapsLink).subscribe(
      (response: any) => {
        console.log(response);
      }
    );
  }

  deleteAmenity(amenity) {
    this.authService.deleteAmenityByAmenityId(amenity.amenityId).subscribe(
      (response: any) => {
        this.amenities = this.amenities.filter(amenity => amenity.amenityId !== response.id);
      }
    );
  }

  deleteHoliday(holiday) {
    this.authService.deleteHolidayByHolidayId(holiday.holidayId).subscribe(
      (response: any) => {
        this.holidays = this.holidays.filter(holiday => holiday.holidayId !== response.id);
      }
    );
  }

  deleteRule(rule) {
    this.authService.deleteRuleByRuleId(rule.id).subscribe(
      (response: any) => {
        this.rules = this.rules.filter(rule => rule.id !== response.id);
      }
    );
  }

  selectValueChangeForFacilityType() {
    console.log('selectValueChangeForFacilityType clicked');
  }

  selectValueChangeForCity() {
    console.log('selectValueChangeForCity clicked');
  }

  onSaveClicked() {
    const facilityDetails = {
      tenantName: this.facilityDetailsFormGroup.get("facilityNameFormControl").value,
      tenantType: "Customer",
      description: this.facilityDetailsFormGroup.get("facilityDescriptionFormControl").value,
      address: {
        addressLine1: this.facilityDetailsFormGroup.get("addressLine1FormControl").value,
        addressLine2: this.facilityDetailsFormGroup.get("addressLine2FormControl").value,
        city: this.facilityDetailsFormGroup.get("cityFormControl").value,
        pinCode: this.facilityDetailsFormGroup.get("pincodeFormControl").value,
        country: "India"
      },
      tenantCategoryId: Number(this.facilityDetailsFormGroup.get("facilityTypeFormControl").value),
      contactPhoneNumber1: this.facilityDetailsFormGroup.get("phoneNumber1FormControl").value,
      contactPhoneNumber2: this.facilityDetailsFormGroup.get("phoneNumber2FormControl").value,
      contactPhoneNumber3: this.facilityDetailsFormGroup.get("phoneNumber3FormControl").value,
    }
    console.log(facilityDetails);

    this.authService.updateTenantByTenantId(this.tenantDetails.id, facilityDetails).subscribe(
      (response: any) => {
        console.log(response);
        this.tenantDetails = response;
        console.log("facility details updated successfully");
        console.log(this.defaultImageIndex);
        this.defaultImageIndex = this.defaultImageIndexBeforeUpdate;
        this.authService.getTenantImagesByTenantId(this.user.tenantId).subscribe(
          (getTenantImagesResponse: any) => {
            if(getTenantImagesResponse.status == 204) {
              // No Content response received i.e images are not created
              var i = 0;
              var length = this.previewImages.length;
              for(i = 0; i < length; i++) {
                if(i == 0) {
                  this.authService.createTenantImages(this.user.tenantId, this.defaultImageIndex, this.previewImages[i].file).subscribe(
                    (createTenantImageResponse: any) => {
                      console.log('Tenant Images created successfully.');
                    },
                    (error) => {}
                  );
                } else {
                  this.authService.updateTenantImagesByTenantId(this.user.tenantId, this.defaultImageIndex, this.previewImages[i].file).subscribe(
                    (updateImageResponse: any) => {
                      console.log('Tenant Images updated successfully.');
                    },
                    (error) => {}
                  );
                }
              }
            } else if(getTenantImagesResponse.status == 200) {
              var i = 0;
              var length = this.previewImages.length;
              if(length > 0) {
                for(i = 0; i < length; i++) {
                  this.authService.updateTenantImagesByTenantId(this.user.tenantId, this.defaultImageIndex, this.previewImages[i].file).subscribe(
                    (updateImageResponse: any) => {
                      console.log('Tenant Images updated successfully.');
                    },
                    (error) => {}
                  );
                }
              } else {
                this.authService.updateTenantImagesByTenantIdWithNoImage(this.user.tenantId, this.defaultImageIndex).subscribe(
                  (updateImageResponse: any) => {
                    console.log('Tenant Images updated successfully.');
                  },
                  (error) => {}
                );
              }
            }
          },
          (error) => {}
        )
        this.showFacilityFeatures=true;
      }
    );
  }

  // selectDefaultImageIndex(index) {
  //   this.defaultImageIndex = index;
  // }

  onFileUploaded(event) {
    // console.log(event);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file); // read file as data url

      reader.onload = () => { // called once readAsDataURL is completed
        this.previewImages.push({file: event.target.files[0], dataUrl: reader.result});
      }
      console.log(reader);
    }
  }

  defaultImageIndexChange(value) {
    this.defaultImageIndexBeforeUpdate = value;
  }

  getImageToShowInTenantDetailsHeader() {
    if(this.images.length > 0) {
      console.log('defaultImageIndex', this.defaultImageIndex);
      return this.images[this.defaultImageIndex];
    } else {
      return this.defaultImageInTenantDetailsHeader;
    }
  }

}
