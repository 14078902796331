import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { AppConstants } from '../AppConstants';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css']
})
export class PaymentConfirmationComponent implements OnInit {
  confirmationId: string;
  accessToken: string;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private jwtService: JwtHelperService) { 
    console.log("In constructor inside payment confirmation component before getting confirmationId");
    this.confirmationId = this.route.snapshot.paramMap.get('confirmationId');
    const encodedAccessToken = this.route.snapshot.paramMap.get('token');
    this.accessToken = atob(encodedAccessToken);
    console.log("In constructor inside payment confirmation component after getting confirmationId");
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN, JSON.stringify(this.accessToken));
    const decodedToken = this.jwtService.decodeToken(this.accessToken);
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT, JSON.stringify(decodedToken));
  //   this.route.queryParams.subscribe(params => {
  //     this.confirmationId = params['confirmationId'];
  // });
  console.log("confirmationId", this.confirmationId);
  // var arrayJSon = JSON.parse(sessionStorage.getItem("bookingObj"));
  // var updatedArrayJson = [];
  // console.log(arrayJSon);
//   for (let i = 0; i < arrayJSon.length; i++) {
//     var json = arrayJSon[i];
//     json = { ...json, confirmationId: this.confirmationId};
//     json = { ...json, paymentType: "PhonePe"};
//     console.log(json);
//     updatedArrayJson.push(json);
// }
  // console.log(updatedArrayJson);
  console.log("In constructor inside payment confirmation component before calling confirmBooking");
  this.authService.confirmBookingByConfirmationId(this.confirmationId).subscribe(
          (response: any) => {
            let bookingStatus = '';
            if(response.bookingResponse[0].bookingStatus == "Booked") {
              bookingStatus = "Successful";
            } else if(response.bookingResponse[0].bookingStatus == "WaitingForPayment") {
              bookingStatus = "WaitingForPayment";
            } else {
              bookingStatus = "Failed";
            }
            console.log("Booking response => ", response);
            console.log("Booking is " + bookingStatus + " for confirmationId: " + response.confirmationId);
            this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS + this.confirmationId);
          },
          (error) => {
             //this.isBookingSuccessful = false;

             console.log("CB Response: ", error);
             console.log("Booking Failed for confirmationId: " + this.confirmationId);
          }
        );
  }

  ngOnInit() {
    console.log("In ngOnInit function inside payment confirmation component");
  }
}
