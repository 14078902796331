import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';

@Component({
  selector: 'app-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.css']
})
export class SignUpSuccessComponent implements OnInit {
  externalUser;
  user;
  SA = false;
  roleId;
  currentUserContext;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    // this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT));
    this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));
    this.roleId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).roleId;
    if(this.user) {
      console.log("user", this.user);
      this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
      console.log("Role Id : ", JSON.parse(this.currentUserContext).roleId);
      AppConstants.SESSION_STORAGE_ROLEID = JSON.parse(this.currentUserContext).roleId;

      if (JSON.parse(this.currentUserContext).roleId == 1)
      {
        this.SA = true;
        this.router.navigate([UIRoutes.ROUTING_MODULE_TENANTS_GRID]);

      }
      if(this.user.tenantId)
      {
        this.authService.getTenantDetailsBYTenantID(this.user.tenantId).subscribe(
          response => {
            console.log(response);
            this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LANDING_PAGE);
          }
        );
      } else if(JSON.parse(this.currentUserContext).roleId == 2 && JSON.parse(this.currentUserContext).tenantId === null) {
        console.log("Tenant registration in progress");
      }
      else if (AppConstants.SESSION_STORAGE_ROLEID == 2)
      {
        this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_FACILITY_DETAILS);
      }
      else if(AppConstants.SESSION_STORAGE_ROLEID == 3)
      {
        this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_TRANSACTIONS);
      }


    } else {
      console.log("No user")
    }
  }

  onClickAddNow() {
    this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_FACILITY_DETAILS);
  }
}
