<div class="main-container">
  <div style="background-color:#fff;">
    <div class="container" style="min-height:700px;">
      <div class="row" style="margin-top:5px;">
        <div *ngFor="let tenant of rowData" class="tenant"  style="margin-top: 30px; margin-left: 20px; margin-right:20px">
          <div *ngIf="tenant.status === 'Active' || tenant.status === 'active'">
            <ng-container *ngIf="tenant.tenantImage && tenant.tenantImage.totalImageCount > 0">

              <div class="ng-slider" *ngIf="tenantImagesObj[tenant.id] && tenantImagesObj[tenant.id].length > 0" style="border-radius: 10px;">
                <ng-image-slider
                  [images]="tenantImagesObj[tenant.id]"
                  slideImage="1"
                  [imageSize]="{ width: '100%', height: 250 }"
                  #nav>
                </ng-image-slider>
              </div>
            </ng-container>
            <ng-container *ngIf="!tenant.tenantImage || getTenantImages(tenant).length === 0">
              <img src="../assets/img/noImage.png" alt="No Image" class= "tenant" style="margin-top: 8px; width:100%; height: 250px;">
            </ng-container>
            <h6  style="font-color: #14274E; font-family: 'NunitoSans-SB';">{{ tenant.tenantName }}</h6>
            <p *ngIf="getAllPhoneNumbers(tenant)">
              <img src="../../assets/img/phone-504.svg" style="height: 15px;width: 20px;"/>
              <span class="paragraph" style="margin-left: 10px;">{{ getAllPhoneNumbers(tenant)}}</span>
            </p>
            <button type="button" (click)="productDetails(tenant?.id)" class="btn btn-secondary book-now-btn ">Book Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
