<div class="main-container">
  <!--<div class="container">
    <form class="search-form" style="height: 50px;">
        <div class="input-group" style="height: 50px;">
            <div class="input-group-prepend">
              <span class="input-group-text" style="background-color: rgb(255,255,255);">
                <i class="material-icons">search</i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="Search here for Sports Facilities, Medical Shops and many more."
                style="height: 50px;" />
            <div class="input-group-append"></div>
        </div>
    </form>
  </div>-->
  <div class="content-wrapper">
      <div class="container">
<!--          <div class="row">
              <ol class="breadcrumb backgroundclr">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{tenant?.tenantName}}</li>
              </ol>
          </div>-->
          <div class="row">
              <!-- TODO height 287px when new content is added bofere footer and after this division -->
              <div class="col-md-6">
                <div class="row">
                  <div class="details col-md-12" style="padding-top: 10px;">
                    <div class="ng-slider d-none d-sm-block">
                      <ng-image-slider
                      [images]="imageObject"
                      slideImage="1"
                      [imageSize]="{width: '50%', height: 200}"
                      #nav></ng-image-slider>
                    </div>
                    <!--- Only Mobile -->
                    <div class="ng-slider d-block d-sm-none">
                      <ng-image-slider
                      [images]="imageObject"
                      slideImage="1"
                      [imageSize]="{width: '100%', height: 200}"
                      #nav></ng-image-slider>
                    </div>
                    <!--- Only Mobile -->
                  </div>
                </div>
                <div class="clearfix-20"></div>
                <div class="row d-none d-sm-block">
                  <div class="details col-md-12">
                    <h5 class="header-title">About {{tenant?.tenantName}}</h5>
                    <p class="paragraph">
                      {{tenant?.description}}
                    </p>
                  </div>
                  <div class="addressLocation col-md-12">
                    <h5 class="header-title">Address</h5>
                    <p class="paragraph">
                      {{tenant?.address?.addressLine1}}, <br>{{tenant?.address?.addressLine2}}, <br>
                      {{tenant?.address?.city}}, <br>{{tenant?.address?.country}} - {{tenant?.address?.pinCode}}
                    </p>

                  </div>
                </div>

                <!--- Only Mobile -->
                <div class="row d-block d-sm-none">
                  <div class="col-sm-12">
                      <div class="tab-card-header">
                        <ul class="nav nav-tabs col-sm-12" id="myTab" role="tablist">
                          <li class="nav-item">
                              <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">About {{tenant?.tenantName}}</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Location</a>
                          </li>
                        </ul>
                      </div>

                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                          <p class="paragraph">
                            {{tenant?.description}}
                          </p>
                        </div>
                        <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                          <p class="paragraph">
                            {{tenant?.address?.addressLine1}}, <br>{{tenant?.address?.addressLine2}}, <br>
                            {{tenant?.address?.city}}, <br>{{tenant?.address?.country}} - {{tenant?.address?.pinCode}}
                          </p>
                        </div>
                      </div>
                  </div>
                </div>
                <!--- Only Mobile -->
              </div>

              <!--- Only Mobile Buttons-->
              <div class="row d-block d-sm-none fixed-bottom mobile-bottom-btns">
                  <div class="mobile-btn cancel">
                    <button type="button" class="btn" (click)="cancelSlots()">Cancel</button>
                  </div>
                  <div class="mobile-btn book-now">
                    <button type="button" (click)="productDeatils(tenant?.id)" class="btn">Book Now</button>
                  </div>
              </div>
              <!--- Only Mobile Buttons-->

              <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12" style="padding-top: 20px; ">
                      <h5 class="header-title">{{tenant?.tenantName}}
<!--                        <br>
                        <span class="bottom-text">
                          tenant.
                        </span>-->
                      </h5>
                      <p *ngIf="getAllPhoneNumbers(tenant)">
                        <img src="../../assets/img/phone-504.svg" style="height: 15px;width: 20px;"/>
                        <span class="paragraph" style="margin-left: 10px;">{{ getAllPhoneNumbers(tenant)}}</span>
                      </p>
                    </div>
                    <div class="col-md-12">
                      <!-- <div>
                        <p><b>Rs 299 </b> <del>Rs 399</del> <span class="off-price">(Rs 100 OFF)</span><br><small>inclusive of taxes</small></p>
                      </div> -->
                      <br>
                      <!-- <button type="button" (click)="productDeatils()" class="btn btn-secondary book-now-btn">Book Now</button> -->
                      <button type="button" (click)="productDeatils(tenant?.id)" class="btn btn-secondary book-now-btn d-none d-sm-block">Book Now</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="header-title">Amenities</h5>
                    </div>
                    <div class="col-md-12" style="margin: 10px 0;">
                      <!-- <div class="testCheckboxes">
                        <div *ngFor="let amenity of rowData" class="gridCheckbox">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{amenity.id}}" id="{{amenity.id}}">
                            <label class="form-check-label" for="{{amenity.id}}">
                              {{amenity.amenityName}}
                            </label>
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 amenity-info" *ngFor="let amenity of rowData">
                          <i class="material-icons amenity-icon">check</i>
                          <label>{{amenity.amenityName}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 amenity-info" *ngIf="tenant && tenant.googleMapsLink">
                      <!--<i class="material-icons amenity-icon">map</i>-->
                      <img src="../../assets/img/icon-google-maps.svg" style="height: 30px;width: 30px;"/>
                      <!--<button type="button" (click)="OpenGoogleMap()" class="btn">Google Map</button>-->
                      <!--<h5 class="header-title">Google Location</h5>-->
                      <a [href]="tenant.googleMapsLink" target="_blank" rel="noopener noreferrer" class="summary-title">
                        Google Map
                      </a>
                    </div>
                  </div>
<!--                  <div class="map-container" *ngIf="googleMapLink">
                    <a [href]="googleMapLink" target="_blank" rel="noopener noreferrer">
                      Open Google Map in a new tab
                    </a>
                  </div>-->
                <!--<div class="map-container" *ngIf="googleMapLink">
&lt;!&ndash;                  <iframe
                    width="350"
                    height="350"
                    style="border:0"
                    loading="lazy"
                    allowfullscreen
                    [src]="googleMapLink">
                    &lt;!&ndash;src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3782.040204163646!2d73.7775015!3d18.5722261!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9071fdfde55%3A0x56692ded96d408eb!2sSmash%20Badminton%20Club!5e0!3m2!1sen!2sin!4v1718440107162!5m2!1sen!2sin">&ndash;&gt;
                   </iframe>&ndash;&gt;

                </div>-->
              </div>
          </div>
      </div>
  </div>

</div>
