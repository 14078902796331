import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookSelectedSlotsComponent } from '../book-selected-slots/book-selected-slots.component';
import { AuthService } from '../service/auth.service';
import { AppConstants } from '../AppConstants';
import { Subscription } from 'rxjs';
import { AdminBookingsService } from '../service/admin-bookings.service';
import { ShowNotificationComponent } from '../show-notification/show-notification.component';

@Component({
  selector: 'app-admin-bookings',
  templateUrl: './admin-bookings.component.html',
  styleUrls: ['./admin-bookings.component.css']
})
export class AdminBookingsComponent implements OnInit {
  selectedDateStr = this.getCurrentDate();
  selectedDate = new Date();
  venues = [];
  products = [];
  selectedProduct: any;
  selectedProductName: string;
  venuesSubscription: any;
  productsSubscription: any;
  user: any;
  venuesForSelectedProduct = [];
  reloadDataSubscription: Subscription;
  showFullDay = false;
  constructor(private authService: AuthService, private dialog: MatDialog, private adminBookingSvc: AdminBookingsService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));

    if( AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
            console.log("Tenant Id Venues ", AppConstants.SESSION_STORAGE_TENANTID);
            this.user.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
            console.log("Tenant Id ", this.user.tenantId);
            //console.log("user", this.user);
    }

    this.getAllVenuesByTenantId(this.user.tenantId);

    this.reloadDataSubscription = this.adminBookingSvc.reloadData.subscribe((data: any) => {
      if (data.reloadData) {
        if(data.confirmationId) {
          this.dialog.open(ShowNotificationComponent, {
            data: data.confirmationId
          });
        } else if(data.cancelledSlot) {
          var snackbar = document.getElementById("snackbar");
          snackbar.className = "show";
          setTimeout(function() {
              snackbar.className = snackbar.className.replace("show", "");
          }, 3000); // Hide after 3 seconds
        }
      }
    });

  }

  getAllVenuesByTenantId(tenantId) {
    this.venuesSubscription = this.authService.getAllVenuesByTenantId(tenantId).subscribe(
      (response: any) => {
        this.venues = [];
        if (response && response.length > 0) {
          response.forEach((venue) => {
            this.venues.push({
              id: venue.id,
              name: venue.venueName,
              productIds: venue.productIds
            });
          });
          this.getAllProductsByTenantId(this.user.tenantId);
        }
      },
      (error) => {
        console.log('getAllVenuesByTenantId - ' + JSON.stringify(error));
      }
    )
  }

  getAllProductsByTenantId(tenantId) {
    this.productsSubscription = this.authService.getAllProductsByTenantId(tenantId).subscribe(
      (response: any) => {
        this.products = [];
        if (response && response.length > 0) {
          response.forEach((product) => {
            this.products.push({
              id: product.id,
              name: product.productName,
              type: product.productType,
              durationPerSlot: product.durationPerSlot
            });
          });
          this.selectedProduct = this.products[0];
          this.selectedProductName = this.selectedProduct.name;
          this.onProductChanged();
        }
      },
      (error) => {
        console.log('getAllProductsByTenantId - ' + JSON.stringify(error));
      }
    )
  }

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month: any = currentDate.getMonth() + 1;
    let date: any = currentDate.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (date < 10) {
      date = '0' + date;
    }
    return year + '-' + month + '-' + date;
  }

  onDateChange(selectedDate) {
    console.log(selectedDate);
    const dateSelected = selectedDate.split('-');
    const date = new Date();
    date.setDate(dateSelected[2]);
    date.setMonth(parseInt(dateSelected[1], 10) - 1);
    date.setFullYear(dateSelected[0]);
    this.selectedDate = date;
    console.log("Selected Date",this.selectedDate);
  }

  onProductChanged() {
    console.log('selected product name - ' + this.selectedProductName);
    this.selectedProduct = this.products.find(product => product.name === this.selectedProductName);
    this.venuesForSelectedProduct = this.venues.filter((venue) => venue.productIds.indexOf(this.selectedProduct.id) > -1);
  }

  bookNow() {
    console.log('book now clicked');
    this.dialog.open(BookSelectedSlotsComponent, {

      data: {}
    });
  }

  onTabSelectionChanged(event) {
    console.log('on tab selection changed event - ' + JSON.stringify(event));
  }

  ngOnDestroy() {
    if (this.venuesSubscription) {
      this.venuesSubscription.unsubscribe();
    }
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
    if(this.reloadDataSubscription) {
      this.reloadDataSubscription.unsubscribe();
    }
  }
}
