<div class="main-container">
    <div class="container" style="background-color:#fff;">
        <br>
        <br>
        <table class="table table-borderless bookedSlotsFieldName">
          <tr>
            <td>Transaction</td>
            <td *ngIf="getTransactionStatus() === 'Success'" style="font-weight: bold; font-size: 16px; color: green;"> Success</td>
            <td *ngIf="getTransactionStatus() === 'Failed'" style="font-weight: bold; font-size: 16px; color: red ;">Failed</td>
            <td *ngIf="getTransactionStatus() === 'WaitingForPayment'" style="font-weight: bold; font-size: 16px; color: orange ;">WaitingForPayment</td>
          </tr>
          <tr>
            <td >Booking Id</td>
            <td style="font-weight: bold; font-size: 16px;">{{this.confirmationId}}</td>
          </tr>
        </table>

        <div *ngFor="let booking of bookings; let i = index">
        <p>
            Slot {{i + 1}}
            <br>
            Start Time:       {{getStartTime(booking)}}
            <br>
            End Time:        {{getEndTime(booking)}}
            <br>
            Booking Date:    {{getBookingDateConverted(booking)}}
            <br>
            Venue:           {{getVenueName(booking)}}
            <br>
            Amount:          {{getAmount(booking)}}
            <!-- <br>
            "venueId": 7,
            <br>
            "productId": 13, -->
            <br>
            PaymentType:     {{getPaymentType(booking)}}
            <br>
            Name             {{getUserFullName(booking)}}
            <br>
            Phone Number     {{getUserPhoneNumber(booking)}}
            <br>
            Email            {{getUserName(booking)}}
            <br>
            <br>
        </p>
    </div>
    </div>
</div>

