<div>
  <div class="content-wrapper">
    <div class="container" > <!--style="height:100vh;"-->
      <div>
        <ul style="list-style-type: decimal; padding-top:5px;">
          <li (click)="scrollToSection('section1')" class="btn LegalHeader" style="padding-top:5px;">Terms of Use</li><br>
          <li (click)="scrollToSection('section2')" class="btn LegalHeader" style="padding-top:5px;">Account Registration</li><br>
          <li (click)="scrollToSection('section3')" class="btn LegalHeader" style="padding-top:5px;">Booking and Financial Terms</li><br>
          <li (click)="scrollToSection('section4')" class="btn LegalHeader" style="padding-top:5px;">Cancellations and Refunds</li><br>
          <li (click)="scrollToSection('section5')" class="btn LegalHeader" style="padding-top:5px;">Terms of service</li><br>
          <li (click)="scrollToSection('section6')" class="btn LegalHeader" style="padding-top:5px;">Other terms and conditions</li><br>
          <li (click)="scrollToSection('section7')" class="btn LegalHeader" style="padding-top:5px;">Privacy Policy</li><br>
        </ul>
      </div>

      <h5 Class="LegalHeader" style="padding-top:20px;">Dexter - Terms of Use – V01 – Aug 2022</h5>
      <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.bookingdexter.com or www.bookingdexter.co.in website.</p>

      <section id="section1">
        <h6 class="LegalHeader">Terms of Use</h6>
        <ul style="list-style-type: decimal; padding-top:5px;">
          <li style="padding-top:5px;">These terms of use (the <b>"Terms of Use"</b>) govern your use of our website www.bookingdexter.com and www.bookingdexter.co.in (the <b>"Website"</b>). The Websiteis jointly referred as the <b>"Platform"</b>. Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request stop using the platform.. By using the Platform, you shall be contracting with Dexter and you signify your acceptance to this Terms of Use and other Dexter policies (including but not limited to the Cancellation & Refund Policy, Privacy Policy and Take Down Policy) as posted on the Platform and amended from time to time, which takes effect on the date on which you use the Platform, and create a legally binding arrangement to abide by the same.</li>
          <li style="padding-top:3px;">The Platform is owned and operated by Dexter, a partnership firm incorporated under the Partnership Act, 1932 and having its registered office at S.No. 110/1/A, CTS. 1148, Flat I-704, Samrajya Shivtirtha Nagar, Paud Road, Kothrud, Pune, Maharashtra 411038, India. For the purpose of these Terms of Use, wherever the context so requires, "you", “user”, or “User” shall mean any natural or legal person who shall transact on the Platform by providing registration data while registering on the Platform as a registered user using any computer systems. The terms "BookingDexter", "we", "us" or "our" shall mean Dexter.</li>
          <li style="padding-top:3px;">Dexter enables transactions on its Platform between participating tenants/merchants/businesses/individuals (<b>“Tenants”</b>) and users, Tenant Administrators on behalf of users (<b>“Users”</b>)  . Users can book slots/appointments (<b>"Services"</b>) from a variety of products and services listed and offered by various Tenants, on the Platform.</li>
          <li style="padding-top:3px;">For Platform Services Dexter is merely acting as an intermediary between the Tenants and Users.</li>
          <li style="padding-top:3px;">Dexter does not exercise control on the tenants and the relationship between the tenants and Users. For the selected Services, Dexter may charge the Users of the Platform, a service fee (inclusive of applicable taxes whenever not expressly mentioned) determined on the basis of various parameters, such  parameters as may be determined from time to time.</li>
        </ul>

        <h6 class="LegalHeader">Amendments</h6>
        <p>These Terms of Use are subject to modifications. We reserve the right to modify or change these Terms of Use and other Dexter policies at any time by posting modified documents on the Platform. You shall be liable to update yourself of such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms of Use and the other Dexter policies and note the changes made on the Platform. Your continued usage of the Services after any change is posted constitutes your acceptance of the amended Terms of Use and other Dexter policies. As long as you comply with these Terms of Use, Dexter grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms of Use, you also accept and agree to be bound by the other terms and conditions and Dexter policies (including but not limited to Cancellation & Refund Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.</p>


        <h5 Class="LegalHeader">Use of Platform and Services</h5>
        <ul style="list-style-type: decimal; padding-top:5px;">
          <li>All commercial/contractual terms are offered by and agreed to between Users and Tenants alone with respect to products and services being offered by the Tenants. The commercial/contractual terms include without limitation price, applicable taxes, payment terms, date, period, warranties related to products and services and after sales services related to products and services. Dexter does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Users and Tenants. Dexter may, however, offer support services to Tenants in respect to mode of payment, payment collection, and other ancillary services, pursuant to independent contracts executed by Dexter with the Tenants. The price of the product and services offered by the Tenant are determined by the Tenants itself and Dexter has no role to play in such determination of price in any way whatsoever.</li>
          <li>Dexter shall not be responsible for the services or quality of the services provided by Tenants to Users.</li>
          <li>Dexter does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Tenants. You are advised to independently verify the bona fides of any particular Tenant that you choose to deal with on the Platform and use your best judgment on that behalf. All Tenant offers and third-party offers are subject to respective party terms and conditions. Dexter takes no responsibility for such offers.</li>
          <li>Dexter neither make any representation or warranty as to specifics (such as quality, value, saleability, etc.) of the products or services proposed to be sold or offered to be sold or purchased on the Platform nor does implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. Dexter accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</li>
          <li>Dexter is not responsible for any non-performance or breach of any contract entered into between Buyers and Tenants, and between Tenants/Users on the Platform. Dexter cannot and does not guarantee that the concerned Users, and Tenants will perform any transaction concluded on the Platform. Dexter is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products unavailable or back ordered.</li>
          <li>Dexter is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between User and Tenant on the Platform come into or take possession of any of the products or services offered by Tenant. At no time shall Dexter hold any right, title or interest over the products nor shall Dexter have any obligations or liabilities in respect of such contract entered into between User and Tenant.</li>
          <li>Dexter is only providing a platform for communication and it is agreed that the contract for services shall be a strictly bipartite contract between the Tenant and the User. In case of complaints from the User pertaining to efficacy, quality, or any other such issues, User shall contact directly to the with the  Tenant. The Tenant shall be liable for redressing User complaints.</li>
          <li>Please note that there could be risks in dealing with underage persons or people acting under false pretence.</li>
        </ul>


        <p><u>Dexter - Use of the Website</u></p>
        <ul style="list-style-type: decimal; padding-top:5px;">
          <li>You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles:</li>
          <li>You shall not host, display, upload, download, modify, publish, transmit, update or share any information which:</li>
            <dl style="text-indent: 40px;text-align: justify;">
              <dd>belongs to another person and which you do not have any right to;</dd>
              <dd>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</dd>
              <dd>is misleading or misrepresentative in any way;</dd>
              <dd>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</dd>
              <dd>harasses or advocates harassment of another person;</dd>
              <dd>involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</dd>
              <dd>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</dd>
              <dd>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;</dd>
              <dd>promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</dd>
              <dd>contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</dd>
              <dd>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</dd>
              <dd>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</dd>
              <dd>contains video, photographs, or images of another person (with a minor or an adult);</dd>
              <dd>tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</dd>
              <dd>engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms of Use, Dexter’s prior written consent means a communication coming from Dexter’s Legal Department, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;
              <dd>olicits gambling or engages in any gambling activity which is or could be construed as being illegal;</dd>
              <dd>interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;</dd>
              <dd>refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;</dd>
              <dd>harm minors in any way;</dd>
              <dd>infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</dd>
              <dd>violates any law for the time being in force;</dd>
              <dd>deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</dd>
              <dd>impersonate another person;</dd>
              <dd>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</dd>
              <dd>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;</dd>
              <dd>is false, inaccurate or misleading;</dd>
              <dd>directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or
                creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.</dd>
            </dl>

          <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to prohibit any such activity.</li>
          <li>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.</li>
          <li>You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Platform, or any other User, including any account on the Platform not owned by you, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Platform.</li>
          <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or domain name used by us including the name 'Dexter’ or ‘BookingDexter’, or otherwise engage in any conduct or action that might tarnish the image or reputation of Dexter or Tenant on platform or otherwise tarnish or dilute any Dexter or BookingDexter trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or Dexter’s systems or networks, or any systems or networks connected to Dexter.</li>
          <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.</li>
          <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</li>
          <li>You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of Dexter and/or others.</li>
          <li>You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>
          <li>In order to allow us to use the information supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information.</li>
          <li>will only use your information in accordance with these Terms of Use and Privacy Policy applicable to use of the Platform.</li>
          <li>From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to mislead other users in any manner.</li>
          <li>You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
          <li>We reserve the right, but has no obligation, to monitor the materials posted on the Platform. Dexter shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect Dexter views. In no event shall Dexter assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.</li>
          <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.</li>
          <li>It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.</li>
          <li>Dexter shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our prior consent or a mutual legal agreement.</li>
        </ul>
      </section>
      <section id="section2">
        <h6 Class="LegalHeader">Account Registration</h6>
        <ul>
          <li>You may access the Platform by registering to create an account (<b>"BookingDexter Account"</b>) and become a member (<b>"Membership"</b>); as described below. The Membership is limited for the purpose and are subject to the terms, and strictly not transferable. We will create your Dexter Account for your use of the Platform services based upon the personal information you provide to us. You can only have one Dexter Account and are not permitted to create multiple accounts. Dexter reserves the right to suspend such multiple accounts without being liable for any compensation where you have created multiple accounts on the Platform.</li>
          <li>You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current and complete.</li>
          <li>We reserve the right to suspend or terminate your Dexter Account and your access to the Services (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; (ii) if it is believed that your actions may cause legal liability for you, other users or us; and/or (iii) if you are found to be non-compliant with the Terms of Use or other Dexter policies.</li>
          <li>You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your Dexter Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your Dexter Account.</li>
          <li>Services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.</li>
          <li>You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your Dexter Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your Dexter Account.</li>
          <li>Services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.</li>
        </ul>
      </section>

      <section id="section3">

        <h6 Class="LegalHeader">Booking and Financial Terms</h6>
        <ul>

          <li>The Platform allows the Users to book a slot for services/products offered by the Tenants, subject to the terms and conditions set out herein.</li>
          <li>Dexter does not own, sell, resell on its own such services/products offered by the Tenants, and/or does not control the Tenants or the related services provided in connection thereof. User understands that any Booking that he/she places shall be subject to the terms and conditions set out in these Terms of Use including, but not limited to, product/service availability serviceability, and acceptance of booking by Tenants.</li>
          <li>As a general rule, all bookings made on the Platform are treated as confirmed.</li>
          <li>Any change in booking or inability serve the bookings, Tenants may contact you via email or phone number provided in the booking. Dexter will not be responsible such changes and communications made by the Tenants.</li>
          <li>All payments made against the bookings on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to any other form of currency with respect to the Orders or Services made on Platform. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking an Order; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree that the payment facility provided by Dexter is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on delivery, collection and remittance facility for the transactions on the Platform using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, Dexter is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</li>
          <li>Buyers acknowledge and agree that Dexter acts as the Tenants payment agent for the limited purpose of accepting payments from Buyers on behalf of the Tenant, as the case may be. Upon your payment of amounts to us, which are due to the Tenant, your payment obligation to the Tenant for such amounts is completed, and we are responsible for remitting such amounts to the Tenants. Buyer agrees to pay for the total amount for the bookings on the Platform. Dexter will collect the total amount in accordance with these Terms of Use and the pricing terms set forth in the applicable listing of product/service for the particular Tenant. Please note that we cannot control any amount that may be charged to Buyer by his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.</li>
          <li>In connection with Buyer’s Order, he/she will be asked to provide customary billing information such as name, billing address and credit card information either to us or our third party payment processor. Buyer agrees to pay us for the Order placed by you on the Platform, in accordance with these Terms, using the methods described under clause VIII (6) above. Buyer hereby authorizes the collection of such amounts by charging the credit card provided as part of requesting the booking, either directly by us or indirectly, via a third party online payment processor or by one of the payment methods described on the Platform. If Buyer is directed to our third-party payment processor, he/she may be subject to terms and conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using the Platform services. Once the Booking is confirmed you will receive a confirmation email summarizing the confirmed booking.</li>
          <li>The final tax bill will be issued by the Tenant to the Buyer along with the Booking and Dexter is merely collecting the payment on behalf of such Tenant. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the Tenant. Tenant holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with the Tenant.</li>
          <li>The prices reflected on the Platform, may include convenience charges. Very rarely, prices may change at the time of placing Booking due to Tenant changing the booking price without due intimation and such change of prices are at the sole discretion of the Tenant attributing to various factors beyond control.</li>
          Disclaimer: Prices on any service(s) as reflected on the Platform may due to some technical issue, typographical error or product information supplied by Tenant be incorrectly reflected and in such an event Tenant may cancel Buyer’s Order(s).
          <li>The Tenant shall be solely responsible for any warranty/guarantee of the services sold to the Buyers and in no event shall be the responsibility of Dexter.</li>
          <li>The transactions are bilateral between the Tenant and Buyer, therefore, Dexter is not liable to charge or deposit any taxes applicable on such transactions.</li>
        </ul>
      </section>
      <section id="section4">
        <h6 Class="LegalHeader">Cancellations and Refunds</h6>
        <ul>
          <li>Dexter do not provide cancellation service for the Bookings made by the Buyers. Tenants are responsible for the cancellation and refunds. Buyers shall contact Tenant for any cancellation, rescheduling or refunds.  Dexter do not control the cancellation, refund or rescheduling policies defined by the Tenant.</li>
          <li>In case Tenant is not able to provide services to the Buyer, Tenant shall be responsible to communicate it to the Buyers in advance.</li>
        </ul>
      </section>
      <section id="section5">
        <h6 Class="LegalHeader">Terms of service</h6>
        <ul>
          <li>The Buyer agrees and acknowledges that Dexter shall not be responsible for:
            <ul>
              <li>The services by the Tenants.;</li>
              <li>The Tenant's services not being up to Buyer expectations or leading to any loss, harm or damage to him/her;</li>
              <li>The availability or unavailability of certain services;</li>
            </ul>
          </li>
          <li>The details of the services and price list available on the Platform or any other services are based on the information provided by the Tenants and Dexter shall not be responsible for any change or cancellation or unavailability.</li>
          <li>Buyers and Tenants agree and acknowledge that Dexter is not responsible for any liability arising out of services provided by the Tenants.</li>
          <li>The Buyer understands that Dexter’s (including Tenant’s) liability ends once Booking has been confirmed to him/her.</li>
          <li>Services provided:
            <ul>
              <li>You agree and acknowledge that Dexter shall be liable in the event you have failed to adhere to the Terms of Use.</li>
              <li>Buyer shall be required to provide credit or debit card details to the approved payment gateways while making the payment on the Platform. In this regard, Buyer agrees to provide correct and accurate credit/ debit card details to the approved payment gateways for availing the Services. Buyer shall not use the credit/ debit card which is not lawfully owned by Buyer, i.e. in any transaction, Buyer must use his/her own credit/ debit card. The information provided by the Buyer will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation or court order. Buyer shall be solely responsible for the security and confidentiality of his/her credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card.</li>
            </ul>
          </li>
          <li>Dexter does not offer any refunds against services already purchased from a Tenant through the Platform unless an error that is directly attributable to Dexter has occurred during the purchase of such services.</li>
          <li>We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion, contact you with further instructions.</li>
          <li>If you use the Platform, you do the same at your own risk.</li>
        </ul>
        <h6 Class="LegalHeader">No Endorsement</h6>
        <ul>
          <li>We do not endorse any Tenant. In addition, although these Terms of Use require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be responsible for any damage or harm resulting from your interactions with other Members.</li>
          <li>By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Members or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from us with respect to such actions or omissions.</li>
        </ul>
      </section>
      <section id="section6">
        <h6 Class="LegalHeader">Other terms and conditions</h6>
        <p Class="LegalHeader">General:</p>
        <ul>

          <li>Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and Dexter policies, you shall immediately discontinue its use. Dexter reserves the right to terminate your Membership and / or deny access to the platform if it is brought to Dexter’s notice that you are under the age of 18 years.</li>
          <li>If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms of Use.</li>
          <li>As we are providing services in the select cities in India, we have complied with applicable laws of India in making the Platform and its content available to you. In the event the Platform is accessed from outside India, it shall be entirely at your risk. We make no representation that the Platform and its contents are available or otherwise suitable for use outside select cities. If you choose to access or use the Platform from or in locations outside select cities, you do so on your own and shall be responsible for the consequences and ensuring compliance of applicable laws, regulations, byelaws, licenses, registrations, permits, authorisations, rules and guidelines.</li>
          <li>You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms of Use and Dexter policies to the attention of all such persons accessing the Platform on your computer or mobile device.</li>
          <li>You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.</li>
          <li>You agree and grant permission to Dexter to receive promotional SMS and e-mails from Dexter or allied partners or Tenants. In case you wish to opt out of receiving promotional SMS or email please send a mail to bookingdexter2021@gmail.com.
          <li>By using the Platform you represent and warrant that:
            <ul>
              <li>All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.</li>
              <li>Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.</li>
              <li>You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.</li>
              <li>All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.</li>
              <li>You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind. In the event you want to advertise your product or service contact bookingdexter2021@gmail.com.</li>
              <li>You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.</li>
              <li>You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.</li>
              <li>You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.</li>
              <li>You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.</li>
              <li>You will not discriminate against any Tenants, Buyers based on race, religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other metric which is deemed to be unlawful under applicable laws. Any credible proof of such discrimination, including any refusal to provide or receive goods or services based on the above metrics, whether alone or in conjunction with any other metric, whether lawful or unlawful, shall render you liable to lose access to the Platform immediately. You will not have any claim towards, and we will not have any liability towards any termination which is undertaken as a result of the aforementioned event.</li>
              <li>You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.</li>
              <li>You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.</li>
              <li>You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:
                <ul>
                  <li>any part of the Platform or the Platform software; or</li>
                  <li>any equipment or any network on which the Platform is stored or any equipment of any third party</li>
                </ul>
              </li>
            </ul>
          <li>You release and fully indemnify Dexter and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in that behalf, Dexter cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.</li>
        </ul>
        <h6 Class="LegalHeader">Access to the Platform, Accuracy and security</h6>
        <ul>
          <li>We do not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.</li>
          <li>We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.</li>
          <li>We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.</li>
          <li>We reserve the right to suspend or withdraw access to the Platform to you personally, or to all users temporarily or permanently at any time without notice. We may any time at our sole discretion reinstate suspended users. A suspended User may not register or attempt to register with us or use the Platform in any manner whatsoever until such time that such user is reinstated by us.</li>
        </ul>
        <h6 Class="LegalHeader">Relationship with operators if the Platform is accessed on mobile devices</h6>
        <ul>
          <li>In the event the Platform is accessed on a mobile device, it is not associated, affiliated, sponsored, endorsed or in any way linked to any platform operator, including, without limitation, Apple, Google, Android or RIM Blackberry (each being an <b>"Operator"</b>).</li>
          <li>Your download, installation, access to or use of the Platform is also bound by the terms and conditions of the Operator.</li>
          <li>You and we acknowledge that these Terms of Use are concluded between you and Dexter only, and not with an Operator, and we, not those Operators, are solely responsible for the Platform and the content thereof to the extent specified in these Terms of Use.</li>
          <li>The license granted to you for the Platform is limited to a non-transferable license to use the Platform on a mobile device that you own or control and as permitted by these Terms of Use.</li>
          <li>We are solely responsible for providing any maintenance and support services with respect to the Platform as required under applicable law. You and we acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and support services with respect to the Platform.</li>
          <li>You and we acknowledge that we, not the relevant Operator, are responsible for addressing any claims of you or any third party relating to the Platform or your possession and/or use of the Platform, including, but not limited to: (i) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (ii) claims arising under consumer protection or similar legislation.</li>
          <li>You and we acknowledge that, in the event of any third party claim that the Platform or your possession and use of the Platform infringes that third party's intellectual property rights, we, not the relevant Operator, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.</li>
          <li>You must comply with any applicable third party terms of agreement when using the Platform (e.g. you must ensure that your use of the Platform is not in violation of your mobile device agreement or any wireless data service agreement).</li>
          <li>You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third party beneficiaries of these Terms of Use, and that, upon your acceptance of these Terms of Use, that Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms of Use against you as a third party beneficiary thereof.</li>
        </ul>

        <h6 Class="LegalHeader">Disclaimers</h6>
        <ul>
          <li>THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.</li>
          <li>DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.</li>
          <li>WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.</li>
          <li>YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S / TENANT’S SERVICES.</li>
          <li>DEXTER DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE TENANT.</li>
          <li>WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH INFORMATION.</li>
          <li>THE INFORMATION PROVIDED HEREUNDER IS PROVIDED "AS IS". WE AND / OR OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGEMENT OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.</li>
          <li>WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.</li>
          <li>IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY THIRD PARTY OR TENANT ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
        </ul>
        <h6 Class="LegalHeader">Intellectual property</h6>
        <ul>
          <li>We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout format, Order placing process flow and any content thereof.</li>
          <li>You recognize that Dexter is the registered owner of the word mark ‘BookingDexter’ and the logo  including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist another in attacking the validity of, or Dexter’s or its affiliates proprietary rights in, the licensed marks or any registrations thereof, or file any applications for the registration of the licensed marks or any names or logos derived from or confusingly similar to the licensed marks, any variation thereof, or any translation or transliteration thereof in another language, in respect of any products/services and in any territory throughout the world. If you become aware or acquire knowledge of any infringement of IPR you shall report the same at bookingdexter2021@gmail.com with all relevant information.</li>
          <li>You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Platform.</li>
          <li>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
          <li>You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.</li>
          <li>If you print off, copy or download any part of the Platform in breach of these Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</li>
        </ul>
        <h6 Class="LegalHeader">Treatment of information provided by you</h6>
        We process information provided by you to us in accordance with our Privacy Policy.
        <h6 Class="LegalHeader">Third Party Content</h6>
        <ul>
          <li>We cannot and will not assure that other users are or will be complying with the foregoing rules or any other provisions of these Terms of Use, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.</li>
          <li>You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not controlled by us and different terms of use and Privacy Policy may apply. By assessing links to other sites, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Platform, although we are under no obligation to do so.</li>
        </ul>
        <h6 Class="LegalHeader">Severability</h6>
        If any of these Terms of Use should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these Terms of Use are intended to be effective, then to the extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining Terms of Use shall survive, remain in full force and effect and continue to be binding and enforceable.
        <h6 Class="LegalHeader">Non-assignment</h6>
        You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.
        <h6 Class="LegalHeader">Governing law and dispute resolution</h6>
        These Terms of Use are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Platform, shall be subject to the jurisdiction of the courts at Pune, India.
        <h6 Class="LegalHeader">Contact Us</h6>
        Please contact us at bookingdexter2021@gmail.in for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Platform.
        Grievance Officer/Nodal Officer
        In accordance with (1) Information Technology Act, 2000 and rules made there under,  and (2) Consumer Protection (E-Commerce) Rules 2020l the name and contact details of the Grievance Officer/Nodal Officer is provided below:
        Mrs. Suvarna Deshmukh
        Dexter
        Reg Office:
        S.No. 110/1/A, CTS. 1148, Flat I-704, Samrajya Shivtirtha Nagar, Paud Road, Kothrud, Pune, Maharashtra 411038, India
        Phone: +91 - 8530078212
        Email bookingdexter20212@gmail.com
        Time: Monday - Friday (9:00 - 18:00)
        <br><br><br>
      </section>
      <section id="section7">
        <h4 Class="LegalHeader">Privacy Policy</h4>

        <p>This Privacy Policy (<b>“Policy”</b>) describes the policies and procedures on the collection, use, disclosure and protection of your information when you use our website located at bookingdexter.com or bookingdexter.co.in (collectively, <b>“Dexter Platform”</b>) made available by Dexter (<b>“Dexter”, “Company”, “we”, “us” and “our”</b>),  a partnership firm established under the laws of India having its registered office at S.No. 110/1/A, CTS. 1148, Flat I-704, Samrajya Shivtirtha Nagar, Paud Road, Kothrud, Pune, Maharashtra 411038, India</p>

        <p>The terms “you” and “your” refer to the user of the Dexter Platform. The term <b>“Services”</b> refers to any services offered by Dexter whether on the Dexter Platform or otherwise.</p>

        <p>Please read this Policy before using the Dexter Platform or submitting any personal information to Dexter.</p>
        <h6 Class="LegalHeader">YOUR CONSENT</h6>
        <p>By using the Dexter Platform and the Services, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information as described and collected by us in accordance with this Policy.  If you do not agree with the Policy, please do not use or access the Dexter Platform.</p>
        <h6 Class="LegalHeader">POLICY CHANGES</h6>
        <p>We may occasionally update this Policy and such changes will be posted on this page. If we make any significant changes to this Policy we will endeavour to provide you with reasonable notice of such changes, such as via prominent notice on the Dexter Platform or to your email address on record and where required by applicable law, we will obtain your consent. To the extent permitted under the applicable law, your continued use of our Services after we publish or send a notice about our changes to this Policy shall constitute your consent to the updated Policy.</p>
        <h6 Class="LegalHeader">LINKS TO OTHER WEBSITES</h6>
        <p>The Dexter Platform may contain links to other websites. Any personal information about you collected whilst visiting such websites is not governed by this Policy. Dexter shall not be responsible for and has no control over the practices and content of any website accessed using the links contained on the Dexter Platform. This Policy shall not apply to any information you may disclose to any of our service providers/service personnel which we do not require you to disclose to us or any of our service providers under this Policy.</p>

        <h6 Class="LegalHeader">INFORMATION WE COLLECT FROM YOU</h6>
        <p>Device Information: In order to improve your website or app experience and lend stability to our services to you, we may collect information or employ third party plugins that collect information about the devices you use to access our Services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information, mobile network information, installed applications on device and phone state. The information collected thus will be disclosed to or collected directly by these plugins and may be used to improve the content and/or functionality of the services offered to you. Analytics companies may use mobile device IDs to track your usage of the Dexter Platform;</p>
        <h6 Class="LegalHeader">COOKIES</h6>
        <p>Our Dexter Platform and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, “flash cookies” and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites.</p>

        <p>Cookies are small files that are stored on your browser or device by websites, apps, online media and advertisements. We use cookies and similar technologies for purposes such as:</p>
        <ul>
          <li>Authenticating users;</li>
          <li>Remembering user preferences and settings;</li>
          <li>Determining the popularity of content;</li>
          <li>Delivering and measuring the effectiveness of advertising campaigns;</li>
          <li>Analysing site traffic and trends, and generally understanding the online behaviours and interests of people who interact with our services.</li>
        </ul>
        <p>A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users’ computers. We use pixel tags to measure the popularity of our various pages, features and services. We also may include web beacons in e-mail messages or newsletters to determine whether the message has been opened and for other analytics.</p>
        <p>To modify your cookie settings, please visit your browser’s settings. By using our Services with your browser settings to accept cookies, you are consenting to our use of cookies in the manner described in this section.</p>
        <p>We may also allow third parties to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the Internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, SDKs and other technologies to identify your device when you visit the Dexter Platform and use our Services, as well as when you visit other online sites and services.</p>
        <p>Please see our Cookie Policy for more information regarding the use of cookies and other technologies described in this section, including regarding your choices relating to such technologies.</p>

        <h6 Class="LegalHeader">USES OF YOUR INFORMATION</h6>
        <p>We use the information we collect for following purposes, including:</p>
        <ul>
          <li>To provide, personalise, maintain and improve our products and services, such as to enable deliveries and other services, enable features to personalise your Dexter account;</li>
          <li>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;</li>
          <li>To administer and enhance the security of our Dexter Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
          <li>To provide you with information about services we consider similar to those that you are already using, or have enquired about, or may interest you. If you are a registered user, we will contact you by electronic means (e-mail or SMS or telephone or other internet based instant messaging systems) with information about these services;</li>
          <li>To understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, make special offers, provide customer support, process and respond to your queries;</li>
          <li>To generate and review reports and data about, and to conduct research on, our user base and Service usage patterns;</li>
          <li>To allow you to participate in interactive features of our Services, if any; or</li>
          <li>To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.</li>
          <li>To carry out academic research with academic partners.</li>

        </ul>
        <p>We may combine the information that we receive from third parties with the information you give to us and information we collect about you for the purposes set out above.  Further, we may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Policy, and it may be used and disclosed to others without limitation.</p>
        <p>We analyse the log files of our Dexter Platform that may contain Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring, app crashes, page viewed and exit websites and applications, operating system, date/time stamp, and clickstream data. This helps us to administer the website, to learn about user behavior on the site, to improve our product and services, and to gather demographic information about our user base as a whole.</p>

        <h6 Class="LegalHeader">DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</h6>
        <p>We may share your information that we collect for following purposes:</p>
        <ul>
          <li>With Service Providers: We may share your information with our vendors, consultants, marketing partners, research firms and other service providers or business partners, such as Payment processing companies, to support our business. For example, your information may be shared with outside vendors to send you emails and messages or push notifications to your devices in relation to our Services, to help us analyze and improve the use of our Services, to process and collect payments. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us.</li>
          <li>With Tenants: While you place a request to book a slot/appointment through the Dexter Platform, your information is provided to us and to the Tenants with whom you may choose to book a slot for the services offered by the that tenant. If you provide a mobile phone number, Dexter may send you text messages regarding the bookings.
          <li>With Academic Partners: We may share your information with our academic partners for the purpose of carrying out academic research.
          <li>For Crime Prevention or Investigation: We may share this information with governmental agencies or other companies assisting us, when we are:
          <li>Obligated under the applicable laws or in good faith to respond to court orders and processes; or
          <li>Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of Services and other illegal acts;
          <li>Responding to claims that an advertisement, posting or other content violates the intellectual property rights of a third party;
          <li>Under a duty to disclose or share your personal data in order to enforce our Terms of Use and other agreements, policies or to protect the rights, property, or safety of the Company, our customers, or others, or in the event of a claim or dispute relating to your use of our Services. This includes exchanging information with other companies and organisations for the purposes of fraud detection and credit risk reduction.
          <li>For Internal Use: We may share your information with any present or future member of our “Group” (as defined below)or affiliates for our internal business purposes The term “Group” means, with respect to any person, any entity that is controlled by such person, or any entity that controls such person, or any entity that is under common control with such person, whether directly or indirectly, or, in the case of a natural person, any Relative of such person.
          <li>With Advertisers and advertising networks: We may work with third parties such as network advertisers to serve advertisements on the Dexter Platform and on third-party websites or other media (e.g., social networking platforms). These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.
        </ul>
        <p>While you cannot opt out of advertising on the Dexter Platform, you may opt out of much interest-based advertising on third party sites and through third party ad networks (including DoubleClick Ad Exchange, Facebook Audience Network and Google AdSense). Opting out means that you will no longer receive personalized ads by third parties ad networks from which you have opted out, which is based on your browsing information across multiple sites and online services. If you delete cookies or change devices, your opt out may no longer be effective.</p>

        <ul>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>  We may share your information other than as described in this Policy if we notify you and you consent to the sharing.</li>
        </ul>
        <h6 Class="LegalHeader">DATA SECURITY PRECAUTIONS</h6>

        <p>We have in place appropriate technical and security measures to secure the information collected by us.</p>
        <p>We use tokenization services to protect the sensitive personal information provided by you. The third-party service providers with respect to our payment gateway and payment processing are compliant with the payment card industry standard (generally referred to as PCI compliant service providers). You are advised not to send your full credit/debit card details through unencrypted electronic platforms. Where we have given you (or where you have chosen) a username and password which enables you to access certain parts of the Dexter Platform, you are responsible for keeping these details confidential. We ask you not to share your password with anyone.</p>
        <p>Please we aware that the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through the Dexter Platform. Once we have received your information, we will use strict physical, electronic, and procedural safeguards to try to prevent unauthorised access.</p>

        <h6 Class="LegalHeader">GRIEVANCE OFFICER AND DEXTER PLATFORM SECURITY</h6>
        <p>If you have any queries relating to the processing or usage of information provided by you in connection with this Policy, please email us at bookingdexter2021@gmail.in</p>
        <p>Further, please note that the Dexter Platform stores your data with the cloud platform of Amazon Web Services provided by Amazon Web Services, Inc., which may store this data on their servers located outside of India. Amazon Web Services has security measures in place to protect the loss, misuse and alteration of the information, details of which are available at https://aws.amazon.com/. The privacy policy adopted by Amazon Web Services are detailed in https://aws.amazon.com/privacy. In the event you have questions or concerns about the security measures adopted by Amazon Web Services, you can contact their data protection / privacy team / legal team or designated the grievance officer at these organisations, whose contact details are available in its privacy policy.</p>

      </section>
    </div>
  </div>

</div>
