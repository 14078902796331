<div class="admin-bookings container">
    <div id="topContainer" class="row">
      <div class="col-md-4 form-group" style="margin-top:10px;">
        <label class="fieldName" style="font-size:12px;">SERVICE</label>
        <div class="input-group">
          <select [(ngModel)]="selectedProductName" class="form-control" (change)="onProductChanged()">
            <option *ngFor="let product of products" [value]="product.name">
              {{product.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3 form-group" style="margin-top:10px;">
        <label class="fieldName" style="font-size:12px;">DATE</label>
        <div class="input-group">
          <input type="date" [(ngModel)]="selectedDateStr" class="form-control" placeholder="Select Date"
          value="{{selectedDateStr}}" name="selectedDate" (change)="onDateChange($event.target.value)">
        </div>
      </div>
      <div class="col-md-3 form-group" style="margin-top:10px; display: flex;">
        <div class="input-group" style="align-items: center;">
          <mat-slide-toggle style="margin-top: 12px;"
              [(ngModel)]="showFullDay"
              [ngModelOptions]="{standalone: true}"
              color="primary">
              <label class="fieldName" style="font-size:12px; margin-bottom: 0;">Show Full Day</label>
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-md-2 form-group top-right">
        <button class="btn btn-primary book-now-btn" (click)="bookNow()">Book Now</button>
      </div>
    </div>
    <div id="booking-slots" *ngIf="selectedDate && selectedProduct && venuesForSelectedProduct.length > 0">
        <mat-tab-group class= "slotGrid" preserveContent>
          <mat-tab *ngFor="let venue of venuesForSelectedProduct" [label]="venue.name">
            <app-booking-slots [showFullDay]="showFullDay" [selectedDate]="selectedDate" [venueName]="venue.name" [selectedProduct]="selectedProduct"></app-booking-slots>
          </mat-tab>
        </mat-tab-group>
    </div>
</div>


<div id="snackbar">Slot cancelled succesfully.</div>
