import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UIRoutes } from '../UIRoutes';
import { Injectable } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';

@Injectable({ providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const currentUser = sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN);
        if(currentUser) {
            if(Object.values(UIRoutes).includes(route.routeConfig.path.toString()) && (this.authService.getTokenExpiryTime() > 0)) {
                return true;
            } else {
                this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN);
                return false;
            }
        }
        console.log('In auth guard');
        this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN);
        return false;
    }
}