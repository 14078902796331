import { Injectable } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  datePipe: DatePipe = new DatePipe('en-US');

  getFormattedDate(dateStr) {
        console.log("dateService: ", dateStr);
        const splitDateStr = dateStr.split('T');
        const dateValue = splitDateStr[0];
        const splitDateValue = dateValue.split('-');
        const formattedDate = this.datePipe.transform(dateStr, 'EEEE, dd MMM yy');
        console.log("formattedDate", formattedDate);
        //return splitDateValue[2] + ' ' + this.months[parseInt(splitDateValue[1], 10) - 1] + ' ' + splitDateValue[0]
        return formattedDate;
  }

  convertTo12HourFormat(time24: string): string {
        // Split the time string into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);

        // Check if hours are greater than 12
        if (hours > 12) {
            // Convert hours to 12-hour format
            const hours12 = hours - 12;
            return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} PM`;
        } else if (hours === 0) {
            // Midnight (00:00) is 12:00 AM in 12-hour format
            return `12:${minutes.toString().padStart(2, '0')} AM`;
        } else if (hours === 12) {
            // Noon (12:00) is 12:00 PM in 12-hour format
            return `12:${minutes.toString().padStart(2, '0')} PM`;
        } else {
            // Hours are already in 12-hour format
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} AM`;
        }
  }

}
