
<h2 class="descriptionHeader" mat-dialog-title>Booking Form</h2>
<mat-dialog-content class="descriptionHeader" >
    <section class="booked-details-container row">
        <section class="col-8 col-md-8 col-sm-12 booking-form ">
            <section id="firstName" class="row">
                <div class="col-sm-6 text-center">
                    <label>First Name</label>
                </div>
                <div class="col-6 col-md-6 col-sm-8">
                    <input type="text" class="custom-input" (keyup)="isReadyToBook()" [(ngModel)]="firstName"/>
                </div>
            </section>
            <section id="lastName" class="row">
                <div class="col-sm-6 text-center">
                    <label>Last Name</label>
                </div>
                <div class="col-6 col-md-6 col-sm-8">
                    <input type="text" class="custom-input" (keyup)="isReadyToBook()" [(ngModel)]="lastName"/>
                </div>
            </section>
          <section id="email" class="row">
            <div class="col-sm-6 text-center">
                  <label>Email</label>
            </div>
            <div class="col-6 col-md-6 col-sm-8">
                  <input type="text" class="custom-input" (keyup)="isReadyToBook()" [(ngModel)]="email"/>
            </div>
          </section>
          <section id="phone" class="row">
            <div class="col-sm-6 text-center">
                  <label>Phone</label>
              </div>
            <div class="col-6 col-md-6 col-sm-8">
                  <input type="text" class="custom-input" (keyup)="isReadyToBook()" [(ngModel)]="phoneNumber"/>
              </div>
          </section>
          <section id="notes" class="row">
            <div class="col-sm-6 text-center">
                <label>Notes</label>
              </div>
            <div class="col-6 col-md-6 col-sm-8">
                <textarea rows="4" cols="25" maxlength="50" class="custom-input" (keyup)="isReadyToBook()" [(ngModel)]="notes"></textarea>
              </div>
          </section>
        </section>
      <section class="col-12 selected-slots">
            <section *ngFor="let product of displaySelectedProducts" style="margin-top: 10px;">
                <section *ngFor="let venue of globalObject.keys(displayData[product])" class="venue-slots">
                  <div class="row">
                    <div style="margin-left:10px">{{product}}</div>
                    <div style="margin-left:40px">{{venue}}</div>
                  </div>
                    <div *ngFor="let data of displayData[product][venue]" class="row" style="font-size:0.8rem; margin-top:10px;">
                        <div class="col-4" >{{getFormattedDate(data.date)}}</div>
                        <div class="col-4" >{{getFormattedTime(data.startTime)}} - {{getFormattedTime(data.endTime)}}</div>
                        <div class="col-3"  *ngIf="data.price!=='Unavailable'">
                            Rs. {{data.price}}
                        </div>
                        <div class="col-3" *ngIf="data.price==='Unavailable'" style="color: red;">
                            {{data.price}}
                        </div>
                        <div >
                          <img src="./../../assets/img/delete.svg" (click)="removeSlot(data)" style="cursor: pointer; "/>
                        </div>
                    </div>
                </section>
            </section>
        </section>
    </section>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button class="btn" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary book-now-btn" [disabled]="disableConfirmBtn" (click)="confirmBooking()">Confirm</button>
</mat-dialog-actions>




<!--
<div class="container" style="height:60vh; width: 400px;margin-right: 0px; margin-top: 0px;">
<h2 class="descriptionHeader" mat-dialog-title>Booking Form</h2>
<div class="descriptionHeader" style="width: 100%; height: 100%; overflow-y: auto;">

    <div class="row">
      <div class="col-md-12 col-sm-12 booking-form">
        <div id="firstName" class="form-group row">
          <div class="col-md-6 col-sm-4">
              <label for="firstName" class="col-form-label">First Name</label>
          </div>
          <div class="col-md-6 col-sm-6">
            <input type="text" id="firstName" class="form-control" (keyup)="isReadyToBook()" [(ngModel)]="firstName"/>
          </div>
        </div>
        &lt;!&ndash; Repeat similar structure for other form fields &ndash;&gt;
      </div>
    </div>

</div>
</div>
<mat-dialog-actions class="actions">
    <button class="btn" mat-dialog-close>Cancel</button>
    <button class="btn btn-primary book-now-btn" [disabled]="disableConfirmBtn" (click)="confirmBooking()">Confirm</button>
</mat-dialog-actions>
-->



<!--
<h2 class="descriptionHeader" mat-dialog-title>Booking Form</h2>
<mat-dialog-content class="descriptionHeader" >
  <div class="container" >
    <div class="row">
      <div class="col-12 col-md-8 booking-form">
        <div id="firstName" class="form-group row">
          <label for="firstName" class="col-md-6 col-sm-6 col-form-label text-md-right">First Name</label>
          <div class="col-md-6 col-sm-6">
            <input type="text" id="firstName" class="form-control" (keyup)="isReadyToBook()" [(ngModel)]="firstName"/>
          </div>
        </div>
        &lt;!&ndash;<div id="lastName" class="form-group row">
          <label for="lastName" class="col-md-6 col-sm-6 col-form-label text-md-right">Last Name</label>
          <div class="col-12 col-md-8">
            <input type="text" id="lastName" class="form-control" (keyup)="isReadyToBook()" [(ngModel)]="lastName"/>
          </div>
        </div>
        <div id="email" class="form-group row">
          <label for="email" class="col-md-6 col-sm-6 col-form-label text-md-right">Email</label>
          <div class="col-md-6 col-sm-6">
            <input type="text" id="email" class="form-control" (keyup)="isReadyToBook()" [(ngModel)]="email"/>
          </div>
        </div>
        <div id="phone" class="form-group row">
          <label for="phone" class="col-md-6 col-sm-6 col-form-label text-md-right">Phone</label>
          <div class="col-md-6 col-sm-6">
            <input type="text" id="phone" class="form-control" (keyup)="isReadyToBook()" [(ngModel)]="phoneNumber"/>
          </div>
        </div>
        <div id="notes" class="form-group row">
          <label for="notes" class="col-md-6 col-sm-6 col-form-label text-md-right">Notes</label>
          <div class="col-md-6 col-sm-6">
            <textarea id="notes" rows="4" cols="25" class="form-control" maxlength="50" (keyup)="isReadyToBook()" [(ngModel)]="notes"></textarea>
          </div>
        </div>&ndash;&gt;
      </div>
      <div class="col-md-4 col-sm-12 selected-slots">
        &lt;!&ndash; Your selected slots content &ndash;&gt;
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button class="btn btn-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary book-now-btn" [disabled]="disableConfirmBtn" (click)="confirmBooking()">Confirm</button>
</mat-dialog-actions>
-->


