<div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- Date/Time Column -->
      <ng-container matColumnDef="dateTime" sticky>
        <th mat-header-cell *matHeaderCellDef>DATE</th>
        <td mat-cell *matCellDef="let element" class="date-time-label"> {{getFormattedTime(element.dateTime)}} </td>
      </ng-container>

      <ng-container matColumnDef="{{column.dateFormat}}" *ngFor="let column of dayColumnsObj">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{getDayHeader(column.dateTime)}}</div>
          <div>{{getWeekDay(column.dateTime)}}</div>
        </th>
        <td mat-cell *matCellDef="let element" id="dt{{element[column.dateFormat]?.uniqueId}}" (dblclick)="onDblClick()" (click)="onSingleClick(element, column.dateFormat, $event)" class="slot-data"
        [ngClass]="element[column.dateFormat] && element[column.dateFormat].isAvailable ? 'available-slot': (element[column.dateFormat] && element[column.dateFormat].isSelected ? 'selected-slot':
              (element[column.dateFormat] && element[column.dateFormat].isBooked ? 'booked-slot': ''))">
              <div *ngIf="element[column.dateFormat]">
                <div *ngIf="element[column.dateFormat].isBooked">
                  <div>{{element[column.dateFormat].customerName}}</div>
                  {{rupeeSign}} {{element[column.dateFormat].price}}
                </div>
                <div *ngIf="element[column.dateFormat].isAvailable || element[column.dateFormat].isSelected">
                  {{rupeeSign}} {{element[column.dateFormat].price}}
                </div>
                <div *ngIf="!element[column.dateFormat].isAvailable && !element[column.dateFormat].isBooked &&
                !element[column.dateFormat].isSelected">
                  {{element[column.dateFormat].displayLabel}}
                </div>
              </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnData; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnData;">
        <td></td>
      </tr>
    </table>
  </div>
