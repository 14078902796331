<div class="custloginmain">
    <div class="custlogin-firststep">
      <div class="innermain row m-0">
        <div class="col-lg-6 rightcol p-0">
          <div class="rtinner">
            <h2 class="ac-title">Reset Password</h2>
            <div class="innercol">
              <p class="sign-txt">Reset password for your account {{userName}}</p>
              <form class="register-form" [formGroup]="userActivationFormGroup">
                <div class="md-form position-relative">  
                  <mat-form-field class="example-full-width">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="passwordFormControl" placeholder="Password">
                    <mat-error *ngIf="userActivationFormControls.passwordFormControl.hasError('required')">Password is required</mat-error>
                    <mat-error *ngIf="userActivationFormControls.passwordFormControl.hasError('minlength')">Password must have at least {{minPasswordLength}} characters</mat-error>
                    <mat-error *ngIf="userActivationFormControls.passwordFormControl.hasError('pattern') && !userActivationFormControls.passwordFormControl.hasError('minlength')">Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Re-enter Password</mat-label>
                    <input matInput type="password" formControlName="reenterPasswordFormControl" [errorStateMatcher]="matcher" placeholder="Password">
                    <mat-error *ngIf="userActivationFormControls.reenterPasswordFormControl.hasError('required')">Password is required</mat-error>
                    <mat-error *ngIf="userActivationFormGroup.hasError('notSame') && !userActivationFormControls.reenterPasswordFormControl.hasError('required')">Passwords do not match</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-check text-center p-0">
                  <button class="btn-signpass" (click)="onResetPasswordClicked()">Reset Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    