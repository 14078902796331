<div class="container" style="background-color: #fff;" *ngIf="roleId == 2">
    <div class="row">
        <div class="col" style="color: #333333;">
            <h1 style="font-size: 32px;color: #14274e;margin-left: 68px;margin-top: 106px;margin-bottom: 0px;">
                Welcome {{user.firstName}}
            </h1>
            <p style="margin-left: 68px;margin-top: 30px;margin-bottom: 0px;color: #333333;">
                Your account has been created successfully!
            </p>
            <p *ngIf="!user" style="margin-left: 68px;margin-bottom: 0px;margin-top: 15px;">
                Please activate your account.
            </p>
            <p *ngIf="user" style="margin-left: 68px;margin-bottom: 0px;margin-top: 15px;">
                Your account is activated.
            </p>

            <div *ngIf="user">
                <p style="margin-bottom: 0px;margin-top: 74px;margin-left: 68px;color: #333333;">
                    Next step would be adding your Facility and its details.
                </p>
                <button class="btn btn-primary" type="button" (click)="onClickAddNow()" style="margin-left: 68px;margin-top: 116px;background-color: #ffffff;font-size: 20px;color: #14274e;width: 199px;height: 60px;border: 1px solid #14274E;border-radius: 5px;">
                    Add Now
                </button>
            </div>
        </div>
        <div
            class="col">
            <div style="margin-top: 69px;"><img src="../../assets/img/4.png" style="width: 375px; height: 463px;margin-bottom: 88px;" /></div>
    </div>
  </div>
</div>

