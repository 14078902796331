import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BookedDetailsComponent } from '../booked-details/booked-details.component';
import { BookingData } from '../models/booking-data';
import { AdminBookingsService } from '../service/admin-bookings.service';
import { AppConstants } from '../AppConstants';
import { BookSelectedSlotsComponent } from '../book-selected-slots/book-selected-slots.component';


@Component({
  selector: 'app-booking-slots',
  templateUrl: './booking-slots.component.html',
  styleUrls: ['./booking-slots.component.css']
})
export class BookingSlotsComponent implements OnInit, OnDestroy {
  @Input() showFullDay;
  @Input() selectedDate;
  @Input() selectedProduct;
  @Input() venueName;

  dateRange = 7;
  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dataSource = [];
  origDataSource = [];
  columnData = [];
  dayColumnsObj = [];
  dayColumns = [];
  slotDetails: any = {};
  slotInterval = 60;
  bookingData: BookingData;
  bookingSlotsSubscription: Subscription;
  user: any;
  singleClickTimeout: any;
  isSingleClick = false;
  rupeeSign = 'Rs.';
  reloadDataSubscription: Subscription;
  selectedSlotRemovedSubscription: Subscription;
  venueDuration = {
    startTime: '',
    endTime: ''
  };

  constructor(private cd: ChangeDetectorRef, private dialog: MatDialog, private adminBookingSvc: AdminBookingsService) {
  }

  ngOnChanges(changes) {
    if (changes && (changes.selectedDate && !changes.selectedDate.firstChange &&
              changes.selectedDate.previousValue !== changes.selectedDate.currentValue) ||
              (changes.showFullDay && !changes.showFullDay.firstChange &&
                changes.showFullDay.previousValue !== changes.showFullDay.currentValue)) {
      this.refreshGrid();
    }
    if (changes && changes.selectedProduct && !changes.selectedProduct.firstChange &&
              changes.selectedProduct.previousValue !== changes.selectedProduct.currentValue) {
      this.slotInterval = this.selectedProduct.durationPerSlot;
      this.refreshGrid();
    }
  }

  addZeroIfRequired(value: number) {
    if (value > 9) {
      return value;
    }
    return '0' + value;
  }

  ngOnInit() {
    this.slotInterval = this.selectedProduct.durationPerSlot;
    this.setAllDayHoursInSource();
    this.initializeData();
    this.reloadDataSubscription = this.adminBookingSvc.reloadData.subscribe((data: any) => {
      if (data.reloadData) {
        this.initializeData();
      }
    });
    this.selectedSlotRemovedSubscription = this.adminBookingSvc.selectedSlotRemoved.subscribe((slotData: any) => {
      const element = this.dataSource.find(el => el.dateTime === slotData.dateTime);
      this.showData(element, slotData.date, null);
    });
  }

  initializeData() {
    if (this.selectedDate && this.selectedProduct) {
      this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));
      if( AppConstants.SESSION_STORAGE_ROLEID == 1)
      {
                  console.log("Tenant Id Venues ", AppConstants.SESSION_STORAGE_TENANTID);
                  this.user.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
                  console.log("Tenant Id ", this.user.tenantId);
                  //console.log("user", this.user);
      }

      const endDate = this.addXNumberOfDays(this.selectedDate, 7);
      /* endDate.setFullYear(this.selectedDate.getFullYear());
      endDate.setMonth(this.selectedDate.getMonth());
      endDate.setDate(this.selectedDate.getDate() + 7); */
      const startDateStr = this.selectedDate.getFullYear() + '-' + this.addZeroIfRequired(this.selectedDate.getMonth() + 1) + '-' +
                            this.addZeroIfRequired(this.selectedDate.getDate());
      const endDateStr = endDate.getFullYear() + '-' + this.addZeroIfRequired(endDate.getMonth() + 1) + '-' + this.addZeroIfRequired(endDate.getDate());
      const params = {
        tenantId: this.user.tenantId,
        productId: this.selectedProduct.id,
        startDate: startDateStr,
        endDate: endDateStr
      };
      if (this.selectedProduct.type === AppConstants.SINGLE_SESSION) {
        this.bookingSlotsSubscription = this.adminBookingSvc.getSingleSessionBookingSlots(params).subscribe((response: any) => {
          console.log("Booking Data of All the venues", response);
          this.bookingData = this.getBookingDataForCurrentVenue(response,'singleBookingAvailableSlots', 'singleBookingBookedSlots');
          this.renderGrid();
        });
      } else {
        this.bookingSlotsSubscription = this.adminBookingSvc.getMultiSessionBookingSlots(params).subscribe((response: any) => {
          console.log(response);
          this.bookingData = this.getBookingDataForCurrentVenue(response, 'multiBookingAvailableSlots', 'multiBookingBookedSlots');
          this.renderGrid();
        });
      }
    }
  }

  addXNumberOfDays(startDate: Date, numberOfDays:number): Date {
    // Set the time to midnight (00:00:00)
    startDate.setHours(0, 0, 0, 0);

    const millisecondsInSevenDays = numberOfDays * 24 * 60 * 60 * 1000;
    const futureDate = new Date(startDate.getTime() + millisecondsInSevenDays);
    return futureDate;
  }


  getBookingDataForCurrentVenue(bookingData, availableSlots, bookedSlots) {
    const currentVenueData = new BookingData();
    currentVenueData.venueName = this.venueName;

    if (bookingData && bookingData.length > 0) {
      bookingData.forEach((venueData) => {
        if (venueData.venueName === this.venueName) {
          currentVenueData.availableSlots.splice(currentVenueData.availableSlots.length, 0,
            ...venueData[availableSlots]);
          currentVenueData.bookedSlots.splice(currentVenueData.bookedSlots.length, 0,
              ...venueData[bookedSlots]);

        }
      });
    }

    return currentVenueData;
  }

  refreshGrid() {
    this.dataSource = [];
    this.setAllDayHoursInSource();
    this.initializeData();
  }

  setVenueDuration() {
    const timings = this.bookingData.availableSlots.map((slot) => slot.startTime);
    const uniqueTimings = timings.filter((time, pos) => timings.indexOf(time) === pos);
    console.log('available timings' + JSON.stringify(uniqueTimings));
    const sortTimings = uniqueTimings.sort((a, b) => {
      const splitA = a.split(':');
      const splitB = b.split(':');
      return parseInt(splitA[0], 10) - parseInt(splitB[0], 10);
    });
    console.log('sort timings - ' + JSON.stringify(sortTimings));
    this.venueDuration = {
      startTime: sortTimings[0],
      endTime: sortTimings[sortTimings.length - 1]
    };
    console.log('venue name: ' + this.venueName + ' - duration: ' + JSON.stringify(this.venueDuration));
    const newDataSource = [];
    let canAddData = true;
    this.dataSource.forEach((ds) => {
      if (canAddData && (newDataSource.length > 0 && ds.dateTime !== this.venueDuration.endTime) || ds.dateTime === this.venueDuration.startTime) {
        newDataSource.push(ds);
      } else if (ds.dateTime === this.venueDuration.endTime) {
        newDataSource.push(ds);
        canAddData = false;
      }
    });
    this.dataSource = newDataSource;
    console.log('new data source - ' + JSON.stringify(this.dataSource));
  }

  renderGrid() {
    if (!this.showFullDay) {
      this.setVenueDuration();
    }
    this.slotDetails = {};
    this.columnData = ['dateTime'];
    this.dayColumns = this.getDayColumns();
    this.columnData = this.columnData.concat(this.dayColumns);
    this.formSlotData();
    this.updateDataSource();
    this.cd.detectChanges();
  }
  onSingleClick(element, dataKey, event) {
    this.isSingleClick = true;
    this.singleClickTimeout = setTimeout(() => {
      if (this.isSingleClick) {
        this.showData(element, dataKey, event);
      }
    }, 200);
  }
  showData(element, dataKey, event) {
    const slotClicked = element[dataKey];
    if (slotClicked.displayLabel === 'Available') {
      slotClicked.isSelected = !slotClicked.isSelected;
      slotClicked.isAvailable = !slotClicked.isAvailable;

      if (slotClicked.isSelected) {
        this.adminBookingSvc.addToSelectedSlots(slotClicked);
      } else {
        this.adminBookingSvc.removeFromSelectedSlots({uniqueId: slotClicked.uniqueId});
      }
    }
    if (event && (slotClicked.displayLabel === 'Unavailable' || slotClicked.displayLabel === 'Available')) {
      event.stopPropagation();
    } else if (event) {
      this.dialog.open(BookedDetailsComponent, {
          data: slotClicked
      });
    }
  }

  onDblClick() {
    this.isSingleClick = false;
    if (this.singleClickTimeout) {
      clearTimeout(this.singleClickTimeout);
      this.bookNow();
    }
  }

  bookNow() {
    this.dialog.open(BookSelectedSlotsComponent, {
      width: '600px',
      data: {}
    });
  }

  formSlotData() {
    const sessionData = this.bookingData;
    if (!sessionData) {
      return;
    }
    const availableSlots = sessionData.availableSlots;
    const bookedSlots = sessionData.bookedSlots;

    if (availableSlots && availableSlots.length > 0) {
      availableSlots.forEach((availableSlot) => {
        if (availableSlot.uniqueId) {
          const yearStrLength = 4;
          const dateLength = 10;
          const uniqueId = availableSlot.uniqueId;
          const indexOfFirstHyphen = uniqueId.indexOf('-');
          const dateKey = uniqueId.substr(indexOfFirstHyphen - yearStrLength, dateLength);
          const slotDate = new Date(dateKey);
          const startTime = availableSlot.startTime.split(':');
          slotDate.setHours(startTime[0]);
          slotDate.setMinutes(startTime[1]);
          const currentTime = new Date().getTime();
          if (slotDate.getTime() >= currentTime) {
            this.parseSlotData(availableSlot, 'available');
          }
        }
      });
    }
    if (bookedSlots && bookedSlots.length > 0) {
      bookedSlots.forEach((bookedSlot) => {
        this.parseSlotData(bookedSlot, 'Booked');
      });
    }
  }

  updateDataSource() {
    this.dayColumns.forEach((column) => {
      this.dataSource.forEach(rowData => {
          let isAvailable = false;
          if (this.slotDetails[rowData.dateTime] && this.slotDetails[rowData.dateTime][column]) {
            const slotData = this.slotDetails[rowData.dateTime][column];
            if (slotData) {
              let slotText = 'Unavailable';
              if (slotData.availableSlots.length > 0) {
                slotText = 'Available';
                isAvailable = true;
                rowData[column] = slotData.availableSlots[0];
              } else {
                slotText = 'Booked';
                isAvailable = false;
                rowData[column] = slotData.bookedSlots[0];
              }
            }
          } else {
            rowData[column] = {
              displayLabel: '--',
              isAvailable: false,
              isSelected: false
            };
          }
        });
    });
  }

  getSlotId(data, dateKey) {
    return data.tenantId + '_' + data.productId + '_' + data.venueId + '_' + dateKey + '_' +
                  data.startTime + '_' + data.endTime;
  }

  addSlotData(data, slotData, type, dateKey) {
    const selectedSlots = JSON.parse(sessionStorage.getItem('selectedSlots'));
    let matchingSlot;
    if (selectedSlots && selectedSlots.length > 0) {
      matchingSlot = selectedSlots.find(selectedSlot => selectedSlot.uniqueId === data.uniqueId);
    }
    if (type === 'available') {
        slotData.availableSlots.push({
          displayLabel: 'Available',
          isSelected: matchingSlot ? matchingSlot.isSelected : false,
          isAvailable: matchingSlot ? matchingSlot.isAvailable : true,
          price: Math.round(data.price),
          slotId: this.getSlotId(data, dateKey),
          tenantId: data.tenantId,
          productId: data.productId,
          productName: this.selectedProduct.name,
          venueName: this.venueName,
          venueId: data.venueId,
          startTime: data.startTime,
          endTime: data.endTime,
          date: dateKey,
          uniqueId: data.uniqueId
        });
    }
    if (type === 'Booked') {
      slotData.bookedSlots.push({
        displayLabel: 'Booked',
        isAvailable: false,
        isSelected: false,
        price: data.amount,
        slotId: this.getSlotId(data, dateKey),
        tenantId: data.tenantId,
        productId: data.productId,
        productName: this.selectedProduct.name,
        venueName: this.venueName,
        venueId: data.venueId,
        customerName: data.username,
        customerEmail: data.email,
        customerPhone: data.phoneNumber,
        bookingId: data.confirmationId,
        bookingDate: data.bookingDate,
        startTime:data.startTime,
        endTime: data.endTime,
        paymentType: data.paymentType,
        bookedOn: data.createdAt,
        notes:data.notes,
        isBooked: true
      });
    }
    return slotData;
  }



  getDateStrFromTime(timeValue) {
    const date = new Date(timeValue);
    return date.getFullYear() + '-' + this.addZeroIfRequired(date.getMonth() + 1) + '-' +
                  this.addZeroIfRequired(date.getDate());
  }

  parseSlotData(data, type) {
    if (data.startTime && data.endTime) {
      const yearStrLength = 4;
      const dateLength = 10;
      const uniqueId = data.uniqueId;
      let indexOfFirstHyphen;
      let dateKey;
      if (uniqueId) {
        indexOfFirstHyphen = uniqueId.indexOf('-');
        dateKey = 'date_' + uniqueId.substr(indexOfFirstHyphen - yearStrLength, dateLength);
      } else if (type === 'Booked') {
        dateKey = 'date_' + this.getDateStrFromTime(data.bookingDate);
      }

      if (this.slotDetails[data.startTime] && this.slotDetails[data.startTime][dateKey]) {
        const slotData = this.slotDetails[data.startTime][dateKey];
        this.addSlotData(data, slotData, type, dateKey);
      } else {
        const slotData = {
          availableSlots: [],
          bookedSlots: []
        };
        if (!this.slotDetails[data.startTime]) {
          this.slotDetails[data.startTime] = {};
        }
        this.slotDetails[data.startTime][dateKey] = this.addSlotData(data, slotData, type, dateKey);
      }
    }
  }

  getDayColumns() {
      const dayColumns = [];
      this.dayColumnsObj = [];
      // add past and future 7 days including the current day in the columndData array
      for (let i=0; i < this.dateRange; i++) {
        const date = this.addXNumberOfDays(this.selectedDate, i);

        /* new Date();
        // increment or decrement the date day based on the i value
        date.setFullYear(this.selectedDate.getFullYear());
        date.setMonth(this.selectedDate.getMonth());
        date.setDate(this.selectedDate.getDate() + i); */

        // const monthWithDate = date.getDate() + ' ' + (date.getMonth() + 1);
        const dateTime = date.getTime();
        const dateFormat = 'date_' + this.getDateFormatWithSeparator(date, '-');
        this.dayColumnsObj.push({
          dateTime: dateTime,
          dateFormat: dateFormat
        });
        // this.dayColumnsObj.push({
        //   date: date
        // });
        // dayColumns.push('time' + dateTime);
        dayColumns.push(dateFormat);
      }
      return dayColumns;
  }

  getDateFormatWithSeparator(date:Date, separator = '-') {
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + separator + month + separator + day;
  }

  // getDayHeader(timeString = '') {
  //   const splitTimeStr = timeString.split('time');
  //   const dateTime = splitTimeStr && splitTimeStr.length > 0 ? splitTimeStr[1] : null;
  getDayHeader(dateTime) {
    if (dateTime) {
      const dateObj = new Date(dateTime);
      return dateObj.getDate() + ' ' + this.months[dateObj.getMonth()];
    }
    return '';
  }
  // getWeekDay(timeString = '') {
    // const splitTimeStr = timeString.split('time');
    // const dateTime = splitTimeStr && splitTimeStr.length > 0 ? splitTimeStr[1] : null;
  getWeekDay(dateTime) {
    if (dateTime) {
      const dateObj = new Date(dateTime);
      return this.weekdays[dateObj.getDay()]
    }
    return '';
  }
  setAllDayHoursInSource() {
    const minsPerHour = 60;
    const hoursPerDay = 24;
    const slotCount = (minsPerHour * hoursPerDay) / this.slotInterval;

    for (let i = 0; i < slotCount; i++) {
      let dateTime;
      let hours;
      let minutes: number | string = i * this.slotInterval;
      if (minutes < minsPerHour) {
        hours = '00';
      } else {
        hours = Math.floor(minutes / minsPerHour);
        // if (hours < 10) {
        //   hours = '0' + hours;
        // }
        minutes = minutes % minsPerHour;
      }
      if (minutes === 0) {
        minutes = '00';
      }
      dateTime = hours + ':' + minutes;
      this.dataSource.push({
        dateTime: dateTime
      });
      this.origDataSource = this.dataSource;
    }
  }
  getFormattedTime(timeString) {
    let formattedTime = '';
    const splitTimeString = timeString.split(':');
    let timeValue = parseInt(splitTimeString[0], 10);
    const minsValue = parseInt(splitTimeString[1], 10);

    if (timeValue >= 12) {
      if (timeValue !== 12) {
        timeValue = timeValue % 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' PM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' PM';
      }
    } else {
      if (timeValue === 0) {
        timeValue = 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' AM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' AM';
      }
    }
    return formattedTime;
  }

  ngOnDestroy() {
    if (this.bookingSlotsSubscription) {
      this.bookingSlotsSubscription.unsubscribe();
    }
    if(this.reloadDataSubscription) {
      this.reloadDataSubscription.unsubscribe();
    }
    if(this.selectedSlotRemovedSubscription) {
      this.selectedSlotRemovedSubscription.unsubscribe();
    }
  }
}
