import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-verify-details',
  templateUrl: './verify-details.component.html',
  styleUrls: ['./verify-details.component.css']
})
export class VerifyDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('content', {static: false})
  private content: TemplateRef<any>;
  @ViewChild('resetPassword', {static: false})
  private resetPassword: TemplateRef<any>;
  verifyOTPFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;
  verifyOTPModalReference;
  resetPasswordModalReference;
  messageToDisplayAfterOtpSent = '';
  minPasswordLength = 8;
  sendOTPClicked;
  onClickVerifyError;
  showOnClickVerifyError = false;
  user;
  constructor(private modalService: NgbModal, private authService: AuthService,
    private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.showOnClickVerifyError = false;
    this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT));
    this.verifyOTPFormGroup = new FormGroup({
      regotp0: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$')
      ]),
      regotp1: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$')
      ]),
      regotp2: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$')
      ]),
      regotp3: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$')
      ])
    });
    console.log("VerifyOTPGroup", this.verifyOTPFormGroup);

    this.resetPasswordFormGroup = this.formBuilder.group({
      passwordFormControl: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minPasswordLength),
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$')

        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,20}")
      ]),
      reenterPasswordFormControl: new FormControl('', [
        Validators.required
      ])
    }, { validator: this.checkPasswords });
  }

  ngAfterViewInit() {
    this.verifyOTPModalReference = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false});
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.passwordFormControl;
    let confirmPass = group.controls.reenterPasswordFormControl;
    if (
      confirmPass.errors &&
      !confirmPass.errors.confirmedValidator
    ) {
      return;
    }
    if (pass.value !== confirmPass.value) {
      confirmPass.setErrors({ confirmedValidator: true });
    } else {
      confirmPass.setErrors(null);
    }
  }


  /* onClickVerify() {
      if (this.verifyOTPFormGroup.invalid) {
        this.showOnClickVerifyError = true;
        return;
      }
      // Implement OTP verification logic here
    } */

  onClickVerify() {

        this.showOnClickVerifyError = false;
        const otp = this.verifyOTPFormGroup.get('regotp0').value + this.verifyOTPFormGroup.get('regotp1').value
          + this.verifyOTPFormGroup.get('regotp2').value + this.verifyOTPFormGroup.get('regotp3').value;

          console.log("OTP", otp);
          this.authService.validateOTP(this.user.email, otp, AppConstants.OTP_TYPE_EMAIL).subscribe(
            (response: any) => {
              if(response && response.accessToken) {
                sessionStorage.setItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN, JSON.stringify(response.accessToken));
              }
              this.verifyOTPModalReference.dismiss();
              //this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_SIGN_UP_SUCCESS);
              this.resetPasswordModalReference = this.modalService.open(this.resetPassword, {ariaLabelledBy: 'reset-password-modal', backdrop: 'static', keyboard: false});
            },
            (error: HttpErrorResponse) => {
              console.log(error);
              this.showOnClickVerifyError = true;
              this.onClickVerifyError = error.error.errors[0].message;
            }
        )

  }

  get resetPasswordFormControls() {
    return this.resetPasswordFormGroup.controls;
  }

  onClickCancel() {
    this.showOnClickVerifyError = false;
    this.verifyOTPModalReference.dismiss();
    sessionStorage.removeItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN).then(() => {
      window.location.reload();
    });
  }

  getCodeBoxElement(index) {
    return (<HTMLInputElement>document.getElementById('codeBox' + index));
  }

  onKeyUpEvent(index, event) {
    this.showOnClickVerifyError = false;
    const eventCode = event.which || event.keyCode;
    // if (!this.isNumber(event)) {
    //   return false;
    // }
    if (this.getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        this.getCodeBoxElement(index+ 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onFocusEvent(index) {
    this.showOnClickVerifyError = false;
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if (!currentElement.value) {
          currentElement.focus();
          break;
      }
    }
  }

  onClickedSendOTP() {
      this.showOnClickVerifyError = false;
      this.sendOTPClicked = true;
      this.authService.sendOTP(this.user.email, AppConstants.OTP_TYPE_EMAIL).subscribe(
        response => {
          this.messageToDisplayAfterOtpSent = 'OTP sent successfully';
        },
        errorResponse => {
          this.messageToDisplayAfterOtpSent = 'Failed to send OTP';
        }
      )
    }

  onResetPasswordClicked() {
    console.log("verify-details forgot details");
    if(this.resetPasswordFormGroup.valid) {
        this.authService.resetPassword(this.resetPasswordFormGroup.get('passwordFormControl').value, this.resetPasswordFormGroup.get('reenterPasswordFormControl').value).subscribe(
        response => {
          this.authService.login(this.user.email, this.resetPasswordFormGroup.get('passwordFormControl').value).pipe(first())
          .subscribe(
            loginResponse => {
              this.resetPasswordModalReference.dismiss();
              this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_SIGN_UP_SUCCESS);
            },
            errorLoginResponse => {
              alert("Error while trying to login.");
            }
          )
        },
        error => {
          alert("Error while reset password.");
          this.resetPasswordModalReference.dismiss();
          sessionStorage.removeItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
          this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN).then(() => {
            window.location.reload();
          });
        }
      );
    }
  }

}
