<div>
  <div class="content-wrapper">
    <div class="container" style="min-height:700px;">
      <div class="centered-row" style="padding-top:40px;">
        <img src="../../assets/img/customer-consultant.svg" style="height: 55px;width: 55px;" alt="Your Image">
        <h4 class = "descriptionHeader" >Help and Support</h4>
      </div>
      <div >
        <h5 class = "descriptionHeader" style="margin-left: 50px; align-items: center;padding-top:20px;"> For any queries contact </h5>
        <h6 class = "descriptionHeader" style="margin-left: 50px; margin-top: 10px;">Email</h6>
        <h4 class = "aboutUsParagraph" style="margin-left: 50px; margin-top: 10px;">helpdesk@bookingdexter.com</h4>
      </div>
      <div>
        <h6 class = "descriptionHeader" style="align-items: left; margin-left: 50px;margin-top: 10px;">Phone</h6>
        <h4 class = "aboutUsParagraph" style="align-items: left; margin-left: 50px; margin-top: 10px; margin-bottom: 30px; padding-bottom:40px;">+91-8530078212</h4>
      </div>
      <div class="col">
        <h4 class = "descriptionHeader" style="text-align:center;">FAQ for Registered Businesses</h4>
        <div style="margin-left: 20px;">
          <h5 class = "descriptionHeader" style="padding-top:20px;"> Is there a Setup Fee?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> No, there are no setup charges. Configuration of the services and booking slots is very easy and intuitive.</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> Can I try it for free?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> Yes, absolutely. You can register your business and try it free for 6 months. Payment services are charged at 3% even during the trial period.</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> Can I setup services with a multiple or recurring sessions?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> Yes, BookingDexter platform allows businesses to create services that can be recurring monthly or a bundle of multiple sessions. Examples:
            <p>&nbsp; &nbsp; &nbsp;A monthly batch of a coaching scheduled every Monday, Wednesday and Friday for an hour.</p>
            <p>&nbsp; &nbsp; &nbsp;A Service package of 12 sessions that are selected by the customers.</p>
            <p>&nbsp; &nbsp; &nbsp;A Service package of 8 sessions scheduled every Saturday and Sunday for 30 minutes from 4pm to 6 pm (in 4 batches)</p></h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> Can I set the price based on the slot time or the service?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> Yes, you can configure different price for different services or slot timings</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> How do I manage holidays or the days of maintenance?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> You can set the holidays or the shut down period in the system and system will not allow customers to book slots on these dates.</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> Can a customer cancel the booked slots?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> No, the platform does not allow the cancellation of the booked slots. The cancellation of the slots and the refunds is a responsibility of the business who owns the venue or provides the service. Customers need to call the facility for cancellation or any changes in the slots</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"> When will I get the payment of the services booked?</h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"> Payments are settled once a week.</h6>

          <h5 class = "descriptionHeader" style="padding-top:20px;"></h5>
          <h6 class = "aboutUsParagraph" style="padding-top:20px;"></h6>
        </div>
      </div>
<!--      <div class="col-md-6" style="align: center;">
        <div class="row">
          <img src="../../assets/img/customer-consultant.svg" style="height: 80px;width: 80px; margin-top: 20px; margin-left: 10px;"/>
          <h4 class = "descriptionHeader" style="margin-top: 20px;margin-left: 10px;">Support</h4>
        </div >
      </div>-->


    </div>
  </div>
</div>

