import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { AppConstants } from '../AppConstants';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  SA = false;
  usersData: any[] = [];
  filteredUsersData: any[] = [];
  externalUserContext: any;
  currentUserContext: any;
  roleId: any;
  columnDefs: ColDef[] = [];
  mobileColumnDefs: ColDef[] = [];
  gridOptions: GridOptions = <GridOptions>{};
  searchText: string = '';

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.externalUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    console.log('Landing page ', this.currentUserContext);
    console.log('Landing page ext ', this.externalUserContext);

    if (this.externalUserContext) {
      this.roleId = JSON.parse(this.externalUserContext).roleId;
    } else if (this.currentUserContext) {
      this.roleId = JSON.parse(this.currentUserContext).roleId;
    } else {
      this.roleId = 0; // User not logged in
    }

    if (this.roleId == 1) {
      this.SA = true;
      this.getAllUsers(); // Load users only for Super Admin
    } else {
      this.SA = false;
    }

    // Define columns for desktop and mobile
    this.columnDefs = [
      { headerName: 'ID', field: 'id', width: 60 },
      { headerName: 'NAME', valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`, width: 180 },
      { headerName: 'EMAIL', field: 'email', width: 200 },
      { headerName: 'PHONE', field: 'phoneNumber', width: 120 },
      { headerName: 'STATUS', field: 'status', width: 100 },
      { headerName: 'TENANT', field: 'tenantId', width: 100 },
      { headerName: 'ROLE', field: 'roleId', width: 80 },
      { headerName: 'LOGIN', field: 'lastlogin', width: 200 }
    ];

    this.mobileColumnDefs = [
      { headerName: 'ID', field: 'id', width: 60 },
      { headerName: 'NAME', valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`, width: 180 },
      { headerName: 'EMAIL', field: 'email', width: 200 },
      { headerName: 'PHONE', field: 'phoneNumber', width: 120 },
      { headerName: 'STATUS', field: 'status', width: 100 },
      { headerName: 'TENANT', field: 'tenantId', width: 100 }
    ];

    this.updateColumnDefs();
  }

  updateColumnDefs() {
    const isMobile = window.innerWidth < 600; // Adjust the breakpoint as needed
    if (isMobile) {
      console.log('Mobile');
      this.gridOptions.columnDefs = this.mobileColumnDefs;
    } else {
      console.log('Desktop');
      this.gridOptions.columnDefs = this.columnDefs;
    }
  }

  getAllUsers() {
    console.log('Users : GetAllUsers');
    this.authService.getAllUsers().subscribe(
      (response: any[]) => {
        console.log('Users: ', response);
        this.usersData = response;
        this.filteredUsersData = [...this.usersData]; // Initialize filtered data
      },
      (error) => {
        console.error('Error fetching users:', error);
      }
    );
  }

  search() {
    console.log('Search string: ', this.searchText);
    const searchTextLower = this.searchText.toLowerCase();

    this.filteredUsersData = this.usersData.filter(user =>
      Object.values(user).some(value =>
        value.toString().toLowerCase().includes(searchTextLower)
      )
    );

    if (this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.filteredUsersData);
    }
  }
}



/*
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { AppConstants } from '../AppConstants';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(private authService: AuthService) {

    this.gridOptions = <GridOptions>{};

  }

  SA = false;

  usersData = [];
  externalUserContext;
  currentUserContext;
  roleId;
  columnDefs: ColDef[] = [];
  mobileColumnDefs: ColDef[] = [];
  gridOptions: GridOptions;
  searchText:any;




  ngOnInit() {

        this.externalUserContext =  sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
        this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
        console.log('Landing page ', this.currentUserContext);
        console.log('Landing page ext ',this.externalUserContext);

        if(this.externalUserContext) {
          this.roleId = JSON.parse(this.externalUserContext).roleId;
        } else if(this.currentUserContext) {
          this.roleId = JSON.parse(this.currentUserContext).roleId;
        } else {
          this.roleId = 0; // user not logged in
        }

        if(this.roleId == 1)
        {
          this.SA = true;
          this.getAllUsers(); // Only for Superadmin Need to add condition
        }
        else
        {
          this.SA = false;
        }

          this.columnDefs = [
              { headerName: 'ID', field: 'id', width: 60 },
              { headerName: 'NAME',  valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`, width: 180 },
              { headerName: 'EMAIL', field: 'email', width: 200 },
              { headerName: 'PHONE', field: 'phoneNumber', width: 120},
              { headerName: 'STATUS', field: 'status', width: 100},
              { headerName: 'TENANT', field: 'tenantId', width: 100},
              { headerName: 'ROLE', field: 'roleId', width: 80},
              { headerName: 'LOGIN', field: 'lastlogin', width: 200}
          ];

          this.mobileColumnDefs = [
                { headerName: 'ID', field: 'id' , width: 60  },
                { headerName: 'NAME',  valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`, width: 180 },
                { headerName: 'EMAIL', field: 'email' , width: 200 },
                { headerName: 'PHONE', field: 'phoneNumber' , width: 120},
                { headerName: 'STATUS', field: 'status' , width: 100},
                { headerName: 'TENANT', field: 'tenantId', width: 100},

          ];

        this.updateColumnDefs();

  }

  updateColumnDefs() {
        const isMobile = window.innerWidth < 600; // Adjust the breakpoint as needed

        if (isMobile) {
        console.log("Mobile");
          this.gridOptions.columnDefs = this.mobileColumnDefs;

        } else {
         console.log("Desktop");
          this.gridOptions.columnDefs = this.columnDefs;
        }
    }

  getAllUsers()
  {
      console.log("Users : GetAllUsers ");
      this.authService.getAllUsers().subscribe(
      (response: any[]) => {
        console.log("Users: ", response);

        this.usersData = response;
        console.log(" Users Data : ", this.usersData);
      },
      (error) => {

      }
      );
  }

  search() {
      console.log("search string: ", this.searchText);
      if (this.gridOptions.api) {
        this.gridOptions.api.setQuickFilter(this.searchText);
      }
  }

}
 */
