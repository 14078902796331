export class AppConstants {
    // Session Storage Constants
    public static SESSION_STORAGE_CURRENT_USER_CONTEXT = "current-user-context";
    public static SESSION_STORAGE_ACCESS_TOKEN = "access-token";
    public static SESSION_STORAGE_EXTERNAL_USER_CONTEXT = "external-user-context";
    public static SESSION_STORAGE_CLICKED_TAB = "clicked-tab";
    public static SESSION_STORAGE_TENANTID = 0;
    public static SESSION_STORAGE_ROLEID = 3;
    public static CONFIRMATION_ID = "confirmationId";

    // Status
    public static STATUS_ACTIVE = 'Active';
    public static STATUS_INACTIVE = 'Inactive';

    public static TAB_ADMIN_BOOKINGS = "admin-bookings";
    public static TAB_TRANSACTIONS = "transactions";
    public static TAB_COMPANY_PROFILE = "company-profile";
    public static TAB_PRODUCTS = "products";
    public static TAB_VENUES = "venues";
    public static TAB_THEMES = "themes";
    public static TAB_POLICY = "policy";
    public static TAB_EXCEPTIONS = "exceptions";

    public static OTP_TYPE_EMAIL = "email";

    public static PHONE_NUMBER_PREFIX = "+91-";

    public static SINGLE_SESSION = "Single Session";
    public static MULTI_SESSION = "Multi Session";
}
