import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { DateService } from '../date.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AdminBookingsService } from '../service/admin-bookings.service';

@Component({
  selector: 'app-booked-details',
  templateUrl: './booked-details.component.html',
  styleUrls: ['./booked-details.component.css']
})
export class BookedDetailsComponent implements OnInit {
  dataContent: string;
  cancelBookingResponse: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, public dateService: DateService,  
              public dialogRef: MatDialogRef<BookedDetailsComponent>,
              private dialog: MatDialog,
              private adminBookingSvc: AdminBookingsService) { }

  ngOnInit() {
    this.dataContent = JSON.stringify(this.data);
    console.log("Booking Details : ", this.data);

  }

  formatDate(dateString) {
    // Create a new Date object from the provided date string
    var date = new Date(dateString);

    // Define options for formatting the date
    var options : Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC' // Adjust as per your time zone if needed
    };

    // Format the date using the specified options
    var formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }

  removeBooking(data) {
    alert(JSON.stringify(data));
  }

  cancelBooking() {
    this.cancelBookingResponse = this.authService.cancelBooking(this.data.bookingId).subscribe(
      (response: any) => {
        console.log("Booking Cancelled for the Booking ID: ", this.data.bookingId);
      },
      (error) => {
        console.log('Cancellation Failed :' + JSON.stringify(error));
      }
    )
  }

  deleteSlot(slotInfo) {
    const dialogRefConfirm = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { message: 'Are you sure you want to cancel the booking slot?' },
      panelClass: 'confirmation-dialog-container'
    });
    // Handle dialog closing
    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        // User confirmed, cancel the booking
        const slotId = slotInfo.tenantId + '_' + slotInfo.productId + '_' + this.getDateStrFromTime(slotInfo.bookingDate)
                          + '_' + slotInfo.startTime + '_' + slotInfo.endTime + '_' + slotInfo.venueId;
        this.authService.cancelSlots([slotId], slotInfo.bookingId).subscribe(
          (response: any) => {
            this.adminBookingSvc.reloadData.next({
              reloadData: true,
              cancelledSlot: true
            });
            console.log("Booking Cancelled for the Booking ID: ", slotId);
          },
          (error) => {
            console.log('Cancellation Failed :' + JSON.stringify(error));
          }
        );
      } else {
        // User cancelled, do nothing or handle accordingly
      }
    });


  }

  getDateStrFromTime(timeValue) {
    const date = new Date(timeValue);
    return date.getFullYear() + '-' + this.addZeroIfRequired(date.getMonth() + 1) + '-' +
      this.addZeroIfRequired(date.getDate());
  }

  addZeroIfRequired(value: number) {
    if (value > 9) {
      return value;
    }
    return '0' + value;
  }

}
