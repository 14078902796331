<!-- border: 1px solid #E3E3E3; -->
<div class="container" style="padding: 0px; margin: 0px;background-color: #ffffff;width: 1153px;height: 825px;border-radius: 5px;">
    <div class="row">
        <div class="col">
            <h1 style="color: #14274e;margin-top: 40px;margin-left: 35px;">Facility Details</h1>
            <form [formGroup]="facilityDetailsFormGroup">
                <div class="form-group" style="margin-right: 30px;margin-left: 37px;width: 350px;margin-top: 28px;">
                        <div>
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                        FACILITY NAME
                                </label>
                                <input type="text" id="facility-name" class="form-control" formControlName="facilityNameFormControl" placeholder="Facility Name"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.facilityNameFormControl.hasError('required') && facilityDetailsFormControls.facilityNameFormControl.touched" style="color: red;font-size: 14px;">
                                        Facility Name is required.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                        FACILITY TYPE
                                </label>
                                <!-- <input type="text" id="facility-name" class="form-control" placeholder="Facility Name"
                                style="width: 350px;height: 40px;" /> -->
                                <select [(ngModel)]="selectedFacilityTypeValue" id="facility-type" class="form-control" formControlName="facilityTypeFormControl" (change)="selectValueChangeForFacilityType()" required>
                                        <!-- <option value="" hidden>
                                                Facility Type
                                        </option> -->
                                        <option *ngFor="let facilityType of facilityTypes" [value]="facilityType.id">
                                                {{facilityType.tenantCategory}}
                                        </option>
                                </select>
                                <div *ngIf="facilityDetailsFormControls.facilityTypeFormControl.hasError('required') && facilityDetailsFormControls.facilityTypeFormControl.touched" style="color: red;font-size: 14px;">
                                        Facility Type is required.
                                </div>
                                <!-- <mat-form-field>
                                        <mat-select>
                                          <mat-option *ngFor="let facilityType of facilityTypes" [value]="facilityType.value">
                                            {{facilityType.viewValue}}
                                          </mat-option>
                                        </mat-select>
                                </mat-form-field> -->
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="address-line-1">
                                        ADDRESS LINE 1
                                </label>
                                <input type="text" id="address-line-1" class="form-control" formControlName="addressLine1FormControl" placeholder="Address Line 1"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.addressLine1FormControl.hasError('required') && facilityDetailsFormControls.addressLine1FormControl.touched" style="color: red;font-size: 14px;">
                                        Address Line 1 is required.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="address-line-2">
                                        ADDRESS LINE 2
                                </label>
                                <input type="text" id="address-line-2" class="form-control" formControlName="addressLine2FormControl" placeholder="Address Line 2"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.addressLine2FormControl.hasError('required') && facilityDetailsFormControls.addressLine2FormControl.touched" style="color: red;font-size: 14px;">
                                        Address Line 2 is required.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="city">
                                        CITY
                                </label>
                                <!-- <input type="text" id="city" class="form-control" placeholder="City"
                                style="width: 350px;height: 40px;" /> -->
                                <select [(ngModel)]="selectedCityValue" id="city" class="form-control" formControlName="cityFormControl" (change)="selectValueChangeForCity()" required>
                                        <!-- <option value="" hidden>
                                                Facility Type
                                        </option> -->
                                        <option *ngFor="let city of cities" [value]="city">
                                                {{city}}
                                        </option>
                                </select>
                                <div *ngIf="facilityDetailsFormControls.cityFormControl.hasError('required') && facilityDetailsFormControls.cityFormControl.touched" style="color: red;font-size: 14px;">
                                        City is required.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="pincode">
                                        PINCODE
                                </label>
                                <input type="text" id="pincode" class="form-control" formControlName="pincodeFormControl" placeholder="Pincode"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.pincodeFormControl.hasError('required') && facilityDetailsFormControls.pincodeFormControl.touched" style="color: red;font-size: 14px;">
                                        Pincode is required.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-1">
                                        PHONE NUMBER 1
                                </label>
                                <input type="text" id="phone-number-1" class="form-control" formControlName="phoneNumber1FormControl" placeholder="Phone Number 1"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('required') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number is required.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be in digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at least 10 digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at most 10 digits.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-2">
                                        PHONE NUMBER 2
                                </label>
                                <input type="text" id="phone-number-2" class="form-control" formControlName="phoneNumber2FormControl" placeholder="Phone Number 2"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be 10 digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at least 10 digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at most 10 digits.
                                </div>
                        </div>
                        <div style="margin-top: 10px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-3">
                                        PHONE NUMBER 3
                                </label>
                                <input type="text" id="phone-number-3" class="form-control" formControlName="phoneNumber3FormControl" placeholder="Phone Number 3"
                                style="width: 350px;height: 40px;" />
                                <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be 10 digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at least 10 digits.
                                </div>
                                <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                        Phone Number must be at most 10 digits.
                                </div>
                        </div>
                    </div>
            </form>

        </div>
        <div class="col">
                <img src="../../assets/img/5.png" style="width: 586px;height: 342px;margin-top: 129px;margin-right: 34px;margin-bottom: 0px;" />
                <button class="btn btn-primary" type="button" (click)="onNextClicked()" [disabled]="facilityDetailsFormGroup.invalid" style="background-color: #14274e;height: 40px;width: 168px;float: right;margin-right: 34px;margin-top: 285px;">
                        Next
                </button>
                </div>
    </div>
</div>
