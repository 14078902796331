import { Component } from '@angular/core';
import { AppConstants } from './AppConstants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser;
  // http://ec2-3-108-63-28.ap-south-1.compute.amazonaws.com
  constructor(){
    this.currentUser = sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN);
  }
}
