import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { UIRoutes } from '../UIRoutes';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  user;

  constructor(private authService: AuthService, private router: Router) { }
  ngOnInit() {

     if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
        this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT))
      } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
        this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT))
      } else {
        this.user = undefined;
      }
      console.log("Home Init User: ", this.user );

  }

  onClickCreateNow() {
    if (this.isUserDefined())
    {
      alert("You have already registered your business. Logout to register the new one.");

    }
    else
    {
      console.log("Home  undefined" );
      this.router.navigateByUrl("");
    }
    //this.modalReference = this.modalService.open(this.content, { windowClass: 'bottom-container', ariaLabelledBy: 'modal-basic-title'});
  }

    isUserDefined() {
      if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
        return true;
      } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
        return true;
      } else {
        return false;
      }
    }

}
