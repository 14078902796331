<div class="confirmation-dialog-container">
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content>
    {{ message }}
  </div>
  <div mat-dialog-actions>
    <button  class="pop-up-btn-white" mat-button (click)="onConfirm()">Confirm</button>
    <button class="pop-up-btn-blue" style="margin-left: 30px;" mat-button (click)="onCancel()">Cancel</button>
  </div>
</div>
