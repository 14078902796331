<div class="container" style="margin-top: 10px;background-color: #fff;margin-left: 0px;">
    <div class="row facility-info">
        <div class="col" style="margin-left: 15px;margin-top: 20px;margin-right: 15px;">
            <div style="background-color: #DFF5F2;">
                <div class="row">
                    <div class="col" style="margin-left: 36px;">
                        <h1 style="margin-top: 30px;color: #14274e;font-size: 32px;">
                            {{tenantDetails?.tenantName}}
                        </h1>
                        <p style="color: #14274e;">
                            {{tenantDetails?.description}}
                        </p>
                    </div>
                    <div class="col"><img [src]="getImageToShowInTenantDetailsHeader()" style="width: 204px;height: 119px;float: right;margin-right: 30px;margin-top: 10px;" />
                    </div>
                </div>
                <div *ngIf="showFacilityFeatures" class="overlay" (click)="onClickEditIcon()">
                    <div class="hover-icon-with-edit-text">
                        <div>
                            <img src="../../assets/img/iconfinder_Pen_728966.svg" style="width: 30px; height: 30px;">
                        </div>
                        <div>
                            EDIT
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showFacilityFeatures" class="row">
        <div class="col" style="margin-left: 15px;">
            <div>
                <p class="admin-string-primary" style="margin-top: 15px;">
                    FACILITY FEATURES
                </p>
                <hr style="background-color: #D8DFE6;" />
                <p class="admin-string-secondary">
                    AMENITIES
                </p>
                <div style="margin-bottom: 10px;">
                    <mat-chip-list aria-label="Amenities" >
                        <mat-chip style="border-radius: 4px; background-color: #DDE4E9; font: normal normal normal 12px/17px OpenSans-R; color: #333333;margin-right: 10px;"
                            *ngFor="let amenity of amenities"
                            [removable]="removable"
                            (removed)="deleteAmenity(amenity)" >
                            {{amenity.amenityName}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div>
                    <div *ngIf="showAddNewAmenitiesButton">
                        <button class="btn btn-primary admin-button" type="button" (click)="clickedAddNewAmenitiesButton()" >
                            Add New
                        </button>
                    </div>
                    <div *ngIf="!showAddNewAmenitiesButton">
                        <form [formGroup]="amenityFormGroup">
                            <p style="font-size: 14px;">
                                Add Your Own
                                <input type="text" formControlName="amenityNameFormControl" style="margin-left: 30px; width: 200px;/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;" />
                                <button class="btn btn-primary admin-button" type="button" [disabled]="amenityFormGroup.invalid" (click)="createNewAmenity()" >
                                    Save
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <div style="margin-top: 40px;">
                <p class="admin-string-primary">
                    HOLIDAYS
                </p>
                <hr style="background-color: #D8DFE6;" />
                <p class = "admin-string-secondary">
                    YEARLY HOLIDAYS
                </p>
                <div style="margin-bottom: 10px;">
                    <mat-chip-list aria-label="Holidays">
                        <mat-chip style="border-radius: 4px; background-color: #DDE4E9;font: normal normal normal 12px/17px OpenSans-R; color: #333333; margin-right: 10px;"
                            *ngFor="let holiday of holidays"
                            [removable]="removable"
                            (removed)="deleteHoliday(holiday)">
                            {{holiday.displayDate}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div>
                    <div *ngIf="showAddNewHolidaysButton">
                        <button class="btn btn-primary admin-button" type="button" (click)="clickedAddNewHolidaysButton()">
                            Add New
                        </button>
                    </div>
                    <div *ngIf="!showAddNewHolidaysButton">
                        <form [formGroup]="holidayFormGroup">
                            <p style="font-size: 14px;">
                                Add Your Own
                                <input type="text" [matDatepicker]="picker" formControlName="holidayFormControl" style="margin-left: 30px;/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <button class="btn btn-primary admin-button" type="button" [disabled]="holidayFormGroup.invalid" (click)="createNewHoliday()">
                                    Save
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <div style="margin-top: 40px;">
                <p class="admin-string-primary">
                    WEBSITE INFORMATION
                </p>
                <hr style="background-color: #D8DFE6;" />
                <p class="admin-string-secondary">
                    Google Maps Link
                </p>
                <div>
                        <form [formGroup]="googleMapsLinkFormGroup">
                            <p style="font-size: 14px;">
                                <input type="text" formControlName="googleMapsLinkFormControl" style="/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;width: 750px;" />
                                <button class="btn btn-primary admin-button" type="button" [disabled]="googleMapsLinkFormGroup.invalid" (click)="updateGoogleMapsLink()" style="margin-left: 30px;width: 42px;height: 24px;font-size: 12px;color: #7c7575;background-color: #dde4e9;padding: 0px;">
                                    Save
                                </button>
                            </p>
                        </form>
                </div>
            </div>
            <div style="margin-top: 40px;">
                <p class="admin-string-primary">
                    RULES
                </p>
                <hr style="background-color: #D8DFE6;" />
                <p class="admin-string-secondary">
                    RULES FOR FACILITY
                </p>
                <div style="margin-bottom: 10px;">
                    <p style="font-size: 14px;" *ngFor="let rule of rules">
                        {{rule.ruleIndex}}) {{rule.description}}
                    </p>
                </div>
                <div>
                    <div *ngIf="showAddNewRulesButton">
                        <button class="btn btn-primary admin-button" type="button" (click)="clickedAddNewRulesButton()">
                            Add New
                        </button>
                    </div>
                    <div *ngIf="!showAddNewRulesButton">
                        <form [formGroup]="ruleFormGroup">
                            <p style="font-size: 14px;">
                                {{rules.length + 1}})<input type="text" formControlName="ruleFormControl" style="margin-left: 30px;/*border-top: none;*//*border-right: none;*//*border-left: none;*/outline: 0;border: none;border-bottom: 1px solid black;width: 750px;" />
                                <button class="btn btn-primary admin-button" type="button" [disabled]="ruleFormGroup.invalid" (click)="createNewRule(rules.length + 1)" style="margin-left: 30px;width: 42px;height: 24px;font-size: 12px;color: #7c7575;background-color: #dde4e9;padding: 0px;">
                                    Save
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!showFacilityFeatures" class="row">
        <div class="col" style="margin-left: 15px;">
            <form [formGroup]="facilityDetailsFormGroup">
                <div class="form-group">
                    <div>
                        <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                            Facility Details
                            <button class="btn btn-primary" type="button" [disabled]="facilityDetailsFormGroup.invalid" (click)="onSaveClicked()" style="background-color: #ffffff;color: #14274E;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border-color: #14274E;">
                                Save
                            </button>
                            <button class="btn btn-primary" (click)="onCancelClick()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;">
                                Cancel
                            </button>
                        </p>
                        <hr style="background-color: #D8DFE6;" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <div style="float: left;margin-right: 100px;">
                                    <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-name">
                                        FACILITY NAME
                                    </label>
                                    <input type="text" id="facility-name" [(ngModel)]="tenantDetails.tenantName" formControlName="facilityNameFormControl" class="form-control" placeholder="Facility Name"
                                        style="width: 350px;height: 40px;" />
                                    <div *ngIf="facilityDetailsFormControls.facilityNameFormControl.hasError('required') && facilityDetailsFormControls.facilityNameFormControl.touched" style="color: red;font-size: 14px;">
                                        Facility Name is required.
                                    </div>
                                </div>
                                <div style="float: left;">
                                    <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-type">
                                        FACILITY TYPE
                                    </label>
                                    <select [(ngModel)]="tenantDetails.tenantCategoryId" id="facility-type" class="form-control" formControlName="facilityTypeFormControl" (change)="selectValueChangeForFacilityType()" style="width: 350px;height: 40px;" required>
                                        <!-- <option value="" hidden>
                                                Facility Type
                                        </option> -->
                                        <option *ngFor="let facilityType of facilityTypes" [value]="facilityType.id">
                                                {{facilityType.tenantCategory}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col">
                            <div>
                                <div style="float: left;margin-right: 100px;">
                                    <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="facility-description">
                                        FACILITY DESCRIPTION
                                    </label>
                                    <textarea id="facility-description" [(ngModel)]="tenantDetails.description" class="form-control" formControlName="facilityDescriptionFormControl" placeholder="Facility Description" rows="2"
                                        style="width: 350px;">
                                    </textarea>
                                    <div *ngIf="facilityDetailsFormControls.facilityDescriptionFormControl.hasError('required')" style="color: red;font-size: 14px;">
                                        Facility Description is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                                    Address
                                </p>
                                <hr style="background-color: #D8DFE6;" />
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="address-line-1">
                                                ADDRESS LINE 1
                                            </label>
                                            <input type="text" id="address-line-1" [(ngModel)]="tenantDetails.address.addressLine1" formControlName="addressLine1FormControl" class="form-control" placeholder="Address Line 1"
                                                style="width: 350px;height: 40px;" />
                                            </div>
                                        <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="address-line-2">
                                                ADDRESS LINE 2
                                            </label>
                                            <input type="text" id="address-line-2" [(ngModel)]="tenantDetails.address.addressLine2" formControlName="addressLine2FormControl" class="form-control" placeholder="Address Line 2"
                                                style="width: 350px;height: 40px;" />
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div style="margin-top: 20px;">
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="city">
                                                CITY
                                            </label>
                                            <select [(ngModel)]="tenantDetails.address.city" id="city" class="form-control" formControlName="cityFormControl" (change)="selectValueChangeForCity()" style="width: 350px;height: 40px;" required>
                                                <!-- <option value="" hidden>
                                                        Facility Type
                                                </option> -->
                                                <option *ngFor="let city of cities" [value]="city">
                                                        {{city}}
                                                </option>
                                            </select>
                                        </div>
                                        <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="pincode">
                                                PINCODE
                                            </label>
                                            <input type="text" id="pincode" [(ngModel)]="tenantDetails.address.pinCode" formControlName="pincodeFormControl" class="form-control" placeholder="Pincode"
                                                style="width: 350px;height: 40px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                                    Contact Details
                                </p>
                                <hr style="background-color: #D8DFE6;" />
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-1">
                                                PHONE NUMBER 1
                                            </label>
                                            <input type="text" id="phone-number-1" [(ngModel)]="tenantDetails.contactPhoneNumber1" formControlName="phoneNumber1FormControl" class="form-control" placeholder="Phone Number 1"
                                                style="width: 350px;height: 40px;" />
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('required') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number is required.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be in digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at least 10 digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber1FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber1FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at most 10 digits.
                                            </div>
                                            </div>
                                        <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-2">
                                                PHONE NUMBER 2
                                            </label>
                                            <input type="text" id="phone-number-2" [(ngModel)]="tenantDetails.contactPhoneNumber2" formControlName="phoneNumber2FormControl" class="form-control" placeholder="Phone Number 2"
                                                style="width: 350px;height: 40px;" />
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be in digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at least 10 digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber2FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber2FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at most 10 digits.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div style="margin-top: 20px;">
                                        <div style="float: left;margin-right: 100px;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="phone-number-3">
                                                PHONE NUMBER 3
                                            </label>
                                            <input type="text" id="phone-number-3" [(ngModel)]="tenantDetails.contactPhoneNumber3" formControlName="phoneNumber3FormControl" class="form-control" placeholder="Phone Number 3"
                                                style="width: 350px;height: 40px;" />
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('pattern') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be in digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('minlength') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at least 10 digits.
                                            </div>
                                            <div *ngIf="facilityDetailsFormControls.phoneNumber3FormControl.hasError('maxlength') && facilityDetailsFormControls.phoneNumber3FormControl.touched" style="color: red;font-size: 14px;">
                                                    Phone Number must be at most 10 digits.
                                            </div>
                                            </div>
                                        <!-- <div style="float: left;">
                                            <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="address-line-2">
                                                Phone Number 1
                                            </label>
                                            <input type="text" id="address-line-2" [(ngModel)]="tenantDetails.address.addressLine2" formControlName="addressLine2FormControl" class="form-control" placeholder="Address Line 2"
                                                style="width: 350px;height: 40px;" />
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p style="font-size: 14px;color: #7c7575;margin-top: 20px;/*margin-bottom: -6px;*/font-weight: 500;">
                                    Photos
                                </p>
                                <hr style="background-color: #D8DFE6;" />
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="clearfix">
                                        <div *ngFor="let image of images; let i = index">
                                            <div class="img-container" style="text-align: center;">
                                                <img [src]=image style="width:100%; height: 100px;">
                                                <!-- <mat-radio-button [value]="i" [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultImageIndex" (click)="selectDefaultImageIndex(i)" class="example-radio-button" style="margin-top: 20px;"
                                                [color]= "radioButtonColor">
                                                </mat-radio-button> -->
                                                <input required id="image{{i}}" [value]='i' type="radio" [ngModel]="defaultImageIndex" (ngModelChange)="defaultImageIndexChange($event)" [ngModelOptions]="{standalone: true}" />
                                            </div>
                                        </div>
                                        <div *ngFor="let image of previewImages; let i = index">
                                            <div class="img-container" style="text-align: center;">
                                                <img [src]=image.dataUrl style="width:100%; height: 100px;">
                                                <!-- <mat-radio-button [value]="i" [ngModelOptions]="{standalone: true}" [(ngModel)]="defaultImageIndex" (click)="selectDefaultImageIndex(i)" class="example-radio-button" style="margin-top: 20px;"
                                                [color]= "radioButtonColor">
                                                </mat-radio-button> -->
                                                <input required id="image{{i}}" [value]='i + images.length' type="radio" [ngModel]="defaultImageIndex" (ngModelChange)="defaultImageIndexChange($event)" [ngModelOptions]="{standalone: true}" />
                                            </div>
                                        </div>
                                        <div (click)="fileInput.click()" style="float: left; cursor: pointer; margin-left: 5px; text-align: center; border: 2px solid #9b9393; border-style: dashed; margin-top: 5px; width: 150px; height: 100px;">
                                            <input #fileInput accept="image/jpg, image/jpeg, image/png, image/svg" (change)="onFileUploaded($event)" type="file" style="display: none;" >
                                            <div>
                                                <img src="../../assets/img/file-upload-icon.jpg" style="width: 50px; height: 50px;">
                                            </div>
                                            Upload Image
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
