<div class="content-wrapper">
  <div class="container">
    <div class="row" *ngIf="SA">
      <div class="col">
        <div class= "tenantsGrid" style="margin-top: 25px;">
          <ag-grid-angular
            style="width: 988px; height: 200px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
