<div class="container" style=" min-height:600px; background-color: #ffffff; margin-left: 0px; margin-right: 0px; margin-top: 10px; padding-left: 25px; padding-right: 25px; padding-bottom: 50px;">
    <div class="row">
        <div class="col">
            <div style="border-bottom: 1px solid #B8B0B0;">
                <h1 style="font-size: 22px;color: #14274e;width: auto;margin-top: 30px;">
                    All Venues
                </h1>
                <button class="btn btn-primary" type="button" (click)="clickAddNewVenue()" [disabled]="showAddNewVenueForm" style="float: right;background-color: #ffffff;font-size: 14px;color: #14274e;margin-top: -45px;border-color: #14274E;">
                    Add New
                </button>
            </div>
        </div>
    </div>

    <div *ngFor="let venue of venues" style="margin-top: 20px">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header style="background-color: #F7F8FA; border: 1px solid #E3E3E3;">
                <mat-panel-title>
                  {{venue.venueName.toUpperCase()}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" style="margin-top: 20px;">
                  <div class="col">
                    <span style="color: #333333; font-size: 16px;">Details</span>

                    <button mat-icon-button [matMenuTriggerFor]="menu" style="float: right; border: none; background-color: transparent;">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="clickEditVenue(venue)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button mat-menu-item (click)="clickDeleteVenue(venue)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </div>
              </div>

              <div class="row">
                  <!-- <div class="col">
                    <div id="container">
                      <div>
                        <div>
                          <h6 style="font-size: 11px; color: #8C95A5;">SUB - CATEGORY</h6>
                        </div>
                        <div>
                          {{"SAI"}}
                        </div>
                      </div>
                      <div>
                        <div>
                          <h6 style="font-size: 11px; color: #8C95A5;">PRODUCTS</h6>
                        </div>
                        <div>

                        </div>
                      </div>
                      <div>
                        <div>
                          <h6 style="font-size: 11px; color: #8C95A5;">STATUS</h6>
                        </div>
                        <div>

                        </div>
                      </div>
                      <div>
                        <div>
                          <h6 style="font-size: 11px; color: #8C95A5;">REPEAT BOOKING</h6>
                        </div>
                        <div>

                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div class="column">
                    <h6 style="font-size: 11px; color: #8C95A5;">SUB - CATEGORY</h6>
                    <p>{{venue.venueType}}</p>
                  </div> -->

                  <div class="column">
                    <h6 style="font-size: 11px; color: #8C95A5;">SERVICES</h6>
                    <p>
                      <mat-chip-list aria-label="Services">
                        <mat-chip style="border-radius: 4px; background-color: #DDE4E9; font: normal normal normal 12px/17px OpenSans-R; color: #333333; margin:5px;"
                            *ngFor="let productId of venue.productIds"
                            [removable]="removable"
                        >
                            {{getProductNameForProductId(productId)}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    </p>
                  </div>

                  <div class="column">
                    <h6 style="font-size: 11px; color: #8C95A5;">STATUS</h6>
                    <p>{{venue.status}}</p>
                  </div>

                  <div *ngIf="false" class="column">
                    <h6 style="font-size: 11px; color: #8C95A5;">REPEAT BOOKING</h6>
                    <p>{{getRepeatBookingText(venue)}}</p>
                  </div>
              </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div *ngIf="showAddNewVenueForm">
      <div class="row">
        <div class="col">
          <form [formGroup]="venueFormGroup">
            <div class="form-group">
              <div>
                <p class="admin-string-primary" style="margin-top:20px;">
                    NEW VENUE
                    <button class="btn btn-primary" (click)="onCreateVenueFormSaveClicked()" type="button" [disabled]="venueFormGroup.invalid" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
                        Save
                    </button>
                    <button class="btn btn-primary" (click)="onCreateVenueFormCancelClicked()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
                        Cancel
                    </button>
                </p>
                <hr style="background-color: #D8DFE6;" />
              </div>

              <div class="row">
                <div class="col">
                  <div>
                    <div style="float: left;margin-right: 100px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="venue-name">
                          VENUE NAME
                      </label>
                      <input type="text" id="venue-name" class="form-control" formControlName="venueNameFormControl" placeholder="Venue Name"
                          style="width: 350px;height: 40px;" />
                      <div *ngIf="venueFormControls.venueNameFormControl.hasError('required') && venueFormControls.venueNameFormControl.touched" style="color: red;font-size: 14px;">
                              Venue Name is required.
                      </div>
                    </div>

                    <!-- <div style="float: left;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="venue-type">
                        SUB - CATEGORY
                      </label>
                      <input type="text" id="venue-type" class="form-control" formControlName="venueTypeFormControl" placeholder="Sub - Category"
                        style="width: 350px;height: 40px;" />
                      <div *ngIf="venueFormControls.venueTypeFormControl.hasError('required') && venueFormControls.venueTypeFormControl.touched" style="color: red;font-size: 14px;">
                            Sub Category is required.
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div style="margin-top: 20px;">
                    <div *ngIf="false" style="float: left;margin-right: 355px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;">
                        REPEAT BOOKING
                      </label>
                      <div class="form-group">
                        <span>
                          <input type="radio" id="repeat-booking-yes" [(ngModel)]="repeatBookingAllowed" [ngModelOptions]="{standalone: true}" [value]="true" name="repeat-booking-option"/>
                          <label for="repeat-booking-yes" style="font-size: 13px; margin-left: 5px;">Yes</label>
                        </span>
                        <span style="margin-left: 15px;">
                          <input type="radio" id="repeat-booking-no" [(ngModel)]="repeatBookingAllowed" [ngModelOptions]="{standalone: true}" [value]="false" name="repeat-booking-option"/>
                          <label for="repeat-booking-no" style="font-size: 13px; margin-left: 5px;">No</label>
                        </span>
                      </div>

                    </div>

                    <!-- <div style="float: left;">
                      <mat-form-field class="example-full-width">
                        <mat-label>PRODUCTS</mat-label>
                        <mat-select formControlName="productForVenuesFormControl" multiple>
                          <mat-select-trigger>
                            {{venueFormControls.productForVenuesFormControl.value ? venueFormControls.productForVenuesFormControl.value[0] : ''}}
                            <span *ngIf="venueFormControls.productForVenuesFormControl.value?.length > 1" class="example-additional-selection">
                              (+{{productsFormControls.productForVenuesFormControl.value.length - 1}} {{venueFormControls.productForVenuesFormControl.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                          </mat-select-trigger>
                          <mat-option *ngFor="let product of productList" [value]="product.productName">{{product.productName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                    <div style="float: left; width: 350px">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;">
                        SERVICES
                      </label>
                      <!-- <ng-select [multiple]="true" placeholder="Select Products" [(ngModel)]="selectedCar" [ngModelOptions]="{standalone: true}">
                        <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                      </ng-select> -->

                      <ng-multiselect-dropdown
                        [placeholder]="'Select Products'"
                        [settings]="dropdownSettings"
                        [data]="productList"
                        [(ngModel)]="selectedItems"
                        [ngModelOptions]="{standalone: true}"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                      >
                      </ng-multiselect-dropdown>

                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col">
                  <div style="margin-top: 20px;">
                    <div style="float: left; margin-right: 365px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="product-status">
                        STATUS
                      </label>

                      <div style="display: flex;">
                        <mat-slide-toggle
                        [(ngModel)]="isChecked"
                        [ngModelOptions]="{standalone: true}"
                        class="example-margin"
                        [color]="color"
                        [checked]="checked"
                        [disabled]="disabled">
                        {{getVenueStatusValue()}}
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div style="float: left; width: 500px;">
                    <mat-chip-list aria-label="Service Selection">
                      <mat-chip  style="border-radius: 4px; background-color: #DDE4E9; font: normal normal normal 12px/17px OpenSans-R; color: #333333;"

                        *ngFor="let product of selectedItems" [removable]="true" (removed)="removeSelectedProduct(product)">
                        {{product.item_text}}
                        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>

              <!-- <div class="row">
                <div class="col">
                  <div style="margin-top: 20px;">
                    <div style="float: left;margin-right: 350px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;">
                        STATUS
                      </label>
                      <div class="form-group">
                        <span>
                          <input type="radio" id="status-active" [(ngModel)]="venueStatus" [ngModelOptions]="{standalone: true}" [value]="Active" name="status-option"/>
                          <label for="status-active" style="font-size: 13px; margin-left: 5px;">Active</label>
                        </span>
                        <span style="margin-left: 15px;">
                          <input type="radio" id="status-inactive" [(ngModel)]="venueStatus" [ngModelOptions]="{standalone: true}" [value]="Inactive" name="status-option"/>
                          <label for="status-inactive" style="font-size: 13px; margin-left: 5px;">Inactive</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

            </div>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="showUpdateVenueForm">
      <div class="row">
        <div class="col">
          <form [formGroup]="updateVenueFormGroup">
            <div class="form-group">
              <div>
                <p class="admin-string-primary" style="margin-top:20px;">
                    UPDATE VENUE
                    <button class="btn btn-primary" (click)="onUpdateVenueFormSaveClicked()" type="button" [disabled]="updateVenueFormGroup.invalid" style="background-color: #ffffff;color: #7c7575;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 0px;border-color: #14274E;">
                        Save
                    </button>
                    <button class="btn btn-primary" (click)="onUpdateVenueFormCancelClicked()" type="button" style="background-color: #ffffff;color: #14274e;width: 66px;height: 30px;padding: 0px;font-size: 14px;float: right;margin-right: 30px;border: none;border-color: #14274E;">
                        Cancel
                    </button>
                </p>
                <hr style="background-color: #D8DFE6;" />
              </div>

              <div class="row">
                <div class="col">
                  <div>
                    <div style="float: left;margin-right: 100px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-venue-name">
                          VENUE NAME
                      </label>
                      <input type="text" id="update-venue-name" class="form-control" formControlName="updateVenueNameFormControl" placeholder="Venue Name"
                          style="width: 350px;height: 40px;" />
                      <div *ngIf="updateVenueFormControls.updateVenueNameFormControl.hasError('required') && updateVenueFormControls.updateVenueNameFormControl.touched" style="color: red;font-size: 14px;">
                              Venue Name is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div style="margin-top: 20px;">
                    <div *ngIf="false" style="float: left;margin-right: 355px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;">
                        REPEAT BOOKING
                      </label>
                      <div class="form-group">
                        <span>
                          <input type="radio" id="repeat-booking-yes" [(ngModel)]="repeatBookingAllowed" [ngModelOptions]="{standalone: true}" [value]="true" name="update-repeat-booking-option"/>
                          <label for="update-repeat-booking-yes" style="font-size: 13px; margin-left: 5px;">Yes</label>
                        </span>
                        <span style="margin-left: 15px;">
                          <input type="radio" id="repeat-booking-no" [(ngModel)]="repeatBookingAllowed" [ngModelOptions]="{standalone: true}" [value]="false" name="update-repeat-booking-option"/>
                          <label for="update-repeat-booking-no" style="font-size: 13px; margin-left: 5px;">No</label>
                        </span>
                      </div>

                    </div>
                    <div style="float: left; width: 350px">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;">
                        SERVICES
                      </label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Selected Products'"
                        [settings]="dropdownSettings"
                        [data]="productList"
                        [(ngModel)]="updateProductSelectedItems"
                        [ngModelOptions]="{standalone: true}"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                      >
                      </ng-multiselect-dropdown>

                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col">
                    <div style="float: left; margin-right: 365px;">
                      <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="update-product-status">
                        STATUS
                      </label>

                      <div style="display: flex;">
                        <mat-slide-toggle
                        [(ngModel)]="isChecked"
                        [ngModelOptions]="{standalone: true}"
                        class="example-margin"
                        [color]="color"
                        [checked]="checked"
                        [disabled]="disabled">
                        {{getVenueStatusValue()}}
                        </mat-slide-toggle>
                      </div>
                    </div>

                  <div style="float: left; width: 500px;">
                    <mat-chip-list aria-label="Service Selection">
                      <mat-chip  style="border-radius: 4px; background-color: #DDE4E9; font: normal normal normal 12px/17px OpenSans-R; color: #333333;"

                        *ngFor="let product of updateProductSelectedItems" [removable]="true" (removed)="removeSelectedProductUpdateForm(product)">
                        {{product.item_text}}
                        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
