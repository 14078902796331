import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, Pipe, PipeTransform, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import {ThemePalette} from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@Pipe({
  name: 'fill'
})
export class FillPipe implements PipeTransform {
  transform(value) {
    return (new Array(value)).fill(1);
  }
}

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [DatePipe]
})
export class ProductComponent implements OnInit, AfterViewInit {

  showAddNewProductForm = false;
  showUpdateProductForm = false;
  // showAddNewRatesButton = true;
  productFormGroup: FormGroup;
  updateproductFormGroup: FormGroup;
  clickedProductDetails;
  sessionIdsToDelete: Array<number> = [];
  isCapacityPerVenueRequired = false;
  // rates = [];
  removable = true;
  showMultipleBookingsOption = false;
  showSingleBookingsOption = false;
  selectedStartTimeValue;
  selectedStartTimeValueSingleBooking: Array<string> = [];
  selectedStartTimeValueMultiBooking: Array<string> = [];
  advancePricingForSingleBooking: Array<number[]> = new Array<number[]>();
  advancePricingForMultiBooking: Array<number[]> = new Array<number[]>();
  selectedEndTimeValue;
  selectedEndTimeValueMultiBooking: Array<string> = [];
  selectedEndTimeValueSingleBooking: Array<string> = [];
  timeSlotsForSingleBooking: Array<string[]> = new Array<string[]>();
  timeSlotsForMultiBooking: Array<string[]> = new Array<string[]>();
  zeros = 0;
  thirty = 30;
  startTimeOptions = [];
  endTimeOptions = [];
  dateObj = new Date();
  noOfSessionsToDisplay = 1;
  noOfSessionsToDisplayForMultiBooking = 1;

  productSubTypes = ["Monthly", "Sessions"];
  public selectedProductSubTypeValue;
  selectedFrequencyValue;
  frequencyForMonthlySubType = [];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  chips: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  columnDefs = [
    { headerName: 'NAME', field: 'productName', flex: 1 }, // Use flex for responsive width
    { headerName: 'DURATION', field: 'durationPerSlot', flex: 1 },
    { headerName: 'STATUS', field: 'status', flex: 1 },
    { headerName: 'TYPE', field: 'productType', flex: 1 },
    { headerName: 'ID', field: 'id', width: 100 } // Fixed width for ID

];

rowData = [];
  private gridApi;
  private gridColumnApi;
  private isfreshlyOpened: Map<number, boolean> = new Map<number, boolean>();
  private chipCollection: Map<string, boolean> = new Map<string, boolean>();
  private chipCollectionForSingleBookingOccurenceOptions: Map<string, boolean>[] = [];
  private chipCollectionForMultiBookingOccurenceOptions: Map<string, boolean>[] = [];
  // for status toggle
  color: ThemePalette = 'primary';
  isChecked = true;
  checked = true;
  disabled = false;
  tenantId;

  // Modal Popup
  modalPopupReference;
  advancePricingModalReference;
  timeSlotsToShowInModalPopup;
  advanceModalPopupOpenedIndex;
  advanceModalPopupOpenedIndexSingleBooking;
  @ViewChild('content', {static: false}) content: TemplateRef<any>;
  @ViewChild('advancePricingModal', {static: false}) advancePricingModal: TemplateRef<any>;

  //Product Edit
  // noOfSessionsToDisplayInProductEditAfterAddingNew = 0;
  noOfSessionAddedInProductEditButNotSubmit = 0;
  productEditAddSessionChips: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  private chipCollectionForSingleBookingEditProductAddSession: Map<string, boolean>[] = [];
  selectedStartTimeValueSingleBookingEditProduct: Array<string> = [];
  selectedEndTimeValueSingleBookingEditProduct: Array<string> = [];
  private isfreshlyOpenedEditProductAddSession: Map<number, boolean> = new Map<number, boolean>();
  timeSlotsForSingleBookingEditProductAddSession: Array<string[]> = new Array<string[]>();
  advanceModalPopupOpenedIndexSingleBookingEditProduct;
  timeSlotsToShowInModalPopupEditProduct;
  advancePricingForSingleBookingEditSession: Array<number[]> = new Array<number[]>();
  modalPopupReferenceEditProduct;
  @ViewChild('contentEditProduct', {static: false}) contentEditProduct: TemplateRef<any>;

  radioOptionsSetForMonthlyMultiBooking = ['Start Any Day', 'Selected Dates'];
  selectedOptionForMonthlyMultiBooking = '';

  constructor(private modalService: NgbModal, private datePipe: DatePipe, private authService: AuthService, private dialog: MatDialog) {
    this.tenantId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).tenantId;
   }

  ngOnInit() {

    if( AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
        console.log("Tenant Id products ", AppConstants.SESSION_STORAGE_TENANTID);
        this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
        console.log("Tenant Id ", this.tenantId);
        //console.log("user", this.user);
    }

    this.getAllProductsByTenantId();

    this.productFormGroup = new FormGroup({
      productNameFormControl: new FormControl('', [
        Validators.required
      ]),
      productRateFormControl: new FormControl('', [
        Validators.required
      ]),
      productDurationFormControl: new FormControl('', [
        Validators.required
      ]),
      productDescriptionFormControl: new FormControl('', [
        Validators.required
      ]),
      productCapacityPerVenueFormControl: new FormControl('', [
        Validators.required
      ]),
      startTimeForSingleSessionFormContol: new FormControl('', [

      ]),
      endTimeForSingleSessionFormContol: new FormControl('', [

      ]),
      selectedProductSubTypeValue: new FormControl(''),
      selectedFrequencyValue: new FormControl('')
    });

    // (this.productFormGroup.get("selectedProductSubTypeValue") as FormControl).valueChanges.subscribe(value => {
    //   this.updateValidation(value);
    // });

    this.updateproductFormGroup = new FormGroup({
      updateProductNameFormControl: new FormControl('', [

      ]),
      updateProductDescriptionFormControl: new FormControl('', [

      ]),
      updateProductRateFormControl: new FormControl('', [

      ]),
      updateProductDurationFormControl: new FormControl('', [

      ]),
      updateProductCapacityPerVenueFormControl: new FormControl('', [

      ]),
      startTimeForSingleSessionEditProductFormContol: new FormControl('', [

      ]),
      endTimeForSingleSessionEditProductFormContol: new FormControl('', [

      ]),
      updateProductMultiSessionSessionsFormControl: new FormControl('', [

      ]),
      updateProductMultiSessionSessionsNoFormControl: new FormControl('', [

      ]),
      updateProductMultiSessionMonthlyFormControl: new FormControl('', [

      ]),
      updateProductMultiSessionMonthlyNoFormControl: new FormControl('', [

      ]),
    });

    for (const chip of this.chips) {
      this.chipCollection.set(chip, false);
    }

    this.addTime();
  }

  ngAfterViewInit() {
    // const updateProductRateInputElement = document.getElementById('update-product-rate') as HTMLInputElement;
    // updateProductRateInputElement.addEventListener('input', this.handleInputChange);
  }

  // handleInputChange(event: Event): void {
  //   const inputValue = this.updateproductFormGroup.get('updateProductRateFormControl').value;
  //   console.log('Input value changed:', inputValue);

  // }

  // updateValidation(value: string) {
  //   const fieldTwo = this.productFormGroup.get('selectedFrequencyValue');
  //   const fieldThree = this.productFormGroup.get('productCapacityPerVenueFormControl');
  //   console.log(value);
  //   if (value === this.productSubTypes[1]) {
  //     fieldTwo.setValidators([Validators.required]);
  //     fieldThree.setValidators([Validators.required]);
  //   } else {
  //     fieldTwo.clearValidators();
  //     fieldThree.clearValidators();
  //   }
  //   fieldTwo.updateValueAndValidity();
  //   fieldThree.updateValueAndValidity();
  // }

  // capacityValidation(value: string) {
  //   const fieldTwo = this.productFormGroup.get('productCapacityPerVenueFormControl');
  //   console.log(value);
  //   if (value) {
  //     fieldTwo.setValidators([Validators.required]);
  //   } else {
  //     fieldTwo.clearValidators();
  //   }
  //   fieldTwo.updateValueAndValidity();
  // }

  showAlertForOverridePricing(): void {
    this.advancePricingModalReference = this.modalService.open(this.advancePricingModal, { windowClass: 'advance-pricing-modal', ariaLabelledBy: 'modal-basic-title'});
  }

  onOKClick() {
    // Perform action when OK button is clicked
    this.closeDialog();
    this.proceedWithProductUpdate();
  }

  onCancelClick(): void {
    this.closeDialog();
  }

  closeDialog(): void {
    this.advancePricingModalReference.dismiss();
  }


  displayAdvancePricingPopup(index: number) {
    console.log('In displayAdvancePricingPopup' + index);
    this.advanceModalPopupOpenedIndex = index;
    this.timeSlotsToShowInModalPopup = this.timeSlotsForMultiBooking[index];
    this.advancePricingForMultiBooking[index] = new Array<number>(this.timeSlotsForMultiBooking[index].length - 1).fill(Number(this.productFormGroup.get('productRateFormControl').value));
    this.modalPopupReference = this.modalService.open(this.content, { windowClass: 'advance-pricing', ariaLabelledBy: 'modal-basic-title'});
  }

  displayAdvancePricingPopupSingleBooking(index: number) {
    console.log('In displayAdvancePricingPopup' + index);
    this.advanceModalPopupOpenedIndexSingleBooking = index;
    this.timeSlotsToShowInModalPopup = this.timeSlotsForSingleBooking[index];
    if(!this.isfreshlyOpened.has(index)) {
      this.advancePricingForSingleBooking[index] = new Array<number>(this.timeSlotsForSingleBooking[index].length - 1).fill(Number(this.productFormGroup.get('productRateFormControl').value));
      this.isfreshlyOpened.set(index, false);
    } else {
      this.advancePricingForSingleBooking[index] = this.advancePricingForSingleBooking[index];
    }
    this.modalPopupReference = this.modalService.open(this.content, { windowClass: 'advance-pricing', ariaLabelledBy: 'modal-basic-title'});
  }

  displayAdvancePricingPopupSingleBookingEditProductAddSession(index: number) {
    console.log('In displayAdvancePricingPopupSingleBookingEditProductAddSession' + index);
    this.advanceModalPopupOpenedIndexSingleBookingEditProduct = index;
    this.timeSlotsToShowInModalPopupEditProduct = this.timeSlotsForSingleBookingEditProductAddSession[index];
    console.log(this.timeSlotsToShowInModalPopupEditProduct);
    if(!this.isfreshlyOpenedEditProductAddSession.has(index)) {
      console.log(this.timeSlotsToShowInModalPopupEditProduct);
      this.advancePricingForSingleBookingEditSession[index] = new Array<number>(this.timeSlotsForSingleBookingEditProductAddSession[index].length - 1).fill(Number(this.updateproductFormGroup.get('updateProductRateFormControl').value));
      console.log(this.advancePricingForSingleBookingEditSession);
      this.isfreshlyOpenedEditProductAddSession.set(index, false);
    } else {
      this.advancePricingForSingleBookingEditSession[index] = this.advancePricingForSingleBookingEditSession[index];
    }
    this.modalPopupReferenceEditProduct = this.modalService.open(this.contentEditProduct, { windowClass: 'advance-pricing-edit-product', ariaLabelledBy: 'modal-basic-title'});
  }

  getAllProductsByTenantId() {

    console.log("Tenant Id Product",  AppConstants.SESSION_STORAGE_TENANTID);
    if(AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
      this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
    }

    console.log("product tennat id",  this.tenantId);
    this.authService.getAllProductsByTenantId(this.tenantId).subscribe(
      (response: any[]) => {
        this.rowData = response;
      },
      (error) => {

      }
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  onRowClicked(event: any) {
    this.clickedProductDetails = event.data;
    this.updateproductFormGroup.get('updateProductNameFormControl').setValue(this.clickedProductDetails.productName);
    this.updateproductFormGroup.get('updateProductDescriptionFormControl').setValue(this.clickedProductDetails.description);
    this.updateproductFormGroup.get('updateProductRateFormControl').setValue(this.clickedProductDetails.price);
    this.updateproductFormGroup.get('updateProductDurationFormControl').setValue(this.clickedProductDetails.durationPerSlot);
    this.updateproductFormGroup.get('updateProductCapacityPerVenueFormControl').setValue(this.clickedProductDetails.capacityPerVenue);
    this.updateproductFormGroup.get('updateProductMultiSessionSessionsFormControl').setValue(this.clickedProductDetails.sessionType);
    this.updateproductFormGroup.get('updateProductMultiSessionSessionsNoFormControl').setValue(this.clickedProductDetails.noOfSessions);
    this.updateproductFormGroup.get('updateProductMultiSessionMonthlyFormControl').setValue(this.clickedProductDetails.sessionType);
    this.updateproductFormGroup.get('updateProductMultiSessionMonthlyNoFormControl').setValue(this.clickedProductDetails.noOfSessions);
    if(this.clickedProductDetails.status == AppConstants.STATUS_ACTIVE) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
    console.log("Clicked Row: ", event.data);
    this.showAddNewProductForm = false;
    this.showUpdateProductForm = true;
  }

  isClickedProductSingleSession() {
    return this.clickedProductDetails.productType == "Single Session" ? true : false;
  }

  isClickedProductMultiSession() {
    return this.clickedProductDetails.productType == "Multi Session" ? true : false;
  }

  isClickedMultiSessionSessions() {
    return this.clickedProductDetails.productType === "Multi Session" && this.clickedProductDetails.sessionType === "Sessions" ? true : false;
  }

  isClickedMultiSessionMonthly() {
    return this.clickedProductDetails.productType === "Multi Session" && this.clickedProductDetails.sessionType === "Monthly" ? true : false;
  }

  isSessionSelectedOnGivenDay(chip: string, index: number) {
    return this.clickedProductDetails.sessions[index][chip.toLowerCase()];
  }

  clickedDeleteSessionEditProduct(session) {
    const index = this.clickedProductDetails.sessions.findIndex((value) => value.id === session.id);
    this.clickedProductDetails.sessions.splice(index, 1);
    this.sessionIdsToDelete.push(Number(session.id));
    console.log(this.sessionIdsToDelete);
  }

  onUpdateProductFormSaveClicked() {
    const price = this.updateproductFormGroup.get('updateProductRateFormControl').value;
    if(price !== this.clickedProductDetails.price) {
      this.showAlertForOverridePricing();
    } else {
      this.proceedWithProductUpdate();
    }
  }

  addSessionForProductEdit() {
    console.log("addSessionForProductEdit");
    this.noOfSessionAddedInProductEditButNotSubmit = this.noOfSessionAddedInProductEditButNotSubmit + 1;
    // this.noOfSessionsToDisplayInProductEditAfterAddingNew = this.noOfSessionsToDisplayInProductEditAfterAddingNew + 1;
  }

  proceedWithProductUpdate() {
    const productName = this.updateproductFormGroup.get('updateProductNameFormControl').value;
    const description = this.updateproductFormGroup.get('updateProductDescriptionFormControl').value;
    const price = this.updateproductFormGroup.get('updateProductRateFormControl').value;
    const durationPerSlot = this.updateproductFormGroup.get('updateProductDurationFormControl').value;
    const capacityPerVenue = this.updateproductFormGroup.get('updateProductCapacityPerVenueFormControl').value;
    const noOfSessions = this.updateproductFormGroup.get('updateProductMultiSessionSessionsNoFormControl').value;
    const productStatus = this.getProductStatusValue();
    const updateProductRequest = {
      productName: productName,
      price: Number(price),
      description: description,
      durationPerSlot: durationPerSlot,
      capacityPerVenue: capacityPerVenue,
      status: productStatus,
      noOfSessions: noOfSessions
    }
    console.log(updateProductRequest);
    this.authService.updateProduct(this.clickedProductDetails.id, updateProductRequest).subscribe(
      (response: any) => {
        console.log('Product details updated successfully', response);
        for(let i = 0; i < this.sessionIdsToDelete.length; i++) {
          this.authService.deleteSessionById(this.sessionIdsToDelete[i]).subscribe(
            (response: any) => {
              console.log('Successfully deleted sessionId ', this.sessionIdsToDelete[i]);
              this.getAllProductsByTenantId();
              this.showUpdateProductForm = false;
            },
            (error) => {
              console.log('Error while deleting sessionId ', this.sessionIdsToDelete[i]);
            }
          )
        }
        console.log('No session to delete');
        if(this.noOfSessionAddedInProductEditButNotSubmit > 0) {
          const getSelectedSingleBookingOptionForSessionEditProduct = this.getSelectedSingleBookingEditProductAddSession();
          getSelectedSingleBookingOptionForSessionEditProduct.forEach((map, index) => {
            console.log(`Map ${index + 1}:`);
            map.forEach((value, key) => {
            console.log(`${key}: ${value}`);
          });
        });
          console.log("timeSlotsForSingleBookingEditProductAddSession", this.timeSlotsForSingleBookingEditProductAddSession);
          if(this.timeSlotsForSingleBookingEditProductAddSession.length == 0)
          {
            for(let counter = 0; counter < getSelectedSingleBookingOptionForSessionEditProduct.length; counter++) {
              this.deriveTimeSlotsSingleBookingEditProductAddSession(counter);
              this.advancePricingForSingleBookingEditSession[counter] = new Array<number>(this.timeSlotsForSingleBookingEditProductAddSession[counter].length - 1).fill(response.price);
            }
          }

          for(let counter = 0; counter < getSelectedSingleBookingOptionForSessionEditProduct.length; counter++) {
            const timeSlotsArray = this.timeSlotsForSingleBookingEditProductAddSession[counter];
            console.log("timeSlotsArray: ", timeSlotsArray);
            const startTime = timeSlotsArray[0];
            const endTime = timeSlotsArray[timeSlotsArray.length - 1];
            const monday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[0]);
            const tuesday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[1]);
            const wednesday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[2]);
            const thursday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[3]);
            const friday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[4]);
            const saturday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[5]);
            const sunday = getSelectedSingleBookingOptionForSessionEditProduct[counter].has(this.chips[6]);

            const sessionRequest = {
              tenantId: this.tenantId,
              productId: response.id,
              startTime: startTime,
              endTime: endTime,
              monday: monday,
              tuesday: tuesday,
              wednesday: wednesday,
              thursday: thursday,
              friday: friday,
              saturday: saturday,
              sunday: sunday
            }

            this.authService.createSession(sessionRequest).subscribe(
              (createSessionResponse: any) => {
                console.log('Session created successfully.');
                console.log(createSessionResponse);
                response.sessions = [createSessionResponse];
                console.log('timeSlotsArray' + timeSlotsArray);
                let timeslotWiseProductRequestArray: Array<any> = [];
                for(let i = 0; i < timeSlotsArray.length - 1; i++) {

                  const timeslotWiseProductRequest = {
                    tenantId: this.tenantId,
                    startTime: this.convert12HourTo24HourFormat(timeSlotsArray[i]),
                    endTime: this.convert12HourTo24HourFormat(timeSlotsArray[i + 1]),
                    price: this.advancePricingForSingleBookingEditSession[counter][i],
                    productId: response.id,
                    sessionId: createSessionResponse.id
                  }
                  timeslotWiseProductRequestArray.push(timeslotWiseProductRequest);
                }

                this.authService.createTimeSlotWiseProducts(timeslotWiseProductRequestArray).subscribe(
                  (createTimeSlotWiseProductsByProductIdResponse: any) => {
                    console.log('Successfully created timeslot wise products for single booking');
                    console.log(createTimeSlotWiseProductsByProductIdResponse);
                    this.getAllProductsByTenantId();
                    this.showUpdateProductForm = false;
                    this.resetUpdateProductForm();
                    this.noOfSessionAddedInProductEditButNotSubmit = 0;
                    window.location.reload();
                  },
                  (error: HttpErrorResponse) => {
                    console.log(error);
                  }
                );
              },
              (error: HttpErrorResponse) => {
                console.log(error.error.errors[0].message);
                alert(error.error.errors[0].message);
              }
            );
          }
        } else {
          this.getAllProductsByTenantId();
          this.showUpdateProductForm = false;
        }
      },
      (error) => {
        console.log('Error while updating product details', error);
        }
      );
  }

  onUpdateProductFormCancelClicked() {
    this.showUpdateProductForm = false;
    this.resetUpdateProductForm();
    this.clearChipSingleBookingEditProductAddSession();
    this.selectedStartTimeValueSingleBookingEditProduct = [];
    this.selectedEndTimeValueSingleBookingEditProduct = [];
    // for(let index=0; index < this.noOfSessionAddedInProductEditButNotSubmit; index++) {
    //   this.clickedDeleteActionsSingleBookingEditProduct(this.noOfSessionsToDisplayInProductEditAfterAddingNew - index);
    // }
    // this.noOfSessionsToDisplayInProductEditAfterAddingNew = this.noOfSessionsToDisplayInProductEditAfterAddingNew - this.noOfSessionAddedInProductEditButNotSubmit;
    this.noOfSessionAddedInProductEditButNotSubmit = 0;
    this.sessionIdsToDelete = [];
    this.getAllProductsByTenantId();
  }

  resetUpdateProductForm() {
    this.updateproductFormGroup.reset();
  }

  resetCreateProductForm() {
    this.productFormGroup.reset();
  }

  clickAddNewProduct() {
    this.showUpdateProductForm = false;
    this.showAddNewProductForm = true;
  }

  addTime() {
    for(let hour=0; hour <=23; hour++) {
      this.startTimeOptions.push(this.datePipe.transform(this.dateObj.setHours(hour, this.zeros), 'h:mm a'));
      this.startTimeOptions.push(this.datePipe.transform(this.dateObj.setHours(hour, this.thirty), 'h:mm a'));

      this.endTimeOptions.push(this.datePipe.transform(this.dateObj.setHours(hour, this.zeros), 'h:mm a'));
      this.endTimeOptions.push(this.datePipe.transform(this.dateObj.setHours(hour, this.thirty), 'h:mm a'));
    }
  }

  // clickedAddNewRatesButton() {
  //   this.showAddNewRatesButton = false;
  // }

  // createNewRate() {
  //   this.rates.push(this.rateFormGroup.get('rateFormControl').value);
  // }

  // deleteRate(rate) {
  //   this.rates = this.rates.filter(rate => rate !== rate);
  // }

  productTypeChipsClickedSingleBooking(){
    document.getElementById("multi-session").classList.remove("selected-chip");
    document.getElementById("single-session").classList.add("selected-chip");
    this.showMultipleBookingsOption = false;
    this.showSingleBookingsOption = true;
    this.isCapacityPerVenueRequired = true;
    console.log('productTypeChipsClicked1()');
  }

  getCapacityPerVenueRequired() {
    const productCapacityPerVenueFormControlValue: string = this.productFormGroup.get('productCapacityPerVenueFormControl').value;
    return this.isCapacityPerVenueRequired && (productCapacityPerVenueFormControlValue == null || productCapacityPerVenueFormControlValue == '');
  }

  productTypeChipsClicked2(){
    document.getElementById("single-session").classList.remove("selected-chip");
    document.getElementById("multi-session").classList.add("selected-chip");
    this.showSingleBookingsOption = false;
    this.showMultipleBookingsOption = true;
    this.isCapacityPerVenueRequired = false;
    console.log('productTypeChipsClicked2()');
  }

  selectValueChangeForProductSubType() {
    console.log('selectValueChangeForProductSubType()');
  }

  isSelected(chip: any) {
    return this.chipCollection.get(chip);
  }

  isSelectedMultiBooking(chip: any, index: number) {
    //console.log("test log");
    //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index]);
    if(this.chipCollectionForMultiBookingOccurenceOptions[index] && this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip)) {
      return true;
    }
  }

  isSelectedSingleBooking(chip: any, index: number) {
    //console.log("test log");
    //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index]);
    if(this.chipCollectionForSingleBookingOccurenceOptions[index] && this.chipCollectionForSingleBookingOccurenceOptions[index].get(chip)) {
      return true;
    }
  }

  isSelectedSingleBookingForEditProductAddSession(chip: any, index: number) {
    if(this.chipCollectionForSingleBookingEditProductAddSession[index] && this.chipCollectionForSingleBookingEditProductAddSession[index].get(chip)) {
      return true;
    }
  }

  onClick($event: Event, chip: string) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectChip(chip);

    const selected = this.getSelected();
    // console.log(selected);
  }

  onClickMultiBookingChips($event: Event, chip: string, index: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectChipMultiBooking(chip, index);

    const selected = this.getSelectedMultiBookingOption();
    //console.log(selected);
  }

  onClickSingleBookingChips($event: Event, chip: string, index: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectChipSingleBooking(chip, index);

    const selected = this.getSelectedSingleBookingOption();
    //console.log(selected);
  }

  onClickSingleBookingChipsEditProductAddSession($event: Event, chip: string, index: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectChipSingleBookingEditProductAddSession(chip, index);

    const selected = this.getSelectedSingleBookingEditProductAddSession();
    //console.log(selected);
  }

  selectChip(chip: string) {
    if (this.chipCollection.get(chip)) {
      this.chipCollection.set(chip, false);
    } else {
      this.chipCollection.set(chip, true);
    }
  }

  selectChipMultiBooking(chip: string, index: number) {
    //console.log(chip);
    //console.log(index);
    if (this.chipCollectionForMultiBookingOccurenceOptions[index] && this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForMultiBookingOccurenceOptions[index];
      nestedMapValue.set(chip, false);
      this.chipCollectionForMultiBookingOccurenceOptions[index] = nestedMapValue;
    } else if (this.chipCollectionForMultiBookingOccurenceOptions[index] && !this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForMultiBookingOccurenceOptions[index];
      nestedMapValue.set(chip, true);
      this.chipCollectionForMultiBookingOccurenceOptions[index] = nestedMapValue;
    } else {
      //console.log('In else part');
      this.chipCollectionForMultiBookingOccurenceOptions[index] = new Map();
      this.chipCollectionForMultiBookingOccurenceOptions[index].set(chip, true);
      // this.chipCollectionForMultiBookingOccurenceOptions.splice(index, 0, new Map().set(chip, true));
    }
  }

  selectChipSingleBooking(chip: string, index: number) {
    //console.log(chip);
    //console.log(index);
    if (this.chipCollectionForSingleBookingOccurenceOptions[index] && this.chipCollectionForSingleBookingOccurenceOptions[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForSingleBookingOccurenceOptions[index];
      nestedMapValue.set(chip, false);
      this.chipCollectionForSingleBookingOccurenceOptions[index] = nestedMapValue;
    } else if (this.chipCollectionForSingleBookingOccurenceOptions[index] && !this.chipCollectionForSingleBookingOccurenceOptions[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForSingleBookingOccurenceOptions[index];
      nestedMapValue.set(chip, true);
      this.chipCollectionForSingleBookingOccurenceOptions[index] = nestedMapValue;
    } else {
      //console.log('In else part');
      this.chipCollectionForSingleBookingOccurenceOptions[index] = new Map();
      this.chipCollectionForSingleBookingOccurenceOptions[index].set(chip, true);
      // this.chipCollectionForMultiBookingOccurenceOptions.splice(index, 0, new Map().set(chip, true));
    }
  }

  clearChipSingleBookingEditProductAddSession() {
    this.chipCollectionForSingleBookingEditProductAddSession = [];
  }

  selectChipSingleBookingEditProductAddSession(chip: string, index: number) {
    //console.log(chip);
    //console.log(index);
    if (this.chipCollectionForSingleBookingEditProductAddSession[index] && this.chipCollectionForSingleBookingEditProductAddSession[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForSingleBookingEditProductAddSession[index];
      nestedMapValue.set(chip, false);
      this.chipCollectionForSingleBookingEditProductAddSession[index] = nestedMapValue;
    } else if (this.chipCollectionForSingleBookingEditProductAddSession[index] && !this.chipCollectionForSingleBookingEditProductAddSession[index].get(chip)) {
       //console.log(this.chipCollectionForMultiBookingOccurenceOptions[index].get(chip));
      const nestedMapValue = this.chipCollectionForSingleBookingEditProductAddSession[index];
      nestedMapValue.set(chip, true);
      this.chipCollectionForSingleBookingEditProductAddSession[index] = nestedMapValue;
    } else {
      //console.log('In else part');
      this.chipCollectionForSingleBookingEditProductAddSession[index] = new Map();
      this.chipCollectionForSingleBookingEditProductAddSession[index].set(chip, true);
      // this.chipCollectionForMultiBookingOccurenceOptions.splice(index, 0, new Map().set(chip, true));
    }
  }

  getSelected() {
    const selected = <string[]>[];

    Array.from(this.chipCollection.entries(), (v: [ string, boolean ]) => {
      if (v[ '1' ]) {
        selected.push(v[ '0' ]);
      }
    });

    return selected;
  }

  getSelectedMultiBookingOption() {
    return this.chipCollectionForMultiBookingOccurenceOptions;
  }

  getSelectedSingleBookingOption() {
    return this.chipCollectionForSingleBookingOccurenceOptions;
  }

  getSelectedSingleBookingEditProductAddSession() {
    return this.chipCollectionForSingleBookingEditProductAddSession;
  }

  clickedAdvancedPricing(index: number) {
    this.deriveTimeSlots(index);
    this.displayAdvancePricingPopup(index);
  }

  clickedAdvancedPricingSingleBooking(index: number) {
    console.log(this.isfreshlyOpened.get(index));
    this.deriveTimeSlotsSingleBooking(index);
    this.displayAdvancePricingPopupSingleBooking(index);
  }

  clickedAdvancedPricingSingleBookingEditProductAddSession(index: number) {
    console.log(this.isfreshlyOpenedEditProductAddSession.get(index));
    this.deriveTimeSlotsSingleBookingEditProductAddSession(index);
    this.displayAdvancePricingPopupSingleBookingEditProductAddSession(index);
  }

  deriveTimeSlots(index: number)
  {
      // console.log("ClickedAdvancedPricing()");
      // console.log(this.selectedStartTimeValueMultiBooking[index]);
      // console.log(this.selectedEndTimeValueMultiBooking[index]);
      // console.log(this.convert12HourTo24HourFormat(this.selectedStartTimeValueMultiBooking[index]));
      // console.log(this.convert12HourTo24HourFormat(this.selectedEndTimeValueMultiBooking[index]));
      // console.log(this.parseTime(this.convert12HourTo24HourFormat(this.selectedStartTimeValueMultiBooking[index])));
      // console.log(this.parseTime(this.convert12HourTo24HourFormat(this.selectedEndTimeValueMultiBooking[index])));
      const startTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedStartTimeValueMultiBooking[index]));
      let endTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedEndTimeValueMultiBooking[index]));
      console.log(startTime);
      console.log(endTime);
      if(endTime == 0) {endTime = 1440}
      let timeSlots: Array<string> = new Array<string>();
      let i;
      const durationInMinutes = this.productFormGroup.get('productDurationFormControl').value;
      for(i = startTime; i <= endTime; i = i + durationInMinutes) {
        if(i == 1440) {
          timeSlots.push("12:00 AM");
        } else {
          timeSlots.push(this.tConvert(this.convertHours(i)));
        }
      }
      console.log(timeSlots);
      this.timeSlotsForMultiBooking[index] = timeSlots;

      //this.timeSlotsForMultiBooking = timeSlots;
      console.log(this.timeSlotsForMultiBooking);
  }

  deriveTimeSlotsSingleBooking(index: number)
  {
      const startTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedStartTimeValueSingleBooking[index]));
      let endTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedEndTimeValueSingleBooking[index]));
      console.log(startTime);
      console.log(endTime);
      if(endTime == 0) {endTime = 1440}
      let timeSlots: Array<string> = new Array<string>();
      let i;
      const durationInMinutes = this.productFormGroup.get('productDurationFormControl').value;
      for(i = startTime; i <= endTime; i = i + durationInMinutes) {
        console.log(this.convertHours(i));
        console.log(this.tConvert(this.convertHours(i)));
        if(i == 1440) {
          timeSlots.push("12:00 AM");
        } else {
          timeSlots.push(this.tConvert(this.convertHours(i)));
        }
      }
      console.log(timeSlots);
      this.timeSlotsForSingleBooking[index] = timeSlots;
      console.log(this.timeSlotsForSingleBooking);
  }

  deriveTimeSlotsSingleBookingEditProductAddSession(index: number)
  {
      const startTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedStartTimeValueSingleBookingEditProduct[index]));
      let endTime = this.parseTime(this.convert12HourTo24HourFormat(this.selectedEndTimeValueSingleBookingEditProduct[index]));
      console.log(startTime);
      console.log(endTime);
      if(endTime == 0) {endTime = 1440}
      let timeSlots: Array<string> = new Array<string>();
      let i;
      const durationInMinutes = this.updateproductFormGroup.get('updateProductDurationFormControl').value;
      for(i = startTime; i <= endTime; i = i + durationInMinutes) {
        if(i == 1440) {
          timeSlots.push("12:00 AM");
        } else {
          timeSlots.push(this.tConvert(this.convertHours(i)));
        }
      }
      console.log(timeSlots);
      this.timeSlotsForSingleBookingEditProductAddSession[index] = timeSlots;
      console.log(this.timeSlotsForSingleBookingEditProductAddSession);
  }

  tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    console.log(time);
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  convertHours(minutes: number) {
    const hour = Math.floor(minutes / 60);
    const min = minutes % 60;
    const str = this.addPadding(String(hour), 2) + ':' + this.addPadding(String(min), 2);
    return str;
  }

  addPadding(str: string, max: number) {
    if(str.length < max) {
      return this.addPadding('0' + str, max);
    }  else {
      return str;
    }
  }

  parseTime(time: string) {
    const splitTimeString = time.split(':');
    const parseTimeStringToInt = Number(splitTimeString[0]) * 60 + Number(splitTimeString[1]);
    return parseTimeStringToInt;
  }

  convert12HourTo24HourFormat(time12h: string) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }
    let hoursInNumber: any = hours;
    if (modifier === 'PM') {
      hoursInNumber = parseInt(hours, 10) + 12;
    }

    return `${hoursInNumber}:${minutes}`;
  }

  get productFormControls() {
    return this.productFormGroup.controls;
  }

  get updateProductFormControls() {
    return this.updateproductFormGroup.controls;
  }

  selectValueChangeForStartTime() {

  }

  selectValueChangeForStartTimeSingleBooking(startTime: string, index: number) {
    this.selectedStartTimeValueSingleBooking[index] = startTime;
    console.log(this.selectedStartTimeValueSingleBooking);
  }

  selectValueChangeForStartTimeSingleBookingEditProduct(startTime: string, index: number) {
    this.selectedStartTimeValueSingleBookingEditProduct[index] = startTime;
    console.log(this.selectedStartTimeValueSingleBookingEditProduct);
  }

  selectValueChangeForStartTimeMultiBooking(startTime: string, index: number) {
    this.selectedStartTimeValueMultiBooking[index] = startTime;
    console.log(this.selectedStartTimeValueMultiBooking);
  }

  selectValueChangeForEndTime() {

  }

  selectValueChangeForEndTimeSingleBooking(endTime: string, index: number) {
    this.selectedEndTimeValueSingleBooking[index] = endTime;
    console.log(this.selectedEndTimeValueSingleBooking);
  }

  selectValueChangeForEndTimeSingleBookingEditProduct(endTime: string, index: number) {
    this.selectedEndTimeValueSingleBookingEditProduct[index] = endTime;
    console.log(this.selectedEndTimeValueSingleBookingEditProduct);
  }

  selectValueChangeForEndTimeMultiBooking(endTime: string, index: number) {
    this.selectedEndTimeValueMultiBooking[index] = endTime;
    console.log(this.selectedEndTimeValueMultiBooking);
  }

  clickedDeleteActionsMultiBooking(index: number) {
    this.chipCollectionForMultiBookingOccurenceOptions.splice(index, 1);
    this.selectedStartTimeValueMultiBooking.splice(index, 1);
    this.selectedEndTimeValueMultiBooking.splice(index, 1);
    this.selectedFrequencyValue = this.selectedFrequencyValue - 1;
    console.log("Deleted entries at index: " + index);
  }

  clickedDeleteActionsSingleBooking(index: number) {
    this.chipCollectionForSingleBookingOccurenceOptions.splice(index, 1);
    this.selectedStartTimeValueSingleBooking.splice(index, 1);
    this.selectedEndTimeValueSingleBooking.splice(index, 1);
    this.selectedFrequencyValue = this.selectedFrequencyValue - 1;
    this.noOfSessionsToDisplay = this.noOfSessionsToDisplay - 1;
    console.log("Deleted entries at index: " + index);
  }

  clickedDeleteActionsSingleBookingEditProduct(index: number) {
    this.chipCollectionForSingleBookingEditProductAddSession.splice(index, 1);
    this.selectedStartTimeValueSingleBookingEditProduct.splice(index, 1);
    this.selectedEndTimeValueSingleBookingEditProduct.splice(index, 1);
    // this.selectedFrequencyValue = this.selectedFrequencyValue - 1;
    //this.noOfSessionsToDisplayInProductEditAfterAddingNew = this.noOfSessionsToDisplayInProductEditAfterAddingNew - 1;
    this.noOfSessionAddedInProductEditButNotSubmit = this.noOfSessionAddedInProductEditButNotSubmit - 1;
    console.log("Deleted entries at index: " + index);
  }

  inputValueChangeForSessionsFrequency() {

  }

  // to handle chip selection for occurence in Single Booking type

  // isSelectedOccurenceForSingleBookingType(singleBookingOccurenceOption: any) {
  //   return this.ChipCollectionForSingleBookingOccurenceOptions.get(singleBookingOccurenceOption);
  // }

  // onClickOccurenceForSingleBookingType($event: Event, singleBookingOccurenceOption: string) {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.selectChipForSingleBookingOccurenceOption(singleBookingOccurenceOption);

  //   const selected = this.getSelectedSingleBookingOccurenceOption();
  //   console.log(selected);
  // }

  // selectChipForSingleBookingOccurenceOption(singleBookingOccurenceOption: string) {
  //   if (this.ChipCollectionForSingleBookingOccurenceOptions.get(singleBookingOccurenceOption)) {
  //     this.ChipCollectionForSingleBookingOccurenceOptions.set(singleBookingOccurenceOption, false);
  //   } else {
  //     this.ChipCollectionForSingleBookingOccurenceOptions.set(singleBookingOccurenceOption, true);
  //   }
  // }

  // getSelectedSingleBookingOccurenceOption() {
  //   const selected = <string[]>[];

  //   Array.from(this.ChipCollectionForSingleBookingOccurenceOptions.entries(), (v: [ string, boolean ]) => {
  //     if (v[ '1' ]) {
  //       selected.push(v[ '0' ]);
  //     }
  //   });

  //   return selected;
  // }

  onCreateProductFormSaveClicked() {
    const productName = this.productFormGroup.get('productNameFormControl').value;
    const description = this.productFormGroup.get('productDescriptionFormControl').value;
    const price = this.productFormGroup.get('productRateFormControl').value;
    const durationPerSlot = this.productFormGroup.get('productDurationFormControl').value;
    const capacityPerVenue = this.productFormGroup.get('productCapacityPerVenueFormControl').value;
    const productType = this.showSingleBookingsOption ? AppConstants.SINGLE_SESSION : AppConstants.MULTI_SESSION;
    const productStatus = this.getProductStatusValue();

    if(this.showSingleBookingsOption) {
      const productRequest = {
        productName: productName,
        description: description,
        status: productStatus,
        price: Number(price),
        durationPerSlot: durationPerSlot,
        capacityPerVenue: capacityPerVenue,
        productType: productType,
        tenantId: this.tenantId
      }

      this.authService.createProduct(productRequest).subscribe(
        (response: any) => {
          console.log('Product created succesfully.');
          console.log(response);
          const getSelectedSingleBookingOptionForSession = this.getSelectedSingleBookingOption();
          console.log(getSelectedSingleBookingOptionForSession);
          // let startTime = this.productFormGroup.get('startTimeForSingleSessionFormContol').value;
          // let endTime = this.productFormGroup.get('endTimeForSingleSessionFormContol').value;
          // if(startTime == undefined) {
          //   startTime = this.startTimeOptions[0];
          // }
          // if(endTime == undefined) {
          //   endTime = this.endTimeOptions[0];
          // }
          console.log("timeSlotsForSingleBooking", this.timeSlotsForSingleBooking);
          if(this.timeSlotsForSingleBooking.length == 0)
          {
            for(let counter = 0; counter < getSelectedSingleBookingOptionForSession.length; counter++) {
              this.deriveTimeSlotsSingleBooking(counter);
              this.advancePricingForSingleBooking[counter] = new Array<number>(this.timeSlotsForSingleBooking[counter].length - 1).fill(response.price);
            }
          }

          for(let counter = 0; counter < getSelectedSingleBookingOptionForSession.length; counter++) {
            const timeSlotsArray = this.timeSlotsForSingleBooking[counter];
            console.log("timeSlotsArray: ", timeSlotsArray);
            const startTime = timeSlotsArray[0];
            const endTime = timeSlotsArray[timeSlotsArray.length - 1];
            const monday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[0]);
            const tuesday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[1]);
            const wednesday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[2]);
            const thursday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[3]);
            const friday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[4]);
            const saturday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[5]);
            const sunday = getSelectedSingleBookingOptionForSession[counter].has(this.chips[6]);

            const sessionRequest = {
              tenantId: this.tenantId,
              productId: response.id,
              startTime: startTime,
              endTime: endTime,
              monday: monday,
              tuesday: tuesday,
              wednesday: wednesday,
              thursday: thursday,
              friday: friday,
              saturday: saturday,
              sunday: sunday
            }

            this.authService.createSession(sessionRequest).subscribe(
              (createSessionResponse: any) => {
                console.log('Session created successfully.');
                console.log(createSessionResponse);
                response.sessions = [createSessionResponse];
                this.rowData = [...this.rowData, response];
                console.log('timeSlotsArray' + timeSlotsArray);
                let timeslotWiseProductRequestArray: Array<any> = [];
                for(let i = 0; i < timeSlotsArray.length - 1; i++) {

                  const timeslotWiseProductRequest = {
                    tenantId: this.tenantId,
                    startTime: this.convert12HourTo24HourFormat(timeSlotsArray[i]),
                    endTime: this.convert12HourTo24HourFormat(timeSlotsArray[i + 1]),
                    price: this.advancePricingForSingleBooking[counter][i],
                    productId: response.id,
                    sessionId: createSessionResponse.id
                  }
                  timeslotWiseProductRequestArray.push(timeslotWiseProductRequest);
                }

                this.authService.createTimeSlotWiseProducts(timeslotWiseProductRequestArray).subscribe(
                  (createTimeSlotWiseProductsByProductIdResponse: any) => {
                    console.log('Successfully created timeslot wise products for single booking');
                    console.log(createTimeSlotWiseProductsByProductIdResponse);
                    this.showAddNewProductForm = false;
                    this.resetCreateProductForm();
                    window.location.reload();
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }
            );
          }

          // const monday = getSelectedDaysForSession.includes(this.chips[0]);
          // const tuesday = getSelectedDaysForSession.includes(this.chips[1]);;
          // const wednesday = getSelectedDaysForSession.includes(this.chips[2]);
          // const thursday = getSelectedDaysForSession.includes(this.chips[3]);
          // const friday = getSelectedDaysForSession.includes(this.chips[4]);
          // const saturday = getSelectedDaysForSession.includes(this.chips[5]);
          // const sunday = getSelectedDaysForSession.includes(this.chips[6]);

          // const sessionRequest = {
          //   tenantId: this.tenantId,
          //   productId: response.id,
          //   startTime: startTime,
          //   endTime: endTime,
          //   monday: monday,
          //   tuesday: tuesday,
          //   wednesday: wednesday,
          //   thursday: thursday,
          //   friday: friday,
          //   saturday: saturday,
          //   sunday: sunday
          // }

          // this.authService.createSession(sessionRequest).subscribe(
          //   (createSessionResponse: any) => {
          //     console.log('Session created succesfully.');
          //     console.log(createSessionResponse);

          //     this.authService.createTimeSlotWiseProductsByProductId(response.id).subscribe(
          //       (createTimeSlotWiseProductsByProductIdResponse: any) => {
          //         console.log('Successfully created timeslot wise products');
          //         console.log(createTimeSlotWiseProductsByProductIdResponse);
          //         this.showAddNewProductForm = false;
          //       },
          //       (error) => {

          //       }
          //     )

          //   },
          //   (error) => {

          //   });
        },
        (error) => {
          console.log(error);
        }
      );
    } else if(this.showMultipleBookingsOption && this.selectedProductSubTypeValue === this.productSubTypes[1]) {
      console.log(this.selectedOptionForMonthlyMultiBooking);
      const productRequest = {
        productName: productName,
        description: description,
        status: productStatus,
        price: Number(price),
        durationPerSlot: durationPerSlot,
        capacityPerVenue: capacityPerVenue,
        productType: productType,
        tenantId: this.tenantId,
        sessionType: this.selectedProductSubTypeValue,
        noOfSessions: Number(this.selectedFrequencyValue),
        monthlyMultiBookingOption: this.selectedOptionForMonthlyMultiBooking
      }

      this.authService.createProduct(productRequest).subscribe(
        (response: any) => {
          console.log('Product created successfully.');
          this.rowData = [...this.rowData, response];
          console.log("Response: ", response);
          const getSelectedMultiBookingOptionForSession = this.getSelectedMultiBookingOption();
          console.log("Here: ", getSelectedMultiBookingOptionForSession);
          console.log("timeSlotsForMultiBooking", this.timeSlotsForMultiBooking);
          if(this.timeSlotsForMultiBooking.length == 0)
          {
            for(let counter = 0; counter < getSelectedMultiBookingOptionForSession.length; counter++) {
              this.deriveTimeSlots(counter);
              this.advancePricingForMultiBooking[counter] = new Array<number>(this.timeSlotsForMultiBooking[counter].length - 1).fill(response.price);
            }
          }
          for(let counter = 0; counter < getSelectedMultiBookingOptionForSession.length; counter++) {
            const timeSlotsArray = this.timeSlotsForMultiBooking[counter];
            console.log("timeSlotsArray: ", timeSlotsArray);
            const startTime = timeSlotsArray[0];
            const endTime = timeSlotsArray[timeSlotsArray.length - 1];
            const monday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[0]);
            const tuesday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[1]);
            const wednesday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[2]);
            const thursday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[3]);
            const friday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[4]);
            const saturday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[5]);
            const sunday = getSelectedMultiBookingOptionForSession[counter].has(this.chips[6]);

            const sessionRequest = {
              tenantId: this.tenantId,
              productId: response.id,
              startTime: startTime,
              endTime: endTime,
              monday: monday,
              tuesday: tuesday,
              wednesday: wednesday,
              thursday: thursday,
              friday: friday,
              saturday: saturday,
              sunday: sunday
            }

            this.authService.createSession(sessionRequest).subscribe(
              (createSessionResponse: any) => {
                console.log('Session created successfully.');
                console.log(createSessionResponse);

                console.log('timeSlotsArray' + timeSlotsArray);
                let timeslotWiseProductRequestArray: Array<any> = [];
                for(let i = 0; i < timeSlotsArray.length - 1; i++) {

                  const timeslotWiseProductRequest = {
                    tenantId: this.tenantId,
                    startTime: this.convert12HourTo24HourFormat(timeSlotsArray[i]),
                    endTime: this.convert12HourTo24HourFormat(timeSlotsArray[i + 1]),
                    price: this.advancePricingForMultiBooking[counter][i],
                    productId: response.id,
                    sessionId: createSessionResponse.id
                  }
                  timeslotWiseProductRequestArray.push(timeslotWiseProductRequest);
                }

                this.authService.createTimeSlotWiseProducts(timeslotWiseProductRequestArray).subscribe(
                  (createTimeSlotWiseProductsByProductIdResponse: any) => {
                    console.log('Successfully created timeslot wise products');
                    console.log(createTimeSlotWiseProductsByProductIdResponse);
                    this.showAddNewProductForm = false;
                    this.resetCreateProductForm();
                    window.location.reload();
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }
            );
          }
          if(this.selectedOptionForMonthlyMultiBooking != '') {
            this.showAddNewProductForm = false;
            this.resetCreateProductForm();
            window.location.reload();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log(this.selectedOptionForMonthlyMultiBooking);
      const productRequest = {
        productName: productName,
        description: description,
        status: productStatus,
        price: Number(price),
        durationPerSlot: durationPerSlot,
        capacityPerVenue: capacityPerVenue,
        productType: productType,
        tenantId: this.tenantId,
        sessionType: this.selectedProductSubTypeValue,
        noOfSessions: Number(this.selectedFrequencyValue),
        monthlyMultiBookingOption: this.selectedOptionForMonthlyMultiBooking,
        monthlyMultiBookingSelectedDates: this.selectedOptionForMonthlyMultiBooking === "Selected Dates"? "1, 15" : null
      }

      this.authService.createProduct(productRequest).subscribe(
        (response: any) => {
          console.log('Product created successfully.');
          this.rowData = [...this.rowData, response];
          console.log("Response: ", response);
          const getSelectedMultiBookingOptionForSession = this.getSelectedMultiBookingOption();
          console.log("Here: ", getSelectedMultiBookingOptionForSession);
          console.log("timeSlotsForMultiBooking", this.timeSlotsForMultiBooking);
          if(this.timeSlotsForMultiBooking.length == 0)
          {
            for(let counter = 0; counter < this.noOfSessionsToDisplayForMultiBooking; counter++) {
              this.deriveTimeSlots(counter);
              this.advancePricingForMultiBooking[counter] = new Array<number>(this.timeSlotsForMultiBooking[counter].length - 1).fill(response.price);
            }
          }
          for(let counter = 0; counter < this.noOfSessionsToDisplayForMultiBooking; counter++) {
            const timeSlotsArray = this.timeSlotsForMultiBooking[counter];
            console.log("timeSlotsArray: ", timeSlotsArray);
            const startTime = timeSlotsArray[0];
            const endTime = timeSlotsArray[timeSlotsArray.length - 1];
            const monday = false;
            const tuesday = false;
            const wednesday = false;
            const thursday = false;
            const friday = false;
            const saturday = false;
            const sunday = false;

            const sessionRequest = {
              tenantId: this.tenantId,
              productId: response.id,
              startTime: startTime,
              endTime: endTime,
              monday: monday,
              tuesday: tuesday,
              wednesday: wednesday,
              thursday: thursday,
              friday: friday,
              saturday: saturday,
              sunday: sunday
            }

            this.authService.createSession(sessionRequest).subscribe(
              (createSessionResponse: any) => {
                console.log('Session created successfully.');
                console.log(createSessionResponse);

                // console.log('timeSlotsArray' + timeSlotsArray);
                // let timeslotWiseProductRequestArray: Array<any> = [];
                // for(let i = 0; i < timeSlotsArray.length - 1; i++) {

                //   const timeslotWiseProductRequest = {
                //     tenantId: this.tenantId,
                //     startTime: this.convert12HourTo24HourFormat(timeSlotsArray[i]),
                //     endTime: this.convert12HourTo24HourFormat(timeSlotsArray[i + 1]),
                //     price: this.advancePricingForMultiBooking[counter][i],
                //     productId: response.id,
                //     sessionId: createSessionResponse.id
                //   }
                //   timeslotWiseProductRequestArray.push(timeslotWiseProductRequest);
                // }

                // this.authService.createTimeSlotWiseProducts(timeslotWiseProductRequestArray).subscribe(
                //   (createTimeSlotWiseProductsByProductIdResponse: any) => {
                //     console.log('Successfully created timeslot wise products');
                //     console.log(createTimeSlotWiseProductsByProductIdResponse);
                //     this.showAddNewProductForm = false;
                //     this.resetCreateProductForm();
                //     window.location.reload();
                //   },
                //   (error) => {
                //     console.log(error);
                //   }
                // );
              }
            );
          }
          if(this.selectedOptionForMonthlyMultiBooking != '') {
            this.showAddNewProductForm = false;
            this.resetCreateProductForm();
            window.location.reload();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  onCreateProductFormCancelClicked() {
    this.showAddNewProductForm = false;
  }

  getProductStatusValue() {
    if(this.isChecked) {
      return AppConstants.STATUS_ACTIVE.charAt(0).toUpperCase() + AppConstants.STATUS_ACTIVE.slice(1);
    } else {
      return AppConstants.STATUS_INACTIVE.charAt(0).toUpperCase() + AppConstants.STATUS_INACTIVE.slice(1);
    }
  }

  onAdvancePricingCancelClicked() {
    this.modalPopupReference.dismiss();
  }

  onAdvancePricingCancelClickedSingleBooking() {
    this.modalPopupReference.dismiss();
  }

  onAdvancePricingCancelClickedSingleBookingEditProduct() {
    this.modalPopupReferenceEditProduct.dismiss();
  }

  onAdvancePricingSaveClicked() {
    console.log(this.timeSlotsForMultiBooking[this.advanceModalPopupOpenedIndex]);
    console.log(this.advancePricingForMultiBooking[this.advanceModalPopupOpenedIndex]);
    this.modalPopupReference.dismiss();
  }

  onAdvancePricingSaveClickedSingleBooking() {
    console.log(this.timeSlotsForSingleBookingEditProductAddSession[this.advanceModalPopupOpenedIndexSingleBookingEditProduct]);
    console.log(this.advancePricingForSingleBookingEditSession[this.advanceModalPopupOpenedIndexSingleBookingEditProduct]);
    this.modalPopupReferenceEditProduct.dismiss();
  }

  // getProductRate(index) {
  //   console.log(this.advancePricingForMultiBooking[this.advanceModalPopupOpenedIndex][index]);
  //   return this.advancePricingForMultiBooking[this.advanceModalPopupOpenedIndex][index];
  // }

  getNextIndexForTimeSlotsInModalPopup(currentIndex) {
    const nextIndex = currentIndex + 1;
    return nextIndex;
  }

  addSessionForSingleBooking(noOfSessionsToDisplay) {
    this.noOfSessionsToDisplay = this.noOfSessionsToDisplay + 1;
  }

  addSessionForMultiBooking(noOfSessionsToDisplay) {
    this.noOfSessionsToDisplayForMultiBooking = this.noOfSessionsToDisplayForMultiBooking + 1;
  }
}
