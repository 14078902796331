import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { AppConstants } from '../AppConstants';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN));
        if(accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        return next.handle(request).pipe( tap(() => {},
        (error: HttpErrorResponse) => {
            console.log(error);
            if (error.status !== 401) {
                return;
            }
            if(error.status == 401 && error.error.errors[0].message == 'Invalid Username Or Password.') {
                return;
            }
            alert("Session Expired");
            sessionStorage.clear();
            this.router.navigateByUrl('').then(() => {
            window.location.reload();
            });
        }));
    }
}