import {Subject} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {

  private loginModalData = new Subject<any>();

  constructor() { }

  setLoginModalData(showModal) {
    this.loginModalData.next({
      showModal: showModal
    });
    //console.log("2222");
  }

  getLoginModalData() {
    console.log("returining loginModalData");
    return this.loginModalData;
  }
}
