import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { UIRoutes } from '../UIRoutes';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  //constructor(private router: Router) { }

  constructor(private authService: AuthService, private router: Router) { }
  externalUserContext;
  currentUserContext;
  roleId;
  SA = false; //super admin
  tenant;
  tenantId;

  ngOnInit() {
    console.log('ngOnInit log');

    this.externalUserContext =  sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    console.log('Landing page ', this.currentUserContext);
    console.log('Landing page ext ',this.externalUserContext);

    if(this.externalUserContext) {
      this.roleId = JSON.parse(this.externalUserContext).roleId;
    } else if(this.currentUserContext) {
      this.roleId = JSON.parse(this.currentUserContext).roleId;
    } else {
      this.roleId = 0; // user not logged in
    }


    if(this.currentUserContext && this.roleId < 3) {
      this.tenantId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).tenantId;
    } else if(this.externalUserContext) {
      this.tenantId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)).tenantId;
    } else {
      this.tenantId = 0;
    }
    /* if (window.screen.width < 700) { // 768px portrait
              this.mobile = true;
              console.log("mobile: ", this.mobile);
    }
 */

    AppConstants.SESSION_STORAGE_ROLEID = this.roleId;

    if(this.roleId == 1)
    {
      this.SA = true;
    }
    else
    {
      this.SA = false;
    }

    if (this.roleId == 3)// end user
    {
      //console.log("LP Role: T3 user");
      this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_TRANSACTIONS]);
    }
    if(this.roleId == 1)// super user
    {
      //console.log("LP Role: Super user");
      this.router.navigate([UIRoutes.ROUTING_MODULE_TENANTS_GRID]);
    }
    if(this.roleId == 2)// tenant admin
    {
        //console.log("LP Role: T2 Admin user");
        this.authService.getTenantDetailsBYTenantID(this.tenantId).subscribe(
            (response: any) => {
              this.tenant = response;
              console.log(this.tenant);
            }
        );
    }

    if(this.roleId == 1 || this.roleId == 2)
    {
      if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB))
      {
          if (sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_ADMIN_BOOKINGS) {
             this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_ADMIN_BOOKINGS_PAGE]);
          }
            else if (sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_TRANSACTIONS) {
             this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_TRANSACTIONS]);
          }
            else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_COMPANY_PROFILE) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_COMPANY_PROFILE]);
          } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_PRODUCTS) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_PRODUCTS]);
          } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_VENUES) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_VENUES]);
          } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_THEMES) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_THEMES]);
          } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_POLICY) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_POLICY]);
          } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CLICKED_TAB) == AppConstants.TAB_EXCEPTIONS) {
            this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_EXCEPTIONS]);
          }
      }
      else
      {
        this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_ADMIN_BOOKINGS_PAGE]);
      }
    }


  }

  clickedOnCompanyProfile() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_COMPANY_PROFILE);
  }

  clickedOnProducts() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_PRODUCTS);
  }

  clickedOnVenues() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_VENUES);
  }

  clickedOnThemes() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_THEMES);
  }

  clickedOnPolicy() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_POLICY);
  }

  clickedOnExceptions() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_EXCEPTIONS);
  }
  clickedOnAdminBookings() {
    sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_ADMIN_BOOKINGS);
  }
  clickedOnTransactions() {
      sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_TRANSACTIONS);
  }
}
