<div>
  <div class="content-wrapper">
    <div class="container" style="min-height:700px;">
      <div class="col">
        <h3 class = "descriptionHeader" style="text-align:center;padding-top:20px;">About Us</h3>
      </div>
      <div class="row">
        <!-- TODO height 287px when new content is added before footer and after this division -->

        <div class="col-md-6">

          <!--<h1 class="boldStatements">Online Booking Platform for your <span style = "color: #EC8B5D;">Venue and Services </span></h1>-->
          <p class = "aboutUsParagraph"> BookingDexter is an online booking and scheduling platform for venues and services. The platform provides a user-friendly interface for businesses to manage their bookings, and schedule appointments.
          </p>
          <p class = "aboutUsParagraph">
              BookingDexter aims to streamline the booking process and increase efficiency for businesses. The platform offers various features such as online appointments, notifications,  configurable services, policies, rules, and payments. This can help businesses save time and resources on managing their bookings, allowing them to focus on other aspects of their operations. Overall, BookingDexter can be a useful tool for venue management for the businesses like Sports, Hospitality, Healthcare, or Service industries that rely on appointments or bookings. The website offers businesses the ability to set up their booking and scheduling platform quickly and easily.
          </p>
          <p class = "aboutUsParagraph">
              On the website, businesses can sign up for a free trial and start customizing their booking page, setting up payment options, and managing their bookings.
          </p>
          <!--<button class="btn btn-primary createNewButtonStyle" type="button" (click)="onClickCreateNow()" style = "width: 250px">Create a Free Account</button>-->
        </div>
        <div class="col-md-6 hidden-mobile">
          <div class="row">
            <div class="col">
              <img src="../../assets/img/1.png" style="height: 164px;width: 160px;background-repeat: no-repeat;margin-top: 93px;margin-bottom: 30px;" />
            </div>
          </div>
        </div>

        <!--<div class="col-md-6">
          <button class="btn btn-primary createNewButtonStyle" type="button" (click)="onClickCreateNow()" style = "width: 250px">Create a Free Account</button>
        </div>-->


      </div>
      <div class="row">
        <div class="col">
          <h3 class = "descriptionHeader" style="text-align:center; margin-top: 20px;margin-left: 10px;">Contact Us</h3>
        </div>
      </div>
      <div class="row">
        <img src="../../assets/img/address-location2.svg" style="margin-top: 10px; height: 20px;width: 20px; margin-left: 20px; color: #14274E;"/>
        <h5 class = "descriptionHeader" style="margin-left: 10px;margin-top: 10px;">Address: </h5>
      </div>
      <div class="row">
        <p class = "aboutUsParagraph" style="margin-left: 50px; max-width: 300px">I-704 Samrajya, Shivtirtha Nagar, Paud Road, Kothrud, Pune 411038, Maharashtra, India</p>
      </div>
      </div>
  </div>
</div>
