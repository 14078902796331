import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginSuccessComponent } from './login-success/login-success.component';
import { LoginComponent } from './login/login.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { LinkExpiredComponent } from './link-expired/link-expired.component';
import { AuthGuard } from './guards/auth.guard';
import { BottomContainerComponent } from './bottom-container/bottom-container.component';
import { VerifyDetailsComponent } from './verify-details/verify-details.component';
import { TenantsGridComponent } from './tenants-grid/tenants-grid.component';
import { UsersComponent } from './users/users.component';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ProductComponent } from './product/product.component';
import { VenueComponent } from './venue/venue.component';
import { ThemesComponent } from './themes/themes.component';
import { PolicyComponent } from './policy/policy.component';
import { ExceptionsComponent } from './exceptions/exceptions.component';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductsAllComponent } from './products-all/products-all.component';
import { ProductLandingComponent } from './product-landing/product-landing.component';
import { AdminBookingsComponent } from './admin-bookings/admin-bookings.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { TermsComponent } from './terms/terms.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HelpComponent } from './help/help.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { HomePageComponent } from './home-page/home-page.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';



const routes: Routes = [
   {
      path: 'home',
      component: HomePageComponent,

  },
  {
    path: 'user-activation',
    component: UserActivationComponent
  },
  {
    path: 'password-reset/:token',
    component: UserActivationComponent
  },
  {
    path: 'link-expired',
    component: LinkExpiredComponent
  },
  {
    path: 'product-list/:id',
    component: ProductListComponent
  },
  {
    path: 'businesses',
    component: ProductsAllComponent
  },
  {
    path: 'products-deatils/:id',
    component: ProductLandingComponent
  },
  {
    path: 'login-success',
    component: LoginSuccessComponent,
    canActivate: [AuthGuard]
  },
  {
      path: 'terms',
      component: TermsComponent
  },
  {
        path: 'about-us',
        component: AboutUsComponent
  },
  {
          path: 'help',
          component: HelpComponent
    },
  {
    path: 'transaction/payment/status/:confirmationId',
    component: PaymentStatusComponent
  },
  {
    path: 'transaction/payment/confirmation/:confirmationId/token/:token',
    component: PaymentConfirmationComponent
  },
  {
    path: '',
    component: BottomContainerComponent,
    children: [
      {
        path: 'landing-page',
        component: LandingPageComponent,
        children: [
          {
            path: 'admin-bookings',
            component: AdminBookingsComponent
          },
          {
            path: 'transactions',
            component: TransactionsComponent
          },
          {
            path: 'company-profile',
            component: CompanyProfileComponent
          },
          {
            path: 'products',
            component: ProductComponent
          },
          {
            path: 'venues',
            component: VenueComponent
          },
          {
            path: 'themes',
            component: ThemesComponent
          },
          {
            path: 'policy',
            component: PolicyComponent
          },
          {
            path: 'exceptions',
            component: ExceptionsComponent
          }
        ]
      },
      {
              path: 'tenants-grid',
              component: TenantsGridComponent
       },
      {
              path: 'users',
              component: UsersComponent
      },
      {
        path: 'sign-up-success',
        component: SignUpSuccessComponent
      },
      {
        path: 'verify-details',
        component: VerifyDetailsComponent
      },
      {
        path: 'facility-details',
        component: FacilityDetailsComponent
      },
    ]
  },
  {
    path: 'login',
    component: BottomContainerComponent
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routing = RouterModule.forRoot(routes)
