<!-- <div *ngIf="currentUser">
  <app-login-success></app-login-success>
</div>

<div class="main-content" *ngIf="!currentUser">
  <app-login></app-login>
</div> -->
<!-- <router-outlet></router-outlet> -->
<app-top-bar class="d-sm-block"></app-top-bar>
<!--<mobile-navigator class="d-blcok d-sm-none"></mobile-navigator>-->
<!--<app-bottom-container></app-bottom-container>-->
<router-outlet></router-outlet>
<!--<app-footer></app-footer>-->
