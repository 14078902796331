<div class="main-container" style="background-color: #ffffff;">
  <div class="content-wrapper">
    <div class="container">
      <!-- Search Row -->
      <div class="row">
        <div class="col-md-6 col-lg-4" style="margin-top: 10px;">
          <label class="fieldName " style="font-size: 12px;">SEARCH</label>
          <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <input
              type="text"
              [(ngModel)]="searchText"
              placeholder="Search"
              class="form-control"
              style="min-width: 200px; margin-bottom: 10px;"
            />
            <button
              class="btn btn-primary mt-2 mt-md-0 ms-md-2 book-now-btn"
              (click)="search()"
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <!-- Grid Row -->
      <div class="row" *ngIf="SA">
        <div class="col">
          <div class="users" style="margin-top: 25px;">
            <ag-grid-angular
              style="width: 100%; height: 800px;"
              class="ag-theme-alpine"
              [rowData]="filteredUsersData"
              [columnDefs]="gridOptions.columnDefs"
              [gridOptions]="gridOptions"
            >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="main-container" style= "background-color: #ffffff;">
  <div class= "content-wrapper">
    <div class="container">

      <div class="row" >
        <div class="col-md-4" style="margin-top:10px;">
          <label  class="fieldName" style="font-size:12px;" >SEARCH</label>
          <div class = "row" style="height:10px; margin-bottom:10px; margin-left:5px;">
            <input style="min-width: 200px;" type="text" [(ngModel)]="searchText" placeholder="Search">
            <button style="margin-left: 10px;" (click)="search()">Search</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="SA">
        <div class="col">
          <div class= "users" style="margin-top: 25px;">
            <ag-grid-angular
              style="width: 100%; height: 800px;"
              class="ag-theme-alpine"
              [rowData]="usersData"
              [columnDefs]="gridOptions.columnDefs"
              [gridOptions]="gridOptions"
            >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<router-outlet></router-outlet>
