import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor() { }

  @ViewChild('section1Ref' , { static: true }) section1Ref!: ElementRef;
  @ViewChild('section2Ref',  { static: true }) section2Ref!: ElementRef;

  scrollToSection(section: string): void {

     console.log("scrollToSection");
      let targetElement: HTMLElement | null = document.getElementById(section);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth',block: 'start' });
      }

  }

  ngOnInit() {
  }

}
