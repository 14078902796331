import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.css']
})
export class VenueComponent implements OnInit {
  panelOpenState;
  showAddNewVenueForm = false;
  showUpdateVenueForm = false;
  repeatBookingAllowed;
  venueStatus;
  tenantId;
  productList = [];
  venueFormGroup: FormGroup;
  updateVenueFormGroup: FormGroup;
  venues = [];
  clickedVenueDetails;
  allowSearchFilter = false;
  selectedItems = [];
  updateProductSelectedItems = [];
  dropdownSettings = {};
  productIdToBeRemoved = [];

  // for status toggle
  color: ThemePalette = 'primary';
  isChecked = true;
  checked = true;
  disabled = false;

  removable = false;

  constructor(private authService: AuthService) {
    this.tenantId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).tenantId;
   }

  ngOnInit() {

    if( AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
        console.log("Tenant Id Venues ", AppConstants.SESSION_STORAGE_TENANTID);
        this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
        console.log("Tenant Id ", this.tenantId);
        //console.log("user", this.user);
    }
    this.getAllVenuesByTenantId(this.tenantId);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: false
    };

    this.venueFormGroup = new FormGroup({
      venueNameFormControl: new FormControl('', [
        Validators.required
      ])
      // venueTypeFormControl: new FormControl('', [
      //   Validators.required
      // ])
      // productForVenuesFormControl: new FormControl('', [
      //   Validators.required
      // ])
    });

    this.updateVenueFormGroup = new FormGroup({
      updateVenueNameFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.authService.getAllProductsByTenantId(this.tenantId).subscribe(
      (getAllProductsResponse: any[]) => {
        getAllProductsResponse.forEach(element => {
          this.productList = [...this.productList, {item_id: element.id, item_text: element.productName}];
        });
        // console.log(this.productList);
      },
      (error) => {}
    )
  }

  getAllVenuesByTenantId(tenantId) {
    if(AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
      this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
    }

    this.authService.getAllVenuesByTenantId(tenantId).subscribe(
      (getAllVenuesByTenantIdResponse: any) => {
        this.venues = getAllVenuesByTenantIdResponse;
      },
      (error) => {}
    )
  }

  onItemSelect(item: any) {
    console.log(item);
    // console.log(this.selectedItems);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  get venueFormControls() {
    return this.venueFormGroup.controls;
  }

  get updateVenueFormControls() {
    return this.updateVenueFormGroup.controls;
  }

  onUpdateVenueFormSaveClicked() {
    const newlyAddedProductIds = this.updateProductSelectedItems.map(product => product.item_id).filter((value) => !this.clickedVenueDetails.productIds.includes(value));
    console.log('newlyAddedProductIds', newlyAddedProductIds);
    // const newArray = this.clickedVenueDetails.productIds.filter((value) => !this.productIdToBeRemoved.includes(value));
    // console.log(newArray);
    console.log(this.productIdToBeRemoved);
    const productIds = Array.from(new Set(this.clickedVenueDetails.productIds.concat(newlyAddedProductIds))).filter((value) => !this.productIdToBeRemoved.includes(value));
    const updateVenueRequest = {
      venueName: this.updateVenueFormGroup.get('updateVenueNameFormControl').value,
      productIds: productIds,
      tenantId: this.clickedVenueDetails.tenantId,
      repeatBookingAllowed: this.repeatBookingAllowed,
      status: this.getVenueStatusValue()
    }

    this.authService.updateVenue(this.clickedVenueDetails.id, updateVenueRequest).subscribe(
      (updateVenueResponse: any) => {
        if(AppConstants.SESSION_STORAGE_ROLEID == 1)
        {
          this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
        }
        console.log('Successfully updated venue.');
        this.getAllVenuesByTenantId(this.tenantId);
        this.showUpdateVenueForm = false;
        this.resetUpdateVenueForm();
      },
      (error) => {}
    );
    console.log(updateVenueRequest);
  }

  newlyAddedProductIds(oldProductIds, newProductIds) {
    const diff = newProductIds.filter(x => !oldProductIds.includes(x) );
    console.log('difference of sets', diff);
    return diff;
  }

  onUpdateVenueFormCancelClicked() {
    this.showUpdateVenueForm = false;
    this.resetUpdateVenueForm();
  }

  resetUpdateVenueForm() {
    this.updateVenueFormGroup.reset();
    this.repeatBookingAllowed = null;
    this.updateProductSelectedItems = [];
  }

  clickAddNewVenue() {
    this.showAddNewVenueForm = true;
  }

  clickEditVenue(venue) {
    console.log('clickEditVenue()', venue);
    this.clickedVenueDetails = venue;
    this.updateVenueFormGroup.get('updateVenueNameFormControl').setValue(venue.venueName);
    if(this.clickedVenueDetails.status == AppConstants.STATUS_ACTIVE) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
    this.repeatBookingAllowed = this.clickedVenueDetails.repeatBookingAllowed;
    this.updateProductSelectedItems = this.getProductsByProductIds(this.clickedVenueDetails.productIds);
    this.showUpdateVenueForm = true;
  }

  clickDeleteVenue(venue) {
    console.log('clickDeleteVenue()', venue);
  }

  onCreateVenueFormSaveClicked() {
    const createVenueRequest = {
      // venueType: this.venueFormGroup.get('venueTypeFormControl').value,
      venueName: this.venueFormGroup.get('venueNameFormControl').value,
      productIds: this.selectedItems.map(product => product.item_id),
      tenantId: this.tenantId,
      repeatBookingAllowed: this.repeatBookingAllowed,
      status: this.getVenueStatusValue()
    }

    this.authService.createVenue(createVenueRequest).subscribe(
      (createVenueResponse: any) => {
        console.log('Successfully create venue.');
        this.venues = [...this.venues, createVenueResponse];
        this.showAddNewVenueForm = false;
        this.resetCreateVenueForm();
      },
      (error) => {}
    );
  }

  onCreateVenueFormCancelClicked() {
    this.showAddNewVenueForm = false;
    this.resetCreateVenueForm();
  }

  resetCreateVenueForm() {
    this.venueFormGroup.reset();
    this.repeatBookingAllowed = null;
    this.selectedItems = [];
  }

  getVenueStatusValue() {
    if(this.isChecked) {
      return AppConstants.STATUS_ACTIVE.charAt(0).toUpperCase() + AppConstants.STATUS_ACTIVE.slice(1);
    } else {
      return AppConstants.STATUS_INACTIVE.charAt(0).toUpperCase() + AppConstants.STATUS_INACTIVE.slice(1);
    }
  }

  removeSelectedProduct(product) {
    console.log(product);
    this.selectedItems = this.selectedItems.slice(1);
  }

  removeSelectedProductUpdateForm(product) {
    console.log(product);
    this.updateProductSelectedItems = this.updateProductSelectedItems.filter((value) => value.item_id !== product.item_id);
    console.log(this.updateProductSelectedItems);
    this.productIdToBeRemoved.push(product.item_id);
    console.log(this.productIdToBeRemoved);
  }

  getRepeatBookingText(venue) {
    if(venue.repeatBookingAllowed) {
      return "Yes";
    } else {
      return "No";
    }
  }

  getProductNameForProductId(productId) {
    return this.productList.find(item => productId === item.item_id).item_text;
  }

  getProductsByProductIds(productIds) {
    return this.productList.filter(item => productIds.includes(item.item_id));
  }

}
