import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';

@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.css']
})
export class FacilityDetailsComponent implements OnInit {
  facilityTypes;
  cities = ["Pune"];
  public selectedFacilityTypeValue;
  public selectedCityValue;
  facilityDetailsFormGroup: FormGroup;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.getFacilityDetails().subscribe(
      response => {
        this.facilityTypes = response;
      }
    );

    this.facilityDetailsFormGroup = new FormGroup({
      facilityNameFormControl: new FormControl('', [
        Validators.required
      ]),
      facilityTypeFormControl: new FormControl('', [
        Validators.required
      ]),
      addressLine1FormControl: new FormControl('', [
        Validators.required
      ]),
      addressLine2FormControl: new FormControl('', [
        Validators.required
      ]),
      cityFormControl: new FormControl('', [
        Validators.required
      ]),
      pincodeFormControl: new FormControl('', [
        Validators.required
      ]),
      phoneNumber1FormControl: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)
      ]),
      phoneNumber2FormControl: new FormControl('', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)
      ]),
      phoneNumber3FormControl: new FormControl('', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)
      ])
    })
  }

  get facilityDetailsFormControls() {
    return this.facilityDetailsFormGroup.controls;
  }

  selectValueChangeForFacilityType() {
    console.log(this.selectedFacilityTypeValue);
  }

  selectValueChangeForCity() {
    console.log(this.selectedCityValue);
  }

  onNextClicked() {
    const facilityDetails = {
      userName: JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).username,
      tenantName: this.facilityDetailsFormGroup.get("facilityNameFormControl").value,
      address: {
        addressLine1: this.facilityDetailsFormGroup.get("addressLine1FormControl").value,
        addressLine2: this.facilityDetailsFormGroup.get("addressLine2FormControl").value,
        city: this.facilityDetailsFormGroup.get("cityFormControl").value,
        pinCode: this.facilityDetailsFormGroup.get("pincodeFormControl").value,
        country: "India"
      },
      tenantType: "Customer",
      tenantCategoryId: Number(this.facilityDetailsFormGroup.get("facilityTypeFormControl").value),
      contactPhoneNumber1: this.facilityDetailsFormGroup.get("phoneNumber1FormControl").value,
      contactPhoneNumber2: this.facilityDetailsFormGroup.get("phoneNumber2FormControl").value,
      contactPhoneNumber3: this.facilityDetailsFormGroup.get("phoneNumber3FormControl").value
    }
    this.authService.createTenantV2(facilityDetails).subscribe(
      (response: any) => {
        console.log(response);
        console.log("facility details created successfully");
        var user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));
        user.tenantId = response.id; 
        sessionStorage.setItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT, JSON.stringify(user));
        this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LANDING_PAGE);
      }
    );
  }

}
