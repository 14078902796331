import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AppConstants } from '../AppConstants';
import { LoginModalService } from '../login-modal.service';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-bottom-container',
  templateUrl: './bottom-container.component.html',
  styleUrls: ['./bottom-container.component.css']
})
export class BottomContainerComponent implements OnInit {
  minPasswordLength = 8;
  signUpFormGroup: FormGroup;
  signInFormGroup: FormGroup;
  forgotPasswordFormGroup: FormGroup;
  verifyOTPFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;
  showSignInForm = true;
  showSignUpForm = false;
  showForgotPasswordForm = false;
  showResetPasswordForm = false;
  userEmail = '';
  sendOTPClicked = false;
  disabledNoOfCasesDiv = false;
  messageToDisplayAfterOtpSent = '';
  showSignInError;
  signInError = '';

 // mobile = false;
  loginModalSubscription: Subscription;
  showModal = false;
  roleIdForSignUp;
  modalReference;
  onClickVerifyError;
  showOnClickVerifyError;
  sendOTPerror:boolean = false;
  onClickResetPasswordError;
  showOnClickResetPasswordError;
  user;
  userExistsError: boolean = false;
  @ViewChild('content', {static: false})
  private content: TemplateRef<any>;

  constructor(private modalService: NgbModal, private loginModalService: LoginModalService, private formBuilder: FormBuilder,
    private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.showOnClickVerifyError = false;
    this.userExistsError = false;
    this.showSignInError = false;
    if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
      this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT))
    } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
      this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT))
    } else {
      this.user = undefined;
    }

    this.loginModalSubscription = this.loginModalService.getLoginModalData().subscribe(data => {
      if(data.showModal) {
        this.displayLoginModalPopup();
      }
    });

    this.signUpFormGroup = new FormGroup({
      emailFormControl: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      firstNameFormControl: new FormControl('', [
        Validators.required
      ]),
      lastNameFormControl: new FormControl('', [
        Validators.required
      ]),
      phoneNoFormControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$')
      ]),
      checkboxFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.signInFormGroup = new FormGroup({
      emailFormControl: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")
      ]),
      passwordFormControl: new FormControl('', [
        Validators.required
      ])
    });

    this.forgotPasswordFormGroup = new FormGroup({
      emailFormControl: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      // passwordFormControl: new FormControl('', [
      //   Validators.required
      // ])
    });

    this.verifyOTPFormGroup = new FormGroup({
      regotp0: new FormControl('', [
        Validators.required, Validators.pattern('^[0-9]*$')
      ]),
      regotp1: new FormControl('', [
        Validators.required, Validators.pattern('^[0-9]*$')
      ]),
      regotp2: new FormControl('', [
        Validators.required, Validators.pattern('^[0-9]*$')
      ]),
      regotp3: new FormControl('', [
        Validators.required, Validators.pattern('^[0-9]*$')
      ])
    });

    this.resetPasswordFormGroup = this.formBuilder.group({
      passwordFormControl: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minPasswordLength),
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$')

        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,20}")
      ]),
      reenterPasswordFormControl: new FormControl('', [
        Validators.required
      ])
    }, { validator: this.checkPasswords });

      /* if (window.screen.width < 568) { // 768px portrait
        this.mobile = true;
      } */
  }


  get signUpFormControls() {
    return this.signUpFormGroup.controls;
  }

  get signInFormControls() {
    return this.signInFormGroup.controls;
  }

  get forgotPasswordFormControls() {
    return this.forgotPasswordFormGroup.controls;
  }

  get resetPasswordFormControls() {
    return this.resetPasswordFormGroup.controls;
  }

  isEmailFilled() {
    return this.userEmail != '' && !this.signInFormControls.emailFormControl.hasError('invalid');
  }

  setEmailFilled() {
    if(this.isEmailFilled()) {
      this.forgotPasswordFormGroup.controls['emailFormControl'].setValue(this.userEmail);
    }
  }

  onClickedSendOTP() {
    this.showOnClickVerifyError = false;
    const emailFormControl = this.forgotPasswordFormGroup.get('emailFormControl');
    if (emailFormControl.valid) {
      emailFormControl.disable();
      this.sendOTPClicked = true;
      this.authService.sendOTP(emailFormControl.value, AppConstants.OTP_TYPE_EMAIL).subscribe(
        response => {
          this.messageToDisplayAfterOtpSent = 'OTP sent successfully';
        },
        errorResponse => {
          this.sendOTPerror = true;
          this.messageToDisplayAfterOtpSent = errorResponse.error.errors[0].message;
          /* console.error(error);
                    this.showOnClickResetPasswordError = true;
                    this.onClickResetPasswordError = error.error.errors[0].message;
           */
        }




      )
    }
  }

  getCodeBoxElement(index) {
    return (<HTMLInputElement>document.getElementById('codeBox' + index));
  }

  onKeyUpEvent(index, event) {
    this.showOnClickVerifyError = false;
    const eventCode = event.which || event.keyCode;
    // if (!this.isNumber(event)) {
    //   return false;
    // }
    if (this.getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        this.getCodeBoxElement(index+ 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onFocusEvent(index) {
    this.showOnClickVerifyError = false;
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if (!currentElement.value) {
          currentElement.focus();
          break;
      }
    }
  }

  checkPasswords(group: FormGroup) {
    this.showOnClickResetPasswordError = false;
    let pass = group.controls.passwordFormControl;
    let confirmPass = group.controls.reenterPasswordFormControl;
    if (
      confirmPass.errors &&
      !confirmPass.errors.confirmedValidator
    ) {
      return;
    }
    if (pass.value !== confirmPass.value) {
      confirmPass.setErrors({ confirmedValidator: true });
    } else {
      confirmPass.setErrors(null);
    }
  }

  onClickVerify() {
    this.showOnClickVerifyError = false;
    this.sendOTPerror = false;
    const otp = this.verifyOTPFormGroup.get('regotp0').value + this.verifyOTPFormGroup.get('regotp1').value
      + this.verifyOTPFormGroup.get('regotp2').value + this.verifyOTPFormGroup.get('regotp3').value;
      const email = this.isEmailFilled() ? this.userEmail : this.forgotPasswordFormGroup.get('emailFormControl').value;
      this.authService.validateOTP(email, otp, AppConstants.OTP_TYPE_EMAIL).subscribe(
        (response: any) => {
          this.showSignInForm = false;
          this.showSignUpForm = false;
          this.showForgotPasswordForm = false;
          console.log(response);
          this.showResetPasswordForm = true;
          if(response && response.accessToken) {
            sessionStorage.setItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN, JSON.stringify(response.accessToken));
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.showOnClickVerifyError = true;
          this.onClickVerifyError = error.error.errors[0].message;
        }
      );
      this.verifyOTPFormGroup.reset();
  }

  onClickForgotPasswordCancel() {

    this.verifyOTPFormGroup.reset();
    this. forgotPasswordFormGroup.reset();
    this.sendOTPClicked = false;
    this.showOnClickVerifyError = false;
    this.showSignUpForm = false;
    this.showForgotPasswordForm = false;
    this.showResetPasswordForm = false;
    this.showSignInForm = true;
    this.userExistsError = false;
    this.showSignInError = false;
    this.sendOTPerror = false;

  }

  onResetPasswordClicked() {

    console.log("bottom-container reset password", this.resetPasswordFormGroup);
    this.showOnClickResetPasswordError = false;

    if (this.resetPasswordFormGroup.invalid) {

        console.log("bottom-container reset password:  Invalid");
        this.showOnClickResetPasswordError = true;
        return;
    }
    else
    {
    /* (this.resetPasswordFormGroup.valid) */

      console.log('Check last 5 passwords from database');
      this.authService.resetPassword(this.resetPasswordFormGroup.get('passwordFormControl').value, this.resetPasswordFormGroup.get('reenterPasswordFormControl').value).subscribe(
        response => {
          const email = this.isEmailFilled() ? this.userEmail : this.forgotPasswordFormGroup.get('emailFormControl').value;
          alert("Password reset successfully for user: "+ email);
          this.showSignInForm = false;
          this.showSignUpForm = false;
          this.showForgotPasswordForm = false;
          this.showResetPasswordForm = false;
          this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN).then(() => {
            window.location.reload();
        });
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.showOnClickResetPasswordError = true;
          this.onClickResetPasswordError = error.error.errors[0].message;
        }
      );
    }

  }

  displayLoginModalPopup() {
    this.roleIdForSignUp = 3;
    this.modalReference = this.modalService.open(this.content, { windowClass: 'bottom-container', ariaLabelledBy: 'modal-basic-title'});
  }

  onClickCreateNow() {
    //console.log("onClickCreateNow");
    this.roleIdForSignUp = 2;
    this.showSignInForm = false;
    this.showForgotPasswordForm = false;
    this.showSignUpForm = true;
    this.modalReference = this.modalService.open(this.content, { windowClass: 'bottom-container', ariaLabelledBy: 'modal-basic-title'});
  }
  onClickSignUpEndUser() {
      //console.log("onClickCreateNow");
      this.roleIdForSignUp = 3;
      this.showSignInForm = false;
      this.showForgotPasswordForm = false;
      this.showSignUpForm = true;
      this.modalReference = this.modalService.open(this.content, { windowClass: 'bottom-container', ariaLabelledBy: 'modal-basic-title'});
  }
  navigateToBusinesses() {
      // Navigate to your desired HTML page
      this.router.navigate(['/businesses']); // Change '/your-page' to the actual route of your HTML page
  }

  onGetStartedClicked() {


   if (this.signUpFormGroup.invalid) {
        this.signUpFormGroup.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
        return;
   }
   else
   {
      this.authService.createExternalUser(this.signUpFormGroup.get('firstNameFormControl').value,
          this.signUpFormGroup.get('lastNameFormControl').value, this.signUpFormGroup.get('emailFormControl').value, this.roleIdForSignUp, this.signUpFormGroup.get('phoneNoFormControl').value, "").subscribe(
            createExternalUserRes => {
              this.signUpFormGroup.reset();
              this.modalReference.dismiss();
              sessionStorage.setItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT, JSON.stringify(createExternalUserRes));
              this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_VERIFY_DETAILS);
            },
            (error: HttpErrorResponse) => {
              if(error.error.errors[0].code == 1104) {
                this.userExistsError = true;
                //alert("User email or phone number already exists.");
               }
            }
       );
   }

  }

  isPasswordTyped: boolean = false;
  onPasswordInput() {

      const passwordControl = this.signInFormGroup.get('passwordFormControl');
      if (passwordControl) {
          this.isPasswordTyped = !!passwordControl.value;
      }

  }

  onClickedSignIn() {
    this.showSignInError = false;

    if(this.signInFormGroup.valid) {
      this.authService.login(this.signInFormGroup.get('emailFormControl').value, this.signInFormGroup.get('passwordFormControl').value).pipe(first())
      .subscribe(
        loginResponse => {
          console.log(loginResponse);
          const firstName = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).firstName;
          this.modalReference.dismiss();
          this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_SIGN_UP_SUCCESS);
        },
        (errorLoginResponse: HttpErrorResponse) => {
          console.log(errorLoginResponse);
          this.showSignInError = true;
          this.signInError = errorLoginResponse.error.errors[0].message;
          //alert("Incorrect username or password for user:" + this.signInFormGroup.get('emailFormControl').value);
        }
      );
    }
  }

  onCancelLogin() {
      this.showSignInForm = true;
      this.showSignUpForm = false;
      this.showForgotPasswordForm = false;
      this.showResetPasswordForm = false;
      this.verifyOTPFormGroup.reset();
      this.forgotPasswordFormGroup.reset();
      this.sendOTPClicked = false;
      this.showOnClickVerifyError = false;
      this.userExistsError = false;
      this.showSignInError = false;
      this.sendOTPerror = false;
      this.modalReference.dismiss();
  }

  onShowSignInFormClicked() {
    this.showSignUpForm = false;
    this.showForgotPasswordForm = false;
    this.showSignInForm = true;
  }

  onShowSignUpFormClicked() {
      this.roleIdForSignUp = 3;
      this.showForgotPasswordForm = false;
      this.showSignInForm = false;
      this.showSignUpForm = true;
  }

  onShowForgotPasswordFormClicked() {
    this.showOnClickResetPasswordError = false;
    this.showOnClickVerifyError = false;
    this.userEmail = this.signInFormGroup.get('emailFormControl').value;
    this.showSignUpForm = false;
    this.showSignInForm = false;
    this.setEmailFilled();
    this.showForgotPasswordForm = true;
}

  isUserDefined() {
    if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
      return true;
    } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
      return true;
    } else {
      return false;
    }
  }

}
