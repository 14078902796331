import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APIRoutes } from '../APIRoutes';
import { AppConstants } from '../AppConstants';
import { of } from 'rxjs';
import { ProductLandingComponent } from '../product-landing/product-landing.component';
// import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtService: JwtHelperService;
  // datePipe: DatePipe = new DatePipe('en-US');
  constructor(private httpClient: HttpClient ) {
    this.jwtService = new JwtHelperService();
   }

  getUsername() {
    return JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).username;
  }

  getTokenExpiryTime() {
    const expiryTime = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).exp;
    const currentTime = Date.now() / 1000;
    return (expiryTime - currentTime);
  }

  login(username: string, password: string) {
    return this.httpClient.post<any>(APIRoutes.AUTH_TOKEN_URI, {username, password})
    .pipe(map(user => {
      console.log("User:auth service ", user);

      if(user && user.accessToken) {
        const decodedToken = this.jwtService.decodeToken(user.accessToken);
        sessionStorage.setItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT, JSON.stringify(decodedToken));
        sessionStorage.setItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN, JSON.stringify(user.accessToken));
      }
      return user;
    }));
  }

    loginWithSocialUser(socialUser: any) {
      return this.httpClient.post<any>(APIRoutes.SOCIAL_AUTH_TOKEN_URI, {
                                                "email": socialUser.email,
                                                "firstName": socialUser.firstName,
                                                "lastName": socialUser.lastName,
                                                "socialToken": socialUser.idToken,
                                                "socialType": socialUser.provider,
        }).pipe(map(
            user => {
              console.log("User:auth service ", user);
              if(user && user.accessToken) {
                const decodedToken = this.jwtService.decodeToken(user.accessToken);
                sessionStorage.setItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT, JSON.stringify(decodedToken));
                sessionStorage.setItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN, JSON.stringify(user.accessToken));
              }
              return user;
            }
          ));
    }

  sendOTP(username: string, type: string) {
    return this.httpClient.post(APIRoutes.SEND_OTP_URI, null, {params: {id: username, type: type}});
  }

  validateOTP(username: string, OTP: string, type: string) {
    return this.httpClient.post(APIRoutes.VALIDATE_OTP_URI, {username: username, OTP: OTP}, {params: {type: type}});
  }

  createExternalUser(firstname: string, lastname: string, username: string, roleId: number, phoneNumber: string, interestedIn: string) {
    return this.httpClient.post(APIRoutes.CREATE_EXTERNAL_USER_URI, {firstName: firstname, lastName: lastname, email: username, roleId: roleId, phoneNumber: phoneNumber, interestedIn: interestedIn});
  }

  resetPasswordAndActivateUser(userId: number, password: string, reEnterPassword: string) {
    return this.httpClient.post(APIRoutes.USER_ACTIVATION_URI + '/' + userId, {password: password, reenterPassword: reEnterPassword});
  }

  resetPassword(password: string, reEnterPassword: string) {
    return this.httpClient.post(APIRoutes.RESET_PASSWORD_URI, {password: password, reenterPassword: reEnterPassword});
  }

  emailPasswordResetLink(username: string) {
    return this.httpClient.post(APIRoutes.EMAIL_PASSWORD_RESET_LINK_URI, null, {params: {username: username}});
  }

  getFacilityDetails() {
    return this.httpClient.get(APIRoutes.FACILITY_TYPE_URI);
  }

  createTenantV2(facilityDetails) {
    return this.httpClient.post(APIRoutes.CREATE_TENANT_V2_URI, facilityDetails);
  }

  updateTenantByTenantId(tenantId: number, facilityDetails) {
    return this.httpClient.put(APIRoutes.UPDATE_TENANT_DETAILS_BY_TENANTID + '/' + tenantId, facilityDetails);
  }

  updateGoogleMapsLinkByTenantId(tenantId: number, googleMapsLink: string) {
    // let params = new HttpParams();
    // params.append("googleMapsLink", googleMapsLink);
    return this.httpClient.patch(APIRoutes.UPDATE_GOOGLE_MAPS_LINK_BY_TENANTID + tenantId, null, {params : {googleMapsLink: googleMapsLink}});
  }

  getTenantDetailsBYTenantID(tenantId) {
    return this.httpClient.get(APIRoutes.GET_TENANT_DETAIS_BY_TENANTID + tenantId);
  }

  getTenantHolidaysByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.GET_TENANT_HOLIDAYS_BY_TENANTID + '/' + tenantId);
  }

  getTenantAmenitiesByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.GET_TENANT_AMENITIES_BY_TENANTID + '/' + tenantId);
  }

  getTenantRulesByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.GET_TENANT_RULES_BY_TENANTID + '/' + tenantId);
  }

  createNewAmenity(tenantId: number, amenityName: string) {
    return this.httpClient.post(APIRoutes.CREATE_TENANT_AMENITY, {tenantId: tenantId, amenityName: amenityName});
  }

  deleteAmenityByAmenityId(amenityId) {
    return this.httpClient.delete(APIRoutes.DELETE_TENANT_AMENITY + '/' + amenityId);
  }

  createNewHoliday(tenantId: number, holiday: string) {
    return this.httpClient.post(APIRoutes.CREATE_TENANT_HOLIDAY_BY_TENANTID, {tenantId: tenantId, date: holiday});
  }

  deleteHolidayByHolidayId(holidayId) {
    return this.httpClient.delete(APIRoutes.DELETE_TENANT_HOLIDAY_BY_HOLIDAYID + '/' + holidayId);
  }

  createNewRule(tenantId: number, ruleType: string, ruleIndex: number, description: string) {
    return this.httpClient.post(APIRoutes.CREATE_TENANT_RULE_BY_TENANTID, {tenantId: tenantId, ruleType: ruleType, ruleIndex: ruleIndex, description: description});
  }

  deleteRuleByRuleId(ruleId: number) {
    return this.httpClient.delete(APIRoutes.DELETE_TENANT_RULE_BY_RULEID + '/' + ruleId);
  }

  createProduct(product) {
    return this.httpClient.post(APIRoutes.CREATE_PRODUCT,
      {
        productName: product.productName,
        price: product.price,
        description: product.description,
        durationPerSlot: product.durationPerSlot,
        capacityPerVenue: product.capacityPerVenue != null ? product.capacityPerVenue : null,
        productType: product.productType,
        tenantId: product.tenantId,
        status: product.status,
        sessionType: product.sessionType != null ? product.sessionType : null,
        noOfSessions: product.noOfSessions != null ? product.noOfSessions : null,
        monthlyMultiBookingOption: product.monthlyMultiBookingOption != '' ? product.monthlyMultiBookingOption : null,
        monthlyMultiBookingSelectedDates: product.monthlyMultiBookingSelectedDates != '' ? product.monthlyMultiBookingSelectedDates : null
      });
  }

  updateProduct(id, product) {
    return this.httpClient.put(APIRoutes.UPDATE_PRODUCT + id,
      {
        productName: product.productName,
        price: product.price,
        description: product.description,
        durationPerSlot: product.durationPerSlot,
        capacityPerVenue: product.capacityPerVenue,
        status: product.status,
        noOfSessions: Number(product.noOfSessions)
      });
  }

  getAllProductsByTenantId(tenantId: number) {
    return this.httpClient.get(APIRoutes.GET_ALL_PRODUCTS_BY_TENANTID + tenantId);
  //   return of([
  //     {
  //         'id': 3,
  //         'productName': 'SS 8 am to 1 pm',
  //         'price': 50,
  //         'description': 'asdfdsasdf',
  //         'durationPerSlot': 60,
  //         'capacityPerVenue': 1,
  //         'productType': 'Single Session',
  //         'tenantId': 2,
  //         'status': 'Inactive',
  //         'createdAt': 1663400123000,
  //         'updatedAt': 1663400545000
  //     },
  //     {
  //         'id': 4,
  //         'productName': 'SS 8pm to 1 pm',
  //         'price': 50,
  //         'description': 'sdfasdfsdf',
  //         'durationPerSlot': 60,
  //         'capacityPerVenue': 1,
  //         'productType': 'Single Session',
  //         'tenantId': 2,
  //         'status': 'Active',
  //         'createdAt': 1663400443000,
  //         'updatedAt': 1663400443000
  //     }
  // ]);
  }

  initiateTransaction(arrayJSon) {
    let jsonObject = JSON.parse(arrayJSon);
    return this.httpClient.post(APIRoutes.INITIATE_TRANSACTION, jsonObject);
  }

  //PhonePe
  initiatePayApi(arrayJSon) {
    let jsonObject = JSON.parse(arrayJSon);
    return this.httpClient.post(APIRoutes.INITIATE_PHONEPAY_PAY_API, jsonObject);
  }

  getBookingByConfirmationId(confirmationId) {
    return this.httpClient.get(APIRoutes.GET_BOOKING_BY_ID + confirmationId);
  }

  confirmBooking(arrayJSon) {
    // let auth_token = sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN);
    //console.log('Auth_Token', auth_token);

    //console.log("ArrayJSON in AUth ", arrayJSon);
    // DO NOT REMOVE THIS WAS USED FOR PAYTM
    // let jsonObject = JSON.parse(arrayJSon);
    return this.httpClient.post(APIRoutes.CONFIRM_BOOKING, arrayJSon);
  }

  confirmBookingByConfirmationId(confirmationId) {
    return this.httpClient.post(APIRoutes.CONFIRM_BOOKING, {
      confirmationId: confirmationId
    });
  }

  cancelBooking(bookingID) {

    return this.httpClient.put(APIRoutes.CANCEL_BOOKING + bookingID,null);

  }

  cancelSlots(bookingSlots, bookingId){
    return this.httpClient.put(APIRoutes.CANCEL_SLOT  + "?bookingId=" + bookingId , {
      slotsIds : bookingSlots
    });
  }

  createSession(session) {
    return this.httpClient.post(APIRoutes.CREATE_SESSION,
      {
        tenantId: session.tenantId,
        productId: session.productId,
        startTime: session.startTime,
        endTime: session.endTime,
        monday: session.monday,
        tuesday: session.tuesday,
        wednesday: session.wednesday,
        thursday: session.thursday,
        friday: session.friday,
        saturday: session.saturday,
        sunday: session.sunday
      });
  }

  deleteSessionById(sessionId : number) {
    return this.httpClient.delete(APIRoutes.DELETE_SESSION + sessionId);
  }

  createTimeSlotWiseProductsByProductId(productId) {
    return this.httpClient.post(APIRoutes.CREATE_TIME_SLOT_WISE_PRODUCTS_BY_PRODUCTID + productId, null);
  }

  createTimeSlotWiseProducts(timeslotWiseProducts) {
    const arrayOftimeslotWiseProducts: Array<any> = timeslotWiseProducts;
    return this.httpClient.post(APIRoutes.CREATE_TIME_SLOT_WISE_PRODUCTS, arrayOftimeslotWiseProducts);
  }

  createTenantImages(tenantId: number, defaultImageIndex: number, image) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("tenantId", String(tenantId));
    formData.append("defaultImageIndex", String(defaultImageIndex));
    return this.httpClient.post(APIRoutes.CREATE_TENANT_IMAGES, formData);
  }

  getTenantImagesByTenantId(tenantId: number) {
    return this.httpClient.get(APIRoutes.GET_TENANT_IMAGES_BY_TENANTID + tenantId, {observe: 'response'});
  }

  updateTenantImagesByTenantId(tenantId: number, defaultImageIndex: number, image) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("defaultImageIndex", String(defaultImageIndex));
    return this.httpClient.put(APIRoutes.UPDATE_TENANT_IMAGES_BY_TENANTID + tenantId, formData);
  }

  updateTenantImagesByTenantIdWithNoImage(tenantId: number, defaultImageIndex: number) {
    const formData = new FormData();
    formData.append("defaultImageIndex", String(defaultImageIndex));
    return this.httpClient.put(APIRoutes.UPDATE_TENANT_IMAGES_BY_TENANTID + tenantId, formData);
  }

  createVenue(createVenueRequest) {
    return this.httpClient.post(APIRoutes.CREATE_VENUE,
      {
        // venueType: createVenueRequest.venueType,
        venueName: createVenueRequest.venueName,
        productIds: createVenueRequest.productIds,
        tenantId: createVenueRequest.tenantId,
        repeatBookingAllowed: createVenueRequest.repeatBookingAllowed,
        status: createVenueRequest.status
      }
      );
  }

  updateVenue(id, updateVenueRequest) {
    return this.httpClient.put(APIRoutes.UPDATE_VENUE + id,
      {
        venueName: updateVenueRequest.venueName,
        productIds: updateVenueRequest.productIds,
        repeatBookingAllowed: updateVenueRequest.repeatBookingAllowed,
        status: updateVenueRequest.status
      });
  }

  getAllVenuesByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.GET_ALL_VENUES_BY_TENANTID + tenantId);

  //   return of([
  //     {
  //         'id': 4,
  //         'venueName': 'Room 1',
  //         'productIds': [
  //             3,
  //             4
  //         ],
  //         'tenantId': 2,
  //         'repeatBookingAllowed': false,
  //         'status': 'Active',
  //         'createdAt': '2022-09-17T07:35:40.000+0000',
  //         'updatedAt': '2022-09-17T07:41:04.000+0000',
  //         'venueType': ''
  //     },
  //     {
  //         'id': 5,
  //         'venueName': 'Room 2',
  //         'productIds': [
  //             3,
  //             4
  //         ],
  //         'tenantId': 2,
  //         'repeatBookingAllowed': false,
  //         'status': 'Active',
  //         'createdAt': '2022-09-17T07:36:01.000+0000',
  //         'updatedAt': '2022-09-17T07:41:19.000+0000',
  //         'venueType': ''
  //     }
  // ]);
  }

  getAllAmenitiesSauByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.TENANT_AMENITIES + '/' + tenantId, {});
  }

  getAllProductsImagesSauByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.TENANT_IMAGES + '/' + tenantId);
  }

  getAlltenants() {
    return this.httpClient.get(APIRoutes.ROUTING_MODULE_PRODUCT_ALL);
  }
  getAllUsers() {
      return this.httpClient.get(APIRoutes.GET_ALL_USERS);
  }
  getAllTransactions(tenantId, startDate, endDate, roleId) {

    let userName = this.getUsername();
    console.log("Auth Transactions: userName  ", userName);
    console.log("Auth Transactions: tenant ", tenantId);
    console.log("Auth Transactions: start Date ", startDate);
    console.log("Auth Transactions end Date: ", endDate);
    let params = new HttpParams();
    //params = params.append('productId', newThisProdId);
    if(startDate != null && endDate !=null)
    {
      if(startDate){
        params = params.append('startDate', startDate);
      }
      if(endDate){
        params = params.append('endDate', endDate);
      }
    }
    console.log("Auth Transactions params: ", params);


    if(roleId == 1 || roleId == 2)
    {

      return this.httpClient.get(APIRoutes.GET_ALL_TRANSACTIONS + '/' + tenantId, {params: params});
    }
    else if (roleId == 3)
    {
      return this.httpClient.get(APIRoutes.GET_USER_TRANSACTIONS + '/' + userName);
    }
  }

  getSauByTenantId(tenantId) {
    return this.httpClient.get(APIRoutes.ROUTING_MODULE_PRODUCT_SINGLE + '/' + tenantId);
  }

  singleBookingRequestToServer(singleObj, tenantId) {

    let auth_token = sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN);
    console.log('Auth_Token', auth_token);

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    });

    // var date = new Date();
    // let latest_date = this.datePipe.transform(date, 'yyyy-MM-dd');

    // const formData = new FormData();
    // formData.append("startTime", String(singleObj.startTime));
    // formData.append("endTime", String(singleObj.endTime));
    // formData.append("bookingDate", latest_date);
    // formData.append("amount", singleObj.amount);
    // formData.append("category", String(singleObj.startTime));
    // formData.append("tenantId", singleObj.tenantId);
    // formData.append("venueId", singleObj.venueId);
    // formData.append("userId", singleObj.userId);
    // formData.append("productId", singleObj.productId);
    // formData.append("advanceProductId", singleObj.advanceProductId);
    // formData.append("paymentType", String(singleObj.paymentType));
    console.log("Single Obj", singleObj);
    return this.httpClient.post(APIRoutes.SINGLE_BOOKING_REQUEST, singleObj);
  }

  //getSlotsSauByTenantId(tenant, selectedService1, latest_date1, thisProdId) {
  getSlotsSauByTenantId(tenant, latest_date1, thisProdId, serviceType) {
    let auth_token = sessionStorage.getItem(AppConstants.SESSION_STORAGE_ACCESS_TOKEN);
    console.log('Auth_Token', auth_token);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    });

    if(thisProdId == null){
      var newThisProdId = tenant;
    } else {
      var newThisProdId = thisProdId;
    }

    let params = new HttpParams();
    params = params.append('productId', newThisProdId);
    //params = params.append('venueType', selectedService1);
    params = params.append('date', latest_date1);

    console.log("Found Out X", serviceType);
    console.log("params", params);
    if(serviceType == "Single Session")
    {
      console.log("In Single", APIRoutes.ALL_SLOTS_OF_TENANT_MULTI + '/' + tenant, {params: params});
      return this.httpClient.get(APIRoutes.ALL_SLOTS_OF_TENANT_SINGLE + '/' + tenant, {params: params});
    }
    else if(serviceType == "Multi Session")
    {
      console.log("In Multi: ", APIRoutes.ALL_SLOTS_OF_TENANT_MULTI + '/' + tenant, {params: params});
      return this.httpClient.get(APIRoutes.ALL_SLOTS_OF_TENANT_MULTI + '/' + tenant, {params: params});
    }

  }

}
