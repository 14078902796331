export class UIRoutes {
    public static ROUTING_MODULE_HOME_PAGE = 'home-page';
    public static ROUTING_MODULE_LOGIN = 'login';
    public static ROUTING_MODULE_LOGIN_SUCCESS = 'login-success';
    public static ROUTING_MODULE_SIGN_UP_SUCCESS = '/sign-up-success';
    public static ROUTING_MODULE_LINK_EXPIRED = '/link-expired';
    public static ROUTING_MODULE_PASSWORD_RESET = 'password-reset';
    public static ROUTING_MODULE_VERIFY_DETAILS = '/verify-details';
    public static ROUTING_MODULE_LANDING_PAGE = 'landing-page';
    public static ROUTING_MODULE_FACILITY_DETAILS = 'facility-details';
    public static ROUTING_MODULE_COMPANY_PROFILE = 'company-profile';
    public static ROUTING_MODULE_PRODUCTS = 'products';
    public static ROUTING_MODULE_VENUES = 'venues';
    public static ROUTING_MODULE_THEMES = 'themes';
    public static ROUTING_MODULE_POLICY = 'policy';
    public static ROUTING_MODULE_EXCEPTIONS = 'exceptions';
    public static ROUTING_MODULE_TENANTS_GRID = 'tenants-grid';
    public static ROUTING_MODULE_USERS = 'users';
    public static ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS = '/transaction/payment/status/';
    public static ROUTING_MODULE_ADMIN_BOOKINGS_PAGE = 'admin-bookings';
    public static ROUTING_MODULE_USER_ACTIVATION = '/user-activation';
    public static ROUTING_MODULE_TRANSACTIONS = 'transactions';

    // Saudagar
    public static ROUTING_MODULE_PRODUCT_LIST = 'product-list';
    public static ROUTING_MODULE_PRODUCT_ALL = 'businesses';
    public static ROUTING_MODULE_PRODUCT_DETAILS = 'products-deatils';
}
