<div class="main-container" style="background-color:#fff; margin-top:10px;">
  <div class="container footer-div"> <!--container-->
    <div>
      <!--<div style="background-color: #fff;">-->
        <div class="row">
          <a  href="#" routerLink="/terms" class = "descriptionHeader" style="height: 40px;margin-top:20px; margin-left:50px;" >Terms of Use</a>
          <a  href="#" routerLink="/about-us" class = "descriptionHeader" style="height: 40px; padding-left: 30px; margin-top:20px;">About Us</a>
          <a  href="#" routerLink="/help" class = "descriptionHeader" style="height: 40px; padding-left: 30px; margin-top:20px;">Help</a>
        </div>
        <footer style="background-color:#fff;">
            <p class="copyright" style="color: #333333; text-align: center;">Dexter ©2024</p>
        </footer>
      <!--</div>-->
    </div>
  </div>
</div>
