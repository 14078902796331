import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FormControl, Validators, FormGroup, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty && control.touched);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty && control.touched);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit {
  minPasswordLength = 8;
  userDataToken;
  userId;
  userName;
  jwtService;
  userActivationFormGroup: FormGroup;

  matcher = new MyErrorStateMatcher();

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private authService: AuthService, private formBuilder: FormBuilder) {
    this.userDataToken = this.activatedRoute.snapshot.paramMap.get('token');
    this.jwtService = new JwtHelperService();
   }

  ngOnInit() {
    const decodeToken = this.jwtService.decodeToken(this.userDataToken);
    this.userName = decodeToken.username;
    this.userId = decodeToken.userId;
    
    if (this.isLinkExpired(decodeToken)) {
      this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LINK_EXPIRED);
    }

    this.userActivationFormGroup = this.formBuilder.group({
      passwordFormControl: new FormControl('', [
        Validators.required,
        Validators.minLength(this.minPasswordLength),
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$')

        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,20}")
      ]),
      reenterPasswordFormControl: new FormControl('', [
        Validators.required
      ])
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) {
  let pass = group.controls.passwordFormControl.value;
  let confirmPass = group.controls.reenterPasswordFormControl.value;

  return pass === confirmPass ? null : { notSame: true }     
}

  isLinkExpired(decodeToken) {
    return (decodeToken.exp - (Date.now()/1000)) < 0;
  }

  get userActivationFormControls() {
    return this.userActivationFormGroup.controls;
  }

  onResetPasswordClicked() {
    // if(this.userActivationFormGroup.valid) {
    //   if (this.router.url.indexOf(UIRoutes.ROUTING_MODULE_PASSWORD_RESET) != -1) {
    //     console.log('Check last 5 passwords from database');
    //     this.authService.resetPassword(Number(this.userId), this.userActivationFormGroup.get('passwordFormControl').value, this.userActivationFormGroup.get('reenterPasswordFormControl').value).subscribe(
    //       response => {
    //         alert("Password reset successfully for user: "+ this.userName);
    //         this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN);
    //       }
    //     );
    //   } else {
    //     this.authService.resetPasswordAndActivateUser(Number(this.userId), this.userActivationFormGroup.get('passwordFormControl').value, this.userActivationFormGroup.get('reenterPasswordFormControl').value).subscribe(
    //       response => {
    //         alert("User activated successfully: "+ this.userName);
    //         sessionStorage.clear();
    //         this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_LOGIN);
    //       }
    //     );
    //   }
    // }
  }
}
