import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AdminBookingsService } from '../service/admin-bookings.service';

@Component({
  selector: 'app-book-selected-slots',
  templateUrl: './book-selected-slots.component.html',
  styleUrls: ['./book-selected-slots.component.css']
})
export class BookSelectedSlotsComponent implements OnInit, OnDestroy {
  selectedSlotsData: any;
  displayData = {};
  displaySelectedProducts = [];
  disableConfirmBtn = true;
  globalObject = Object;
  bookNowSubscription: Subscription;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(private adminBookingSvc: AdminBookingsService, private changeDetect: ChangeDetectorRef,
    private dialogRef: MatDialogRef<BookSelectedSlotsComponent>) { }

  ngOnInit() {
    this.renderSelectedSlots();
  }

  renderSelectedSlots() {
    this.selectedSlotsData = this.adminBookingSvc.getSelectedSlotData();

    if (this.selectedSlotsData && this.selectedSlotsData.length > 0) {
      this.selectedSlotsData.forEach((slotData) => {
        if (!this.displayData[slotData.productName] || !this.displayData[slotData.productName][slotData.venueName]) {
          if (!this.displayData[slotData.productName]) {
            this.displayData[slotData.productName] = {};
          }
          this.displayData[slotData.productName][slotData.venueName] = [slotData];
        } else {
          this.displayData[slotData.productName][slotData.venueName].push(slotData);
        }
      });
    }

    this.displaySelectedProducts = Object.keys(this.displayData);
    this.changeDetect.detectChanges();
  }

  getDisplayData(data) {
    return JSON.stringify(data);
  }

  getFormattedTime(timeString) {
    let formattedTime = '';
    const splitTimeString = timeString.split(':');
    let timeValue = parseInt(splitTimeString[0], 10);
    const minsValue = parseInt(splitTimeString[1], 10);

    if (timeValue >= 12) {
      if (timeValue !== 12) {
        timeValue = timeValue % 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' PM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' PM';
      }
    } else {
      if (timeValue === 0) {
        timeValue = 12;
      }
      if (minsValue === 0) {
        formattedTime = timeValue + ' AM';
      } else {
        formattedTime = timeValue + ':' + splitTimeString[1] + ' AM';
      }
    }
    return formattedTime;
  }

  getFormattedDate(dateStr) {
    const splitDateStr = dateStr.split('_');
    const dateValue = splitDateStr[1];
    const splitDateValue = dateValue.split('-');
    return splitDateValue[2] + ' ' + this.months[parseInt(splitDateValue[1], 10) - 1] + ' ' + splitDateValue[0]
  }

  confirmBooking() {
    this.disableConfirmBtn = true;
    const userDetails = {
      name: this.firstName + ' ' + this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      notes: this.notes
    };
    this.bookNowSubscription = this.adminBookingSvc.bookNow(userDetails).subscribe((response: any) => {
        this.adminBookingSvc.reloadData.next({
          confirmationId: response.confirmationId,
          reloadData: true
        });
        this.adminBookingSvc.clearSelectedSlots();
        this.dialogRef.close();
    }, (error: any) => {
      this.disableConfirmBtn = false;
      console.log('Error on book now API call' + JSON.stringify(error));
      if (error.status === 400) {
        error.error.forEach(unavailableSlot => {
          if (unavailableSlot.bookingStatus === 'Unavailable') {
            const filteredSlot = this.selectedSlotsData.find(slot => slot.uniqueId === unavailableSlot.uniqueId);
            filteredSlot.price = 'Unavailable';
          }
        });
      }
    });
  }

  removeSlot(data) {
    this.adminBookingSvc.removeFromSelectedSlots({
      uniqueId: data.uniqueId, date: data.date, dateTime: data.startTime, canRemoveSlot: true});
    this.displayData = {};
    this.displaySelectedProducts = [];
    this.renderSelectedSlots();
  }

  isReadyToBook() {
    this.disableConfirmBtn = !(this.firstName && this.lastName && this.email && this.phoneNumber);
    return this.disableConfirmBtn;
  }

  ngOnDestroy() {
    if (this.bookNowSubscription) {
      this.bookNowSubscription.unsubscribe();
    }
  }
}
