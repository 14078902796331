import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { UIRoutes } from '../UIRoutes';
import { NgImageSliderComponent } from 'ng-image-slider';
import { AuthService } from '../service/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products-all',
  templateUrl: './products-all.component.html',
  styleUrls: ['./products-all.component.css']
})

export class ProductsAllComponent implements OnInit {
  rowData = [];
   tenants: any[];
  imageList: any;
  tenantImages: any = [];
  imageObject: Array<object> = [];
  imageObject1: any = [];
  tenantImagesObj = {};
  modalReference;
  public id: any;
  private content: TemplateRef<any>;

  @ViewChild('slider' , { static: false }) slider: NgImageSliderComponent; // Reference to the NgImageSliderComponent


  constructor(private modalService: NgbModal, private authService: AuthService, private router: Router,) { }

  ngOnInit() {
    this.getAllTenant();

    //this.getImagesForAllTenants();
  }
  displayLoginModalPopup() {
      //this.roleIdForSignUp = 3;
      this.modalReference = this.modalService.open(this.content, { windowClass: 'bottom-container', ariaLabelledBy: 'modal-basic-title'});
  }

  getAllTenant() {
    this.authService.getAlltenants().subscribe(
      (response: any[]) => {
        console.log("Tenants: ", response);

        this.rowData = response;
        console.log("All Tenants", this.rowData);
        for (let i=0; i<this.rowData.length; i++) {
          const tenant = this.rowData[i];
          this.tenantImagesObj[tenant.id] = this.getTenantImages(tenant);
        }
      },
      (error) => {

      }
    );
  }

  productDetails(id): void {
      console.log("Tenant Id: ", id);

      this.router.navigate(['/product-list', id]);
      console.log("I am here");
  }
  /* getImagesForAllTenants() {
    this.authService.getAllProductsImagesSauByTenantId(1).subscribe(
      (imageList) => {
        this.imageList = imageList;
        console.log(this.imageList.images.length);
        for(var i=0; this.imageList.images.length > i; i++){
          let imgObj = {
            image: this.imageList.images[i],
            thumbImage: this.imageList.images[i],
          }
          console.log("i: ", i);
          this.tenantImages.push(imgObj);
        }
        this.imageObject = this.tenantImages;
        console.log("imageObject :", this.imageObject);
      },
      (error) => {

      }
    );
  }
 */

  getTenantImages(tenant: any): { image: string, thumbImage: string }[] {
    const images: { image: string, thumbImage: string }[] = [];
    if (tenant.tenantImage) {
      for (let i = 1; i <= tenant.tenantImage.totalImageCount; i++) {
        if (tenant.tenantImage['image' + i]) {
          const imageObj = {
            image: tenant.tenantImage['image' + i],
            thumbImage: tenant.tenantImage['image' + i]
          };
          images.push(imageObj);
        }
      }
    }
    // If no images are available or totalImageCount is not present, add the default image
    if (images.length === 0) {
      const defaultImageObj = {
        image: "../assets/img/noImage.png",
        thumbImage: "../assets/img/noImage.png"
      };
      images.push(defaultImageObj);
    }
    //console.log("Images: ", images);
    return images;
  }

  /*   getImagesForTenant(id) {
      this.authService.getAllProductsImagesSauByTenantId(1).subscribe(
        (imageList) => {
          this.imageList = imageList;
          console.log(this.imageList);
          for(var i=0; this.imageList.images.length > i; i++){
            let imgObj = {
              image: this.imageList.images[i],
              thumbImage: this.imageList.images[i],
            }
            console.log("i: ", i);
            this.imageObject1.push(imgObj);
          }

          this.imageObject = this.imageObject1;
          console.log("imageObj:", this.imageObject);

        },
        (error) => {

        }
      );
    }
 */


  /* getTenantImages2(tenant: any): { image: string, thumbImage: string }[] {
    const images: { image: string, thumbImage: string }[] = [];
    if (tenant.tenantImage && tenant.tenantImage.totalImageCount > 0) {
      for (let i = 1; i <= tenant.tenantImage.totalImageCount; i++) {
        if (tenant.tenantImage['image' + i]) {
          const imageObj = {
            image: tenant.tenantImage['image' + i],
            thumbImage: tenant.tenantImage['image' + i]
          };
          images.push(imageObj);
        }
      }
    } else {
      // If no images are available, add the default image
      const defaultImageObj = {
        image: "../assets/img/noImage.png",
        thumbImage: "../assets/img/noImage.png"
      };
      images.push(defaultImageObj);
    }
    return images;
  }

  getTenantImages1(tenant: any): { image: string, thumbImage: string }[] {
    const images: { image: string, thumbImage: string }[] = [];
    for (let i = 1; i <= tenant.tenantImage.totalImageCount; i++) {
      if (tenant.tenantImage && tenant.tenantImage['image' + i]) {
        const imageObj = {
          image: tenant.tenantImage['image' + i],
          thumbImage: tenant.tenantImage['image' + i] // You can set thumbnail URL here
        };
        images.push(imageObj);
      }
    }
    return images;
  } */

  getAllPhoneNumbers(tenant: any): string {
    let phoneNumbers : string = '';

    if (tenant.contactPhoneNumber1) {
      phoneNumbers = tenant.contactPhoneNumber1 }
    if (tenant.contactPhoneNumber2) {
            phoneNumbers += " / " + tenant.contactPhoneNumber2 }
    /* if (tenant.contactPhoneNumber3) {
                  phoneNumbers += " / " + tenant.contactPhoneNumber3 }
*/

    return phoneNumbers;
  }







  /*   const images: Array<object> = [];
    //console.log("GetTenantImage tenant  ", tenant);
    if(tenant && tenant.tenantImage && tenant.tenantImage.totalImageCount)
    {
      console.log("count: ", tenant.tenantImage.totalImageCount);
        for (let i = 1; i <= tenant.tenantImage.totalImageCount; i++) {
          if (tenant.tenantImage && tenant.tenantImage['image' + i]) {

          let imgObj = {
                        image: tenant.tenantImage['image' + i],
                        thumbImage: tenant.tenantImage['image' + i]
                    }
            images.push(imgObj);
            //images.push(tenant.tenantImage['image' + i]);
          }
        }
    }
    else
    {
      console.log("No Image Found");
       let imgObj = {
                         image: "../assets/img/noImage.png",
                         thumbImage: "../assets/img/noImage.png"
        }
       images.push(imgObj);
    }
    //console.log("imags:", images);
    return images;
   */


   //}


/*
   // Method to slide to the next image
  nextSlide() {
   this.slider.next();
  }

  // Method to slide to the previous image
  prevSlide() {
    this.slider.prev();
  }
  navigateToTenant(tenantId): void {
    alert('Hello')

    // this.router.navigate([`${UIRoutes.ROUTING_MODULE_PRODUCT_LIST}${tenantId}`]);
  }
 */

}
