<div class="row" *ngIf="SA">
  <ol class="breadcrumb backgroundclr">
    <li class="breadcrumb-item"><a href="/tenants-grid">Tenants</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{tenant?.tenantName}}</li>
  </ol>
</div>
 <div class="container" style="background-color: #fff; margin-left: 0px;">
    <div class="row">
        <div class="col" style="margin-left: -50px;">
            <nav class="navbar navbar-light navbar-expand-md">
                <div class="container-fluid">
                  <button data-toggle="collapse " class="navbar-toggler hidden-mobile" data-target="#navcol-1">
                        <span class="sr-only">
                            Toggle navigation
                        </span>
                        <span class="navbar-toggler-icon">

                        </span>
                  </button>
                    <!--<div *ngIf="isLoggedIn() && (roleId == 1)">
                      <a href="#" routerLink="/tenants-grid"  (click)="onClickAdmin()" style="height: 40px; color: #14274E; font-size: 16px;">Tenants</a>
                    </div>-->
                    <div class="collapse navbar-collapse" id="navcol-1" >
                      <ul class="nav navbar-nav descriptionHeader" *ngIf="roleId < 3">
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                          <a class="nav-link" [routerLink]="['admin-bookings']" href="/landing-page/admin-bookings"  (click)="clickedOnAdminBookings()" style="padding-top: 10px; padding-right: 38px; padding-bottom: 10px; padding-left: 38px;">
                              Bookings
                          </a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                          <a class="nav-link" [routerLink]="['transactions']" href="/landing-page/transactions" (click)="clickedOnTransactions()" style="padding-top: 10px; padding-right: 38px; padding-bottom: 10px; padding-left: 38px; ">
                            Transactions
                          </a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                            <a class="nav-link" [routerLink]="['company-profile']" href="/landing-page/company-profile" (click)="clickedOnCompanyProfile()" style="padding-top: 10px; padding-right: 38px; padding-bottom: 10px; padding-left: 38px;">
                                Company Profile
                            </a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                            <a class="nav-link" [routerLink]="['products']" href="landing-page/products" (click)="clickedOnProducts()" style="padding: 10px 38px;">
                                Services
                            </a>
                        </li>
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                            <a class="nav-link" [routerLink]="['venues']" href="landing-page/venues" (click)="clickedOnVenues()" style="padding: 10px 38px;">
                                Venues
                            </a>
                        </li>
                        <!--<li class="nav-item" role="presentation">
                            <a class="nav-link" [routerLink]="['themes']" href="landing-page/themes" (click)="clickedOnThemes()" style="padding: 10px 38px;">
                                Themes
                            </a>
                        </li>-->
                        <!--<li class="nav-item" role="presentation">
                            <a class="nav-link" [routerLink]="['policy']" href="landing-page/policy" (click)="clickedOnPolicy()" style="padding: 10px 38px;">
                                Policy
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" [routerLink]="['exceptions']" href="exceptions" (click)="clickedOnExceptions()" style="padding: 10px 38px;">
                                Exceptions
                            </a>
                        </li>-->
                      </ul>
                      <ul class="nav navbar-nav descriptionHeader" *ngIf="roleId == 3">
                        <li class="nav-item" [routerLinkActive]="['active']" role="presentation" data-target="#navcol-1" data-toggle="collapse">
                          <a class="nav-link" [routerLink]="['transactions']" href="/landing-page/transactions" (click)="clickedOnTransactions()" style="padding-top: 10px; padding-right: 38px; padding-bottom: 10px; padding-left: 38px; ">
                            My Transactions
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!--</div>-->

<router-outlet></router-outlet>
