
    <ng-template #content let-modal>
        <div style="background-color: #ffffff;">
          <form [formGroup]="verifyOTPFormGroup" style="margin-right: 30px;margin-left: 5px;">
            <div class="col">
                    <div class="row" style="margin-left: 10px;margin-top: 50px;">
                        <div class="col">
                            <p style="font-size: 24px;color: #14274e;width: 340px;">
                                Enter Verification Code
                            </p>
                            <p style="color: #333333;margin-top: 30px;width: 300px;">
                                We have just sent a verification a code to {{user.email}}
                            </p>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 10px;margin-top: 20px;">
                        <div>
                            <div style="width: 40px;height: 40px;float: left;margin-left: 20px; margin-right: 10px;">
                                <input id="codeBox1" type="text" formControlName="regotp0" maxlength="1" (keyup)="onKeyUpEvent(1, $event)" (focus)="onFocusEvent(1)" style="width: inherit;height: inherit;background-color: #87dfd6;border-radius: 12px;border: none;text-align: center;" />
                            </div>
                            <div style="width: 40px;height: 40px;float: left;margin-right: 10px;">
                                <input id="codeBox2" type="text" formControlName="regotp1" maxlength="1" (keyup)="onKeyUpEvent(2, $event)" (focus)="onFocusEvent(2)" style="width: inherit;height: inherit;background-color: #87dfd6;border-radius: 12px;border: none;text-align: center;" />
                            </div>
                            <div style="width: 40px;height: 40px;float: left;margin-right: 10px;">
                                <input id="codeBox3" type="text" formControlName="regotp2" maxlength="1" (keyup)="onKeyUpEvent(3, $event)" (focus)="onFocusEvent(3)" style="width: inherit;height: inherit;background-color: #87dfd6;border-radius: 12px;border: none;text-align: center;" />
                            </div>
                            <div style="width: 40px;height: 40px;float: left;">
                                <input id="codeBox4" type="text" formControlName="regotp3" maxlength="1" (keyup)="onKeyUpEvent(4, $event)" (focus)="onFocusEvent(4)" style="width: inherit;height: inherit;background-color: #87dfd6;border-radius: 12px;border: none;text-align: center;" />
                            </div>
                        </div>
                    </div>
                    <label style="font-size: 14px;height: 15px; font-weight: bold;margin-top: 15px; margin-left:30px;">
                      OTP Validity : 5 Minutes
                    </label>

                    <div class="row" style="margin-left: 30px;margin-top: 20px;">
                      <div class="col">
                        <div *ngIf="showOnClickVerifyError" style="color: red;">Invalid OTP</div>
                        <div *ngIf="sendOTPClicked">{{messageToDisplayAfterOtpSent}}</div>
                      </div>
                    </div>

                    <div class="col-md">
                      <div class="row" style="margin-left: 10px;margin-top: 40px;">

                        <p (click)="onClickedSendOTP()" style="font-weight: 400;color: #14274e; cursor: pointer;">
                            Resend code
                        </p>
                        <!-- <p style="color: #14274e;font-weight: 600;letter-spacing: 0px;cursor: pointer;">
                            Change the Phone Number
                        </p> -->
                      </div>
                    </div>
                <!--    <div class="row" style="margin-left: 10px;">
                        <div class="col">
                            <div *ngIf="showOnClickVerifyError" style="color: red;">{{onClickVerifyError}}</div>
                        </div>
                    </div>-->
                    <div class="row" style="margin-left: 30px;margin-top: 20px;  margin-bottom:50px;">

                      <button class="btn btn-primary" type="button" (click)="onClickVerify()" style="background-color: #14274e;width: 100px;">
                          Verify
                      </button>
                      <button class="btn btn-primary" type="button" (click)="onClickCancel()" style="margin-left: 20px;background-color: #14274e;width: 100px;">
                          Cancel
                      </button>
                    </div>

<!--                <div class="col-md-4 d-none d-md-block">
                    <div style="width: 454px;margin-top: 92px;">
                        <img src="../../assets/img/3.png" style="width: 454px;height: 425px;" />
                    </div>
                </div>-->
            </div>
          </form>
        </div>
    </ng-template>

    <ng-template #resetPassword let-modal>
        <div class="container" style="width: 1021px;height: 608px;background-color: #ffffff;">
            <form [formGroup]="resetPasswordFormGroup">
            <div class="row">
                <div class="col">
                    <div class="row" style="margin-left: 45px;margin-top: 110px;">
                        <div class="col">
                            <h1 style="font-size: 32px;color: #14274e;width: 340px;">
                                Reset Password
                            </h1>
                            <div style="margin-top: 20px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="password">
                                    PASSWORD
                                </label>
                                <div>
                                    <input type="password" formControlName="passwordFormControl" placeholder="Password"
                                    style="width: 325px;height: 40px;" />
                                </div>
                                <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('required') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password is required
                                </div>
                                <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password must have at least {{minPasswordLength}} characters
                                </div>
                                <div *ngIf="resetPasswordFormControls.passwordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.passwordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character
                                </div>
                            </div>
                            <div style="margin-top: 20px;">
                                <label style="font-size: 11px;height: 15px;padding-top: 0px;padding-bottom: 0px;color: #7c7575;font-weight: bold;" for="reenter-password">
                                    RE-ENTER PASSWORD
                                </label>
                                <div>
                                    <input type="password" formControlName="reenterPasswordFormControl" placeholder="Password"
                                    style="width: 325px;height: 40px;" />
                                </div>
                                <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('required') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password is required
                                </div>
                                <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password must have at least {{minPasswordLength}} characters
                                </div>
                                <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                                  Password must contain at least 1 or more lowercase letter, uppercase letter, number and special character
                                </div>
                                <div *ngIf="resetPasswordFormControls.reenterPasswordFormControl.hasError('confirmedValidator') && !resetPasswordFormControls.reenterPasswordFormControl.hasError('pattern') && !resetPasswordFormControls.passwordFormControl.hasError('minlength') && resetPasswordFormControls.reenterPasswordFormControl.touched" style="color: red;font-size: 14px;">
                                  Passwords did not match
                                </div>
                            </div>
                            <div class="row" style="margin-left: 90px;margin-top: 34px;">
                                <div class="col">
                                    <button class="btn btn-primary" [disabled]="resetPasswordFormGroup.invalid" type="button" (click)="onResetPasswordClicked()" style="background-color: #14274e;width: 130px;">
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div style="width: 454px;margin-top: 92px;">
                        <img src="../../assets/img/3.png" style="width: 454px;height: 425px;" />
                    </div>
                </div>
            </div>
        </form>
        </div>
    </ng-template>
