import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  public confirmationId;
  bookings: any;
  transactionStatus: string;
  constructor(private authService: AuthService, private route: ActivatedRoute) {
    console.log("In constructor inside payment status component before getting confirmationId");
    this.confirmationId = this.route.snapshot.paramMap.get('confirmationId');
    console.log("In constructor inside payment status component after getting confirmationId");
  }

  ngOnInit() {
    //this.confirmationId = sessionStorage.getItem(AppConstants.CONFIRMATION_ID);
    console.log("In ngOnInit inside payment status component before getting booking by confirmationId");
    console.log(this.confirmationId);
    this.getBookingByConfirmationId(this.confirmationId);
  }

  getBookingByConfirmationId(confirmationId) {
    console.log(confirmationId);
    this.authService.getBookingByConfirmationId(confirmationId).subscribe(
      (response) => {
        this.bookings = response;
        console.log(response);
      },
      (error) => {

      }
    );
  }

  getTransactionStatus() {
    if(this.bookings && this.bookings.length > 0) {
      if(this.bookings.length > 0 && this.bookings[0].bookingStatus == 'Failed') {
        return 'Failed';
      } else if(this.bookings.length > 0 && this.bookings[0].bookingStatus == 'Booked') {
        return 'Success';
      } else if(this.bookings.length > 0 && this.bookings[0].bookingStatus == 'WaitingForPayment') {
        return 'WaitingForPayment';
      } else {
        return this.bookings[0].bookingStatus;
      }
    }
  }

  getStartTime(booking) {
    return booking.startTime;
  }

  getEndTime(booking) {
    return booking.endTime;
  }

  getAmount(booking) {
    return booking.amount;
  }

  getUserFullName(booking) {
    return booking.name;
  }

  getUserPhoneNumber(booking) {
    return booking.phoneNumber;
  }

  getUserName(booking) {
    return booking.email;
  }

  getBookingDateConverted(booking) {
    return booking.bookingDate;
  }

  getPaymentType(booking) {
    return booking.paymentType;
  }

  getVenueName(booking) {
    return booking.venueName;
  }

}
